import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import StorageParams from "../../constants/StorageParams";
import { BaseApiService } from "../../utils/BaseApiService";
import { DataTable } from "primereact/datatable";
import { UserSessionUtils } from "../../utils/UserSessionUtils";
import { Column } from "primereact/column";
import {
   convertDateFormat,
   convertTo24HourFormat,
   datesAreEqual,
   formatDate,
   formatNumberWithCommas,
   toReadableTime,
   toReadableDateTime
} from "../../utils/Utils";
import { Button } from "primereact/button";
import StatusSummary from "../../components/StatusSummary";
import { Skeleton } from "primereact/skeleton";
import { Chart } from "primereact/chart";
import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";

const AttendantDashboard = () => {
   const navigate = useNavigate();
   const isLoggedIn = localStorage.getItem(StorageParams.IS_LOGGED_IN);

   let times24hr = [];
   let shopIncomeDataSets = [];
   let shopHourlySales = [];
   let dailyLabels = [];

   const initHourlyData = {
      labels: times24hr,
      datasets: shopHourlySales
   };

   const initWeeklyData = {
      labels: dailyLabels,
      datasets: shopIncomeDataSets
   };

   const [sales, setSales] = useState(null);
   const [isloading, setisloading] = useState(true);
   const [shopProducts, setShopProducts] = useState([]);
   const [isLoading, setIsLoading] = useState(false);

   const [weekSales, setWeekSales] = useState([]);
   const [todaySales, setTodaySales] = useState([]);

   const [weeklyData, setWeeklyData] = useState(initWeeklyData);
   const [hourlyData, setHourlydata] = useState(initHourlyData);

   const [today, setToday] = useState(new Date());

   const [datesOfWeek, setDatesOfWeek] = useState([]);
   const [selectedDay, setSelectedDay] = useState(formatDate(new Date(), false));
   const [user, setUser] = useState(null);

   const calendarRef = useRef(null);
   useEffect(() => {
      let obj = UserSessionUtils.getFullSessionObject();
      setUser(obj.user);
   }, []);

   for (let hour = 7; hour <= 22; hour++) {
      times24hr.push(`${hour.toString().padStart(2, "0")}:00`);
   }

   const getSaleChartData = () => {
      if (weekSales.length > 0) {
         let incomeValues = [];
         let txns = [];
         //setting data for the daily income
         for (let date of datesOfWeek) {
            //filtering out sales for the current loop date
            const dateSales = [...weekSales].filter((sale) => {
               return datesAreEqual(date, new Date(sale.soldOnDate));
            });

            //profits array for the date
            txns.push(dateSales?.length);
            const profits = dateSales
               .map((item) => item.lineItems?.reduce((a, i) => a + i.totalProfit, 0) || 0)
               .filter((profit) => profit !== undefined);
            let income = Math.round(profits.reduce((a, b) => a + b, 0)); //getting the sum profit in all carts

            incomeValues.push(income);

            if (datesAreEqual(date, new Date())) {
               setTodaySales(dateSales);
            }
         }

         shopIncomeDataSets.push({
            label: user?.attendantShopName,
            backgroundColor: "#f5e102",
            data: incomeValues,
            tension: 0.4,
            txns: txns
         });

         let newData = {
            ...weeklyData,
            datasets: shopIncomeDataSets,
            labels: [...new Set(datesOfWeek.map((date) => formatDate(date, false)))]
         };
         setWeeklyData(newData);
         setisloading(false);
      }
   };

   const getWeekSales = () => {
      const dayOfWeek = today.getDay(); // 0 for Sunday, 1 for Monday, ..., 6 for Saturday
      setisloading(true);
      // Calculate the date of the previous Saturday
      const saturday = new Date(today);
      saturday.setDate(today.getDate() - dayOfWeek - 1); // Subtracting 1 to get the previous Saturday
      const sunday = new Date(today);
      sunday.setDate(today.getDate() - dayOfWeek);
      datesOfWeek.length = 0;
      for (let i = 0; i < 7; i++) {
         const date = new Date(sunday);
         date.setDate(sunday.getDate() + i);
         if (!datesOfWeek.includes(date)) {
            datesOfWeek.push(date);
         }
      }

      let searchParameters = {
         offset: 0,
         limit: 0,
         shopId: UserSessionUtils.getShopId(),
         startDate: convertDateFormat(saturday),
         endDate: convertDateFormat(datesOfWeek[datesOfWeek.length - 1], true)
      };

      new BaseApiService("/shop-sales")
         .getRequestWithJsonResponse(searchParameters)
         .then((response) => {
            setWeekSales(response.records);
         })
         .catch((error) => {
            setisloading(false);
         });
   };

   const handleSalesPerHour = () => {
      if (todaySales.length > 0) {
         let salesPerHour = [];
         let incomeValues = [];

         times24hr.forEach((hour) => {
            let hourSales = todaySales?.filter((sale) => {
               const saleTime = convertTo24HourFormat(toReadableTime(sale?.dateCreated));
               const isSimilarHour = saleTime.split(":")[0] === hour.split(":")[0];
               return isSimilarHour;
            });
            salesPerHour.push(hourSales.length);

            //profits array for the date
            const profits = hourSales
               .map((item) => item.lineItems?.reduce((a, i) => a + i.totalProfit, 0) || 0)
               .filter((profit) => profit !== undefined);
            let income = Math.round(profits.reduce((a, b) => a + b, 0)); //getting the sum profit in all carts
            incomeValues.push(income);
         });

         shopHourlySales.push({
            label: user?.attendantShopName,
            backgroundColor: "#f5e102",
            borderColor: "#f5e102",
            data: salesPerHour,
            tension: 0.6,
            borderWidth: 2.5,
            incomeValues: incomeValues
         });

         let newHourlyData = {
            ...hourlyData,
            datasets: shopHourlySales
         };
         setHourlydata(newHourlyData);
         setisloading(false);
      }
   };

   const changeTodaySales = (label = "") => {
      const filteredSales = weekSales.filter((sale) => label === formatDate(new Date(sale?.soldOnDate), false));
      setTodaySales(filteredSales);
      setSelectedDay(label);
   };

   const lineChartOptions = {
      scales: {
         y: {
            beginAtZero: true
         }
      },
      plugins: {
         tooltip: {
            callbacks: {
               label: function (context) {
                  let label = context.dataset.label || "";
                  const value = context.parsed.y;
                  const income = context.dataset.incomeValues[context?.dataIndex];

                  if (label) {
                     label += ": ";
                  }

                  if (context.parsed.y !== null) {
                     label += `Income ${formatNumberWithCommas(income)}, Txns ${value}`;
                  }
                  return label;
               }
            }
         }
      }
   };

   const barChartOptions = {
      onClick: (evt, element) => {
         if (element.length > 0) {
            let index = element[0].index;
            let label = weeklyData.labels[index];
            changeTodaySales(label);
         }
      },
      plugins: {
         tooltip: {
            callbacks: {
               label: function (context) {
                  let label = context.dataset.label || "";
                  const value = context.parsed.y;
                  const txns = context.dataset.txns[context?.dataIndex];
                  if (label) {
                     label += ": ";
                  }
                  if (context.parsed.y !== null) {
                     label += `Income ${formatNumberWithCommas(value)}, Txns ${txns}`;
                  }
                  return label;
               }
            }
         }
      }
   };

   useEffect(() => {
      getWeekSales();
   }, [today]);

   useEffect(() => {
      getSaleChartData();
   }, [weekSales]);

   useEffect(() => {
      handleSalesPerHour();
   }, [todaySales]);

   const fetchPrevSales = async () => {
      let searchParameters = { offset: 0, limit: 5 };

      if (UserSessionUtils.getShopAttendant()) {
         searchParameters.shopId = UserSessionUtils.getShopId();
      }
      new BaseApiService("/shop-sales")
         .getRequestWithJsonResponse(searchParameters)
         .then(async (response) => {
            setSales(response.records);
            setisloading(false);
         })
         .catch((error) => {
            setisloading(false);
         });
   };

   const fetchShopProducts = async () => {
      setIsLoading(true);
      let searchParameters = { offset: 0, limit: 5 };

      if (UserSessionUtils.getShopAttendant()) {
         searchParameters.shopId = UserSessionUtils.getShopId();
      }

      new BaseApiService("/shop-products")
         .getRequestWithJsonResponse(searchParameters)
         .then(async (response) => {
            setShopProducts(response.records);
            setIsLoading(false);
         })
         .catch((error) => {
            setIsLoading(false);
         });
   };

   useEffect(() => {
      if (!isLoggedIn) {
         localStorage.clear();
      }
   }, [isLoggedIn]);

   useEffect(() => {
      fetchPrevSales();
      fetchShopProducts();
   }, []);
   const IdTemplate = (rowData) => {
      return (
         <>
            <span className="p-column-title">Transaction</span>
            {rowData.serialNumber}
         </>
      );
   };

   const amountBodyTemplate = (rowData) => {
      return (
         <>
            <span className="p-column-title">Amount Paid</span>
            {formatNumberWithCommas(rowData.amountPaid)}
         </>
      );
   };

   const statusBodyTemplate = (rowData) => {
      return (
         <>
            <span className="p-column-title">Status</span>
            {rowData.statusName}
         </>
      );
   };

   const dateCreatedBodyTemplate = (rowData) => {
      return (
         <>
            <span className="p-column-title">Date Created</span>
            {toReadableDateTime(rowData.dateCreated)}
         </>
      );
   };

   const qtySoldBodyTemplate = (rowData) => {
      return <>{formatNumberWithCommas(rowData?.performanceSummary?.totalQuantitySold)}</>;
   };

   const header = (
      <div className="flex flex-column md:flex-row md:align-items-center">
         <h5 className="m-0 line relative">Recent sales</h5>
      </div>
   );

   const header2 = (
      <div className="flex flex-column md:flex-row md:align-items-center">
         <h5 className="m-0">Items out of stock</h5>
      </div>
   );

   const stockFooter = (
      <>
         <div className="flex justify-content-between">
            {sales && (
               <Button
                  onClick={() => {
                     navigate("/sales");
                  }}
                  className="font-semibold"
                  label="Make a sale"
               />
            )}
         </div>
      </>
   );
   return (
      <div className="layout-dashboard">
         <div className="py-2">
            <div className="grid">
               <StatusSummary showStats iconName="pi pi-shopping-cart " title="Transactions" value={formatNumberWithCommas(0)} />
               <StatusSummary showStats title="Items" value={formatNumberWithCommas(0)} iconName="bx bx-receipt" />
               <StatusSummary showStats title="Cash at hand" value={formatNumberWithCommas(Math.round(0))} iconName="pi pi-money-bill" />
               <StatusSummary showStats title="Sales" value={formatNumberWithCommas(0)} iconName="bx bx-wallet" />
            </div>
         </div>

         <div className="grid p-fluid">
            <div className="col-12 lg:col-6">
               <div className="card h-full">
                  {isLoading ? (
                     <div>
                        <Skeleton height="3rem" className="mb-2" />

                        <Skeleton height="2rem" className="mb-2" />

                        <Skeleton height="22rem" className="mb-2" />
                     </div>
                  ) : (
                     <div>
                        <div className="flex gap-2 justify-content-between">
                           <div className="flex gap-2 justify-content-between">
                              <div>
                                 <label className="centerText title font-semibold">Daily</label>
                                 <br />
                                 <label className="">Income</label>
                              </div>
                              <InputText className="border-none" value={formatDate(today)} readOnly />
                              <Calendar
                                 readOnlyInput
                                 className="border-none hidden"
                                 ref={calendarRef}
                                 maxDate={new Date()}
                                 dateFormat="dd, M yy"
                                 value={today}
                                 placeholder="Select weeks day"
                                 onChange={(e) => setToday(e.value)}
                              />
                           </div>
                           <Button icon={"pi pi-calendar"} className="transparent-btn" onClick={() => calendarRef?.current?.show()} />
                        </div>
                        <Chart type="bar" data={weeklyData} options={barChartOptions} />
                     </div>
                  )}
               </div>
            </div>
            <div className="col-12 lg:col-6">
               <div className="card h-full">
                  {isLoading ? (
                     <div>
                        <Skeleton height="3rem" className="mb-2" />

                        <Skeleton height="2rem" className="mb-2" />

                        <Skeleton height="22rem" className="mb-2" />
                     </div>
                  ) : (
                     <div>
                        <label className="centerText title font-semibold">Hourly</label>
                        <br />
                        <label className="">Transactions {selectedDay}</label>
                        <Chart type="line" data={hourlyData} options={lineChartOptions} />
                     </div>
                  )}
               </div>
            </div>
         </div>
      </div>
   );
};

export default AttendantDashboard;
