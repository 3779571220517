import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { BaseApiService } from "../../utils/BaseApiService";
import { Dropdown } from "primereact/dropdown";
import { convertDateFormat, toReadableDate, toReadableTime } from "../../utils/Utils";
import { UserSessionUtils } from "../../utils/UserSessionUtils";
import { MAXIMUM_RECORDS_PER_PAGE, paymentModes, salesRecordStatuses } from "constants/Constants";
import { Paginator } from "primereact/paginator";
import { formatNumberWithCommas } from "../../utils/Utils";
import { useNavigate } from "react-router-dom";
import DeleteRecordDialog from "../../components/dialogs/DeleteRecordDialog";
import { Calendar } from "primereact/calendar";
import EditSaleDialog from "../../components/dialogs/EditSaleDialog";
import StatusSummary from "../../components/StatusSummary";
import SaleSummaryDialog from "../../components/dialogs/SaleSummary";
import { useLocation } from "react-router-dom";
import { RecieptPrintDialog } from "../RecieptPrintout";
import { SHOP_SALES_ROUTE_PATH } from "../../utils/NavigationRoutes";
import FilterComponent from "components/FilterComponent";
import { template2 } from "components/PaginatorTemplate";
import { showErrorMessage } from "constants/ErrorMessages";
import { SHOP_PRODUCTS_ENDPOINT, SHOP_SALES_ENDPOINT, SHOPS_ENDPOINT } from "utils/EndpointsUtils";
import { useSelector } from "react-redux";
import { getCanDeleteSales, getCanEditSales, getCanViewSales, getCanViewShopIncome } from "duqactStore/selectors";

export default function ShopOwnerReports() {
   const toast = useRef(null);
   const dt = useRef(null);

   const canDeleteSales = useSelector(getCanDeleteSales);
   const canEditSales = useSelector(getCanEditSales);
   const canViewSales = useSelector(getCanViewSales);
   const canViewShopIncome = useSelector(getCanViewShopIncome);

   const [productDialog, setProductDialog] = useState(false);
   const [shopSales, setShopSales] = useState([]);
   const [selectedSale, setSelectedSale] = useState(null); //the table row
   const [limit, setLimit] = useState(0);
   const [isLoading, setIsLoading] = useState(false);
   const [first, setFirst] = useState(0);
   const [totalRecords, setTotalRecords] = useState(0);
   const [searchTerm, setSearchTerm] = useState("");
   const [deleteProductDialog, setDeleteProductDialog] = useState(false);
   const [editSale, setEditSale] = useState(false);
   const [totalItems, setTotalItems] = useState(0);
   const [income, setIncome] = useState(0);
   const [salesValue, setSalesValue] = useState(0);
   const [capital, setCapital] = useState(0);
   const [dates, setDates] = useState(null);
   const [shops, setShops] = useState([]);
   const [products, setProducts] = useState([]);
   const [selectedUser, setSelectedUser] = useState(null);
   const [selectedProduct, setSelectedProdct] = useState(null);
   const [paymentMode, setPaymentMode] = useState(null);
   const [status, setStatus] = useState(null);
   const [showPrintDialog, setShowPrintDialog] = useState(false);
   const [selectedShop, setSelectedShop] = useState(null);
   const [shopUsers, setShopUsers] = useState([]);

   const isShopAttendant = UserSessionUtils.getShopAttendant();
   const isShopOwner = UserSessionUtils.getShopOwner();

   let offset = 0;
   const { state } = useLocation();
   const calendarRef = useRef(null);
   const navigate = useNavigate();

   const clearFields = () => {
      setSelectedUser(null);
      setSearchTerm("");
      setSelectedProdct(null);
      setPaymentMode(null);
      setStatus(null);
      setDates(null);
   };
   const fetchProducts = async (searchParam) => {
      const searchParameters = {
         offset: 0,
         limit: 20,
         ...(selectedShop && { shopId: selectedShop?.id }),
         ...(searchParam && { searchTerm: searchParam }),
         ...(isShopAttendant && !selectedShop && { shopId: UserSessionUtils.getShopId() })
      };

      if (selectedShop || isShopAttendant === true) {
         new BaseApiService(SHOP_PRODUCTS_ENDPOINT)
            .getRequestWithJsonResponse(searchParameters)
            .then(async (response) => {
               setProducts(response.records);
            })
            .catch((error) => {});
      }
   };

   const fetchSales = async () => {
      calendarRef?.current?.hide();

      setIsLoading(true);
      const searchParameters = {
         offset: offset,
         limit: MAXIMUM_RECORDS_PER_PAGE,
         ...(searchTerm?.trim() !== "" && { searchTerm: searchTerm }),
         ...(dates && { startDate: convertDateFormat(dates[0]) }),
         ...(dates && !dates[1] && dates[0] && { endDate: convertDateFormat(dates[0], true) }),
         ...(dates && dates[1] && { endDate: convertDateFormat(dates[1], true) }),
         ...(selectedShop && { shopId: selectedShop?.id }),
         ...(selectedUser && { userId: selectedUser?.id }),
         ...(selectedProduct && selectedShop && { shopProductId: selectedProduct?.id }),
         ...(selectedProduct && isShopAttendant && { shopProductId: selectedProduct?.id }),
         ...(isShopOwner && !selectedShop && { shopOwnerId: UserSessionUtils.getShopOwnerId() }),
         ...(isShopAttendant && { shopId: UserSessionUtils.getShopId() }),
         ...(status && { status: status?.type }),
         ...(paymentMode && { paymentMode: paymentMode?.type })
      };

      if (state?.id) {
         searchParameters.shopId = state?.id;
      }
      setTotalItems(0);

      await new BaseApiService(SHOP_SALES_ENDPOINT)
         .getRequestWithJsonResponse(searchParameters)
         .then((response) => {
            setShopSales(response.records);
            setTotalRecords(response.totalItems);
            setIsLoading(false);
            setCapital(response?.totalPurchaseCost);
            setTotalItems(response?.totalItems);
            setIncome(canViewShopIncome ? Math.floor(response?.totalProfit) : 0);
            setSalesValue(response?.totalCost);
         })
         .catch((error) => {
            showErrorMessage(toast, error?.message);
            setIsLoading(false);
         });
   };

   const fetchShopUsers = () => {
      if (selectedShop) {
         new BaseApiService(`/shops/${selectedShop?.id}/user-accounts`)
            .getRequestWithJsonResponse({ offset: 0, limit: 0 })
            .then((response) => {
               setShopUsers(response?.records);
            })
            .catch((error) => {
               console.error(error);
            });
      }
   };

   const fetchShops = async () => {
      if (isShopAttendant == false) {
         const searchParameters = { offset: 0, limit: 0, ...(isShopOwner && { shopOwnerId: UserSessionUtils.getShopOwnerId() }) };

         await new BaseApiService(SHOPS_ENDPOINT)
            .getRequestWithJsonResponse(searchParameters)
            .then(async (response) => {
               if (response?.records?.length === 1) {
                  setSelectedShop(response.records[0]);
               }
               setShops(response.records);
            })
            .catch((error) => {
               setIsLoading(false);
            });
      }
   };

   const onPageChange = (e) => {
      offset = e.page * MAXIMUM_RECORDS_PER_PAGE;
      setFirst(e.first);
      setLimit(MAXIMUM_RECORDS_PER_PAGE);

      fetchSales();
   };

   const handleBack = () => {
      navigate(SHOP_SALES_ROUTE_PATH, "");
   };

   useEffect(() => {
      fetchShops();
      fetchSales();
   }, []);

   useEffect(() => {
      fetchShopUsers();
      fetchProducts();
   }, [selectedShop]);

   const header = (
      <div className="flex flex-column justify-content-between md:flex-row md:align-items-center">
         <div className="flex align-items-baseline">
            <h5 className="m-0 line relative">Shop Sales</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left  ml-5"></span>
            <span className="block mt-2 md:mt-0 p-input-icon-left ml-5">
               <div className="p-inputgroup flex-1">
                  <InputText placeholder="Search" onInput={(e) => setSearchTerm(e.target.value)} />
                  <Button icon="pi pi-search" className="primary-btn" onClick={fetchSales} />
               </div>
            </span>
         </div>
         {state?.isItTrue && <Button onClick={handleBack} label="Sales Desk" className="primary-btn mr-5" />}
      </div>
   );

   const actionBodyTemplate = (rowData) => {
      return (
         <div className="actions flex justify-content-center md:align-items-center ">
            <Button
               icon="pi pi-eye"
               className="p-button-rounded p-button-text p-button-success "
               onClick={() => {
                  setSelectedSale(rowData);
                  setProductDialog(true);
               }}
            />
            {canEditSales && (
               <Button
                  icon="pi pi-pencil"
                  className="p-button-rounded p-button-text p-button-secondary "
                  onClick={() => {
                     setSelectedSale(rowData);
                     setEditSale(true);
                  }}
               />
            )}

            <Button
               icon="pi pi-print"
               className="p-button-rounded p-button-text p-button-info "
               onClick={() => {
                  setSelectedSale(rowData);
                  setShowPrintDialog(true);
               }}
            />

            {canDeleteSales && (
               <Button
                  icon="pi pi-trash"
                  className="p-button-rounded p-button-text p-button-danger "
                  onClick={() => {
                     setSelectedSale(rowData);
                     setDeleteProductDialog(true);
                  }}
               />
            )}
         </div>
      );
   };

   const amountBodyTemplate = (rowData) => {
      return formatNumberWithCommas(rowData.totalCost, rowData?.currency);
   };

   const recievedBodyTemplate = (rowData) => {
      return formatNumberWithCommas(rowData.amountPaid, rowData?.currency);
   };
   const balanceBodyTemplate = (rowData) => {
      return formatNumberWithCommas(rowData.balance, rowData?.currency);
   };

   const dateCreatedBodyTemplate = (rowData) => {
      return (
         <>
            {toReadableDate(rowData.soldOnDate)} <br />
            {toReadableTime(rowData.dateCreated)}
         </>
      );
   };

   const itemsBodyTemplete = (rowData) => {
      let items = rowData?.lineItems.reduce((a, b) => a + b.quantity, 0);

      return <>{formatNumberWithCommas(items)}</>;
   };

   const Income = (rowData) => {
      const cartProfit = rowData?.lineItems.reduce((a, i) => a + i.totalProfit, 0);

      return formatNumberWithCommas(Math.round(cartProfit), rowData?.currency);
   };

   const nameBodyTemplete = (rowData) => {
      const { name } = rowData;
      let newName = name.split(",").filter((i) => i.length > 0);
      newName = newName.join(",");

      if (newName.length > 22) {
         newName = newName.slice(0, 30).concat("...");
      }
      return newName;
   };

   const hideDialog = () => {
      setSelectedSale(null);
      setProductDialog(false);
   };

   if (canViewSales == false) {
      return <h3 className="text-center">Cannot access this page, please contact system admin</h3>;
   }
   return (
      <div className="grid crud-demo">
         <div className="col-12">
            <div className="py-2 -mt-3">
               <div className="grid">
                  <StatusSummary iconName="pi pi-shopping-cart " title="Txns" value={formatNumberWithCommas(totalRecords)} />
                  <StatusSummary title="Sales" value={formatNumberWithCommas(salesValue)} iconName="bx bx-receipt" />
                  <StatusSummary title="Capital" value={formatNumberWithCommas(Math.round(capital))} iconName="pi pi-money-bill" />
                  <StatusSummary title="Income" value={formatNumberWithCommas(income)} iconName="bx bx-wallet" />
               </div>
            </div>

            <div className="py-2 -mt-1">
               <div className="grid">
                  <div className="col-12 md:col-12 lg:col-12">
                     <FilterComponent onFilter={fetchSales} onClear={clearFields}>
                        <div className="formgrid grid">
                           {!isShopAttendant && (
                              <>
                                 <div className="field col-12 md:col-3 lg:col-3">
                                    <label htmlFor="shop">Shop</label>
                                    <Dropdown
                                       id="shop"
                                       value={selectedShop}
                                       options={shops}
                                       onChange={(e) => setSelectedShop(e.target.value)}
                                       optionLabel={"name"}
                                       filter
                                       filterBy="name"
                                       placeholder="Select Shop"
                                    />
                                 </div>

                                 <div className="field col-12 md:col-3 lg:col-3">
                                    <label htmlFor="shop">User</label>
                                    <Dropdown
                                       value={selectedUser}
                                       options={shopUsers}
                                       onChange={(e) => setSelectedUser(e.target.value)}
                                       optionLabel={(d) => d?.firstName + " " + d?.lastName}
                                       filter
                                       showClear
                                       filterBy="firstName"
                                       placeholder="Select user"
                                    />
                                 </div>
                              </>
                           )}

                           <div className="field col-12 md:col-3 lg:col-3">
                              <label htmlFor="shop">Product</label>
                              <Dropdown
                                 id="shop"
                                 value={selectedProduct}
                                 options={products}
                                 onChange={(e) => setSelectedProdct(e.target.value)}
                                 onFilter={(e) => fetchProducts(e.filter)}
                                 optionLabel={"productName"}
                                 filter
                                 showClear
                                 filterBy="productName"
                                 placeholder="Select product"
                              />
                           </div>
                           <div className="field col-12 md:col-3 lg:col-3">
                              <label htmlFor="shop">Date</label>
                              <Calendar
                                 ref={calendarRef}
                                 className="w-full"
                                 selectionMode="range"
                                 readOnlyInput
                                 hideOnRangeSelection
                                 maxDate={new Date()}
                                 value={dates}
                                 onChange={(e) => setDates(e.value)}
                                 placeholder="Date"
                                 dateFormat="dd-M-yy"
                              />
                           </div>
                        </div>

                        <div className="formgrid grid">
                           <div className="field col-12 md:col-3 lg:col-3">
                              <label htmlFor="shop">Payment Mode</label>
                              <Dropdown
                                 value={paymentMode}
                                 options={paymentModes}
                                 onChange={(e) => setPaymentMode(e.target.value)}
                                 optionLabel={"name"}
                                 placeholder="Payment mode"
                              />
                           </div>

                           <div className="field col-12 md:col-3 lg:col-3">
                              <label htmlFor="shop">Status</label>
                              <Dropdown
                                 id="shop"
                                 value={status}
                                 options={salesRecordStatuses}
                                 onChange={(e) => setStatus(e.target.value)}
                                 optionLabel={"name"}
                                 placeholder="Select status"
                              />
                           </div>
                        </div>
                     </FilterComponent>

                     <div className="card">
                        <Toast ref={toast} />

                        {header}

                        <DataTable
                           loading={isLoading}
                           ref={dt}
                           value={shopSales}
                           dataKey="id"
                           rows={10}
                           className="datatable-responsive"
                           emptyMessage="No shop sales found."
                           size="small"
                           resizableColumns
                        >
                           <Column field="shopName" header="Transaction " body={nameBodyTemplete} style={{ width: "10%" }} />

                           <Column header="Qty" body={itemsBodyTemplete} className="text-center" headerStyle={{ textAlign: "center" }} />

                           <Column header="Recieved" body={recievedBodyTemplate} />
                           <Column header="Amount" body={amountBodyTemplate} />
                           <Column header="Balance" body={balanceBodyTemplate} />
                           {canViewShopIncome && <Column header="Income" body={Income} />}
                           {shops?.length > 1 && <Column field="shopName" header="Shop " />}

                           <Column field="createdByFullName" header="Created by" />

                           <Column header="Sold on" body={dateCreatedBodyTemplate} style={{ width: "15%" }} />

                           <Column body={actionBodyTemplate} header="Actions" headerStyle={{ textAlign: "center" }} style={{ width: "10%" }} />
                        </DataTable>
                        <Paginator
                           template={template2}
                           first={first}
                           rows={MAXIMUM_RECORDS_PER_PAGE}
                           totalRecords={totalRecords}
                           alwaysShow={false}
                           onPageChange={onPageChange}
                        />
                     </div>
                  </div>
               </div>
            </div>

            <SaleSummaryDialog visible={productDialog} hideDialog={hideDialog} selectedSale={selectedSale} reload={fetchSales} />

            <EditSaleDialog toast={toast} selectedSale={selectedSale} editSale={editSale} setEditSale={setEditSale} onFinish={fetchSales} />
            <RecieptPrintDialog saleData={selectedSale} toggleFn={setShowPrintDialog} visible={showPrintDialog} reloadFn={setShowPrintDialog} />

            <DeleteRecordDialog
               isLoading={isLoading}
               selectedRecordName={`Sale record ${selectedSale?.id}`}
               setDeleteProductDialog={setDeleteProductDialog}
               deleteProductDialog={deleteProductDialog}
               setSelectedRecord={setSelectedSale}
               showForm={true}
               apiUrl={"/shop-sales/" + selectedSale?.id}
               setIsLoading={setIsLoading}
               onComplete={() => {
                  fetchSales();
                  setSelectedSale(null);
                  toast.current.show({
                     severity: "success",
                     summary: "Successful",
                     detail: "Sale record deleted",
                     life: 3000
                  });
                  setIsLoading(false);
               }}
            />
         </div>
      </div>
   );
}
