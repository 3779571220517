import React, { useState, useEffect, useRef } from "react";
import { classNames } from "primereact/utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";

import { BaseApiService } from "../../utils/BaseApiService";
import { useNavigate } from "react-router-dom";
import StorageParams from "../../constants/StorageParams";
import { showErrorMessage } from "../../constants/ErrorMessages";
import { Dropdown } from "primereact/dropdown";
import { NumericFormat } from "react-number-format";
import { Messages } from "primereact/messages";
import { formatNumberWithCommas, toReadableDateTime, toReadableTime } from "../../utils/Utils";
import { InputTextarea } from "primereact/inputtextarea";
import { Calendar } from "primereact/calendar";
import PhoneInput from "react-phone-input-2";
import { Accordion, AccordionTab } from "primereact/accordion";
import StatusSummary from "../../components/StatusSummary";

const Supplier = () => {
   let initialSupplier = {
      serialNumber: null,
      phoneNumber: null,
      alternativePhoneNumber: null,
      firstName: null,
      lastName: null,
      otherName: null,
      fullName: null,
      companyOrBusinessName: null,
      email: null,
      remarks: null,
      totalSuppliedShops: 0
   };

   const [suppliers, setSuppliers] = useState(null);
   const [supplierDialog, setSupplierDialog] = useState(false);
   const [deleteSupplierDialog, setDeleteSupplierDialog] = useState(false);
   const [deleteSuppliersDialog, setDeleteSuppliersDialog] = useState(false);
   const [supplier, setSupplier] = useState(initialSupplier);
   const [selectedSuppliers, setSelectedSuppliers] = useState(null);
   const [submitted, setSubmitted] = useState(false);
   const [globalFilter, setGlobalFilter] = useState(null);
   const [edit, setEdit] = useState(false);
   const [refresh, setRefresh] = useState(false);
   const [searchTerm, setSearchTerm] = useState("");
   const [isLoading, setIsLoading] = useState(false);
   const [phoneNumber, setPhoneNumber] = useState(null);
   const [altphoneNumber, setAltPhoneNumber] = useState(null);
   const [countries, setCountries] = useState(null);
   const [selectedCountry, setSelectedCountry] = useState(null);

   const dialogMessage = useRef();
   const navigate = useNavigate();
   const toast = useRef(null);
   const dt = useRef(null);

   const fetchSuppliers = async () => {
      let searchParameters = {
         searchTerm: "",
         offset: 0,
         limit: 0,
         searchTerm: searchTerm
      };
      new BaseApiService("/suppliers")
         .getRequestWithJsonResponse(searchParameters)
         .then(async (response) => {
            setSuppliers(response.records);
         })
         .catch((error) => {});
   };

   const fetchCountries = async () => {
      let searchParameters = { searchTerm: "", offset: 0, limit: 0 };
      new BaseApiService("/lookups/countries")
         .getRequestWithJsonResponse(searchParameters)
         .then(async (response) => {
            setCountries(response.records);
         })
         .catch((error) => {});
   };

   useEffect(() => {
      fetchSuppliers();
      fetchCountries();
   }, [searchTerm]);

   const openNew = () => {
      setSupplier(initialSupplier);
      setSubmitted(false);
      setSupplierDialog(true);
      setEdit(false);
   };

   const hideDialog = () => {
      setSubmitted(false);
      setSupplierDialog(false);
   };

   const hideShopDialog = () => {
      setDeleteSupplierDialog(false);
   };

   const hideDeleteShopsDialog = () => {
      setDeleteSuppliersDialog(false);
   };

   const saveSupplier = () => {
      setSubmitted(true);

      setIsLoading(true);
      const apiUrl = edit && edit === true ? "/suppliers/" + supplier.id : "/suppliers";

      new BaseApiService(apiUrl)
         .saveRequestWithJsonResponse(
            {
               firstName: supplier.firstName,
               lastName: supplier.lastName,
               otherName: supplier.otherName,
               phoneNumber: supplier.phoneNumber,
               alternativePhoneNumber: supplier.alternativePhoneNumber,
               email: supplier.email,
               companyOrBusinessName: supplier.companyOrBusinessName,
               remarks: supplier.remarks
            },
            edit
         )
         .then((response) => {
            if (edit && edit === true) {
               setRefresh(true);
            } else {
               let _suppliers = [...suppliers];

               let _supplier = { ...response };

               _suppliers.push(_supplier);

               setSuppliers(_suppliers);
            }
            setSupplierDialog(false);
            setIsLoading(false);
            setSupplier(initialSupplier);
            toast.current.show({
               severity: "success",
               summary: "Successful",
               detail: "Supplier Details saved",
               life: 3000
            });
         })
         .catch((error) => {
            showErrorMessage(dialogMessage, error.message);
            setIsLoading(false);
         });
   };

   const onCurrencyChange = (e) => {
      setSelectedCountry(e.target.value);
   };

   const editSupplier = (supplier) => {
      setEdit(true);

      setSupplier({ ...supplier });
      setSupplierDialog(true);
   };

   const confirmDeleteSupplier = (supplier) => {
      setSupplier(supplier);
      setDeleteSupplierDialog(true);
   };

   const deleteSupplier = () => {
      new BaseApiService("/suppliers/" + supplier.id)
         .deleteRequestWithJsonResponse()
         .then((response) => {
            let _suppliers = suppliers.filter((val) => val.id !== supplier.id);
            setSuppliers(_suppliers);
            setDeleteSupplierDialog(false);
            setSupplier(initialSupplier);
            toast.current.show({
               severity: "success",
               summary: "Successful",
               detail: "Supplier Deleted",
               life: 3000
            });
         })
         .catch((error) => {});
   };

   const confirmDeleteSelected = () => {
      setDeleteSuppliersDialog(true);
   };

   const deleteSuppliers = () => {
      let _suppliers = suppliers.filter((val) => !selectedSuppliers.includes(val));
      setSuppliers(_suppliers);
      setDeleteSuppliersDialog(false);
      setSelectedSuppliers(null);
      toast.current.show({
         severity: "success",
         summary: "Successful",
         detail: "Suppliers Deleted",
         life: 3000
      });
   };

   const onInputChange = (e, name) => {
      if (name == "phoneNumber" || name == "alternativePhoneNumber") {
         const val = e || "";
         let _supplier = { ...supplier };
         _supplier[`${name}`] = val;

         setSupplier(_supplier);
      } else {
         const val = (e.target && e.target.value) || "";
         let _supplier = { ...supplier };
         _supplier[`${name}`] = val;

         setSupplier(_supplier);
      }
   };

   const serialNumberBodyTemplate = (rowData) => {
      return (
         <>
            <span className="p-column-title">Serial number</span>
            {rowData.serialNumber}
         </>
      );
   };

   const contactPersonBodyTemplate = (rowData) => {
      return (
         <>
            <span className="p-column-title">Contact person</span>
            {rowData.fullName}
         </>
      );
   };

   const businessNameBodyTemplate = (rowData) => {
      return (
         <>
            <span className="p-column-title">Business name</span>
            {rowData.companyOrBusinessName} <br />
            <span className="text-sm opacity-80">SN: {rowData.serialNumber}</span>
         </>
      );
   };

   const phoneBodyTemplate = (rowData) => {
      return (
         <>
            <span className="p-column-title">Phone</span>
            {rowData.phoneNumber}
         </>
      );
   };

   const createdByFullNameBodyTemplate = (rowData) => {
      return (
         <>
            <span className="p-column-title">Created by</span>
            {rowData.createdByFullName}
         </>
      );
   };

   const dateCreatedBodyTemplate = (rowData) => {
      return (
         <>
            <span className="p-column-title">Date Created</span>
            {toReadableDateTime(rowData.dateCreated)}
         </>
      );
   };

   const totalSuppliedShopsBodyTemplate = (rowData) => {
      return (
         <>
            <span className="p-column-title">Supplied shops</span>
            <Button
               label={checkNumericAttribute(rowData.totalSuppliedShops) + ""}
               style={{ color: "#495057" }}
               className="p-button-link numlink"
               // onClick={() =>
               //     navigate('/administrator/shops', {
               //         state: {
               //             // ownerId: rowData.id,
               //             // ownerName: rowData.firstName + ' ' + rowData.lastName
               //         }
               //     })
               // }
            />
         </>
      );
   };

   function checkNumericAttribute(value) {
      if (value === null || typeof value === "undefined") {
         return 0;
      } else {
         return value;
      }
   }

   const actionBodyTemplate = (rowData) => {
      return (
         <div className="actions flex md:flex-row md:align-items-center">
            <Button icon="pi pi-pencil" className="p-button-rounded p-button-text p-button-success mr-2" onClick={() => editSupplier(rowData)} />
            <Button
               icon="pi pi-trash"
               className="p-button-rounded p-button-text p-button-danger mt-2"
               onClick={() => confirmDeleteSupplier(rowData)}
            />
         </div>
      );
   };

   const header = (
      <div className="flex flex-column md:flex-row md:align-items-center">
         <h5 className="m-0 line relative">Suppliers</h5>
         <span className="block mt-2 md:mt-0 p-input-icon-left ml-5">
            <div className="p-inputgroup flex-1">
               <InputText placeholder="Search" onInput={(e) => setSearchTerm(e.target.value)} />
               <Button icon="pi pi-search" className="primary-btn" onClick={fetchSuppliers} />
            </div>
         </span>
         <div className="my-2 ml-auto">
            <Button label="New" icon="pi pi-plus" className="primary-btn mr-2" onClick={openNew} />
            <Button
               label="Delete"
               icon="pi pi-trash"
               className="p-button-danger"
               onClick={confirmDeleteSelected}
               style={selectedSuppliers && selectedSuppliers.length > 1 ? { display: "inline-flex" } : { display: "none" }}
            />
         </div>
      </div>
   );

   const supplierDialogFoooter = (
      <>
         <Button label="Cancel" icon="pi pi-times" className=" outline-btn" onClick={hideDialog} />
         <Button loading={isLoading} label="Save" icon="pi pi-check" className=" primary-btn" onClick={saveSupplier} />
      </>
   );
   const deleteManufacturerDialogFooter = (
      <>
         <Button label="No" icon="pi pi-times" className="outline-btn" onClick={hideShopDialog} />
         <Button label="Yes" icon="pi pi-check" className="primary-btn" onClick={deleteSupplier} />
      </>
   );
   const deleteManufacturersDialogFooter = (
      <>
         <Button label="No" icon="pi pi-times" className="outline-btn" onClick={hideDeleteShopsDialog} />
         <Button label="Yes" icon="pi pi-check" className="primary-btn" onClick={deleteSuppliers} />
      </>
   );

   const template2 = {
      layout: "CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink",
      RowsPerPageDropdown: (options) => {
         const dropdownOptions = [
            { label: 10, value: 10 },
            { label: 20, value: 20 },
            { label: 50, value: 50 }
         ];

         return (
            <React.Fragment>
               <span className="mx-1" style={{ color: "var(--text-color)", userSelect: "none" }}></span>
               <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />
               <span className="mr-auto"></span>
            </React.Fragment>
         );
      },
      CurrentPageReport: (options) => {
         return (
            <span
               style={{
                  color: "var(--text-color)",
                  userSelect: "none",
                  width: "auto",
                  textAlign: "center"
               }}
            >
               Showing {options.first} to {options.last} of {options.totalRecords} suppliers
            </span>
         );
      }
   };

   return (
      <div className="grid crud-demo">
         <div className="col-12">
            <div className="py-2">
               <div className="grid">
                  <StatusSummary iconName="pi pi-shopping-cart " title="Shops" value={formatNumberWithCommas(0)} />
                  <StatusSummary title="Attendants" value={formatNumberWithCommas(0)} iconName="bx bx-receipt" />
                  <StatusSummary title="Capital" value={formatNumberWithCommas(Math.round(0))} iconName="pi pi-money-bill" />
                  <StatusSummary title="Removed Capital" value={formatNumberWithCommas(0)} iconName="bx bx-wallet" />
               </div>
            </div>
            <div className="py-2 -mt-3">
               <Accordion>
                  <AccordionTab className="p-fluid" header="Filter suppliers">
                     <div className="formgrid grid">
                        <div className="field col">
                           <label htmlFor="purchasedQuantity">Price range</label>
                           <InputNumber id="purchasedQuantity" />
                        </div>
                        <div className="field col">
                           <label htmlFor="purchasePrice">Amount range</label>
                           <InputNumber id="purchasePrice" />
                        </div>
                        <div className="field col">
                           <label htmlFor="purchasePrice">Batch no</label>
                           <InputNumber id="purchasePrice" />
                        </div>
                     </div>

                     <div className="formgrid grid">
                        <div className="field col">
                           <label htmlFor="purchasedQuantity">Purchase date</label>
                           <InputNumber id="purchasedQuantity" />
                        </div>
                        <div className="field col">
                           <label htmlFor="purchasePrice">Purchase by</label>
                           <InputNumber id="purchasePrice" />
                        </div>
                        <div className="field col">
                           <label htmlFor="purchasePrice">Expiry date</label>
                           <Calendar />
                        </div>
                     </div>
                     <div className="formgrid grid">
                        <div className="field col">
                           <label htmlFor="purchasedQuantity">Category</label>
                           <InputNumber id="purchasedQuantity" />
                        </div>
                     </div>
                     <div
                        style={{
                           display: "flex",
                           flexDirection: "row",
                           justifyContent: "flex-end"
                        }}
                     >
                        <Button
                           label="Clear"
                           icon="pi pi-times"
                           className="p-button-text "
                           style={{
                              color: "black",
                              border: "1px solid black",
                              margin: "5px",
                              width: "fit-content"
                           }}
                        />
                        <Button
                           label="Filter"
                           icon="pi pi-check"
                           className="p-button-text"
                           style={{
                              color: "#FFEB3B",
                              border: "1px solid black",
                              backgroundColor: "black",
                              margin: "5px",
                              width: "fit-content"
                           }}
                        />
                     </div>
                  </AccordionTab>
               </Accordion>
            </div>
            <div className="card">
               <Toast ref={toast} />

               <DataTable
                  ref={dt}
                  value={suppliers}
                  selection={selectedSuppliers}
                  onSelectionChange={(e) => setSelectedSuppliers(e.value)}
                  dataKey="id"
                  paginator
                  rows={10}
                  className="datatable-responsive"
                  paginatorTemplate={template2}
                  globalFilter={globalFilter}
                  emptyMessage="No supplier found."
                  header={header}
                  size="small"
               >
                  <Column
                     field="businessName"
                     header="Company or Business"
                     body={businessNameBodyTemplate}
                     className="text-left"
                     headerStyle={{
                        width: "20%",
                        minWidth: "10rem",
                        textAlign: "left"
                     }}
                  />

                  <Column
                     field="name"
                     header="Contact person"
                     body={contactPersonBodyTemplate}
                     className="text-left"
                     headerStyle={{
                        width: "20%",
                        minWidth: "10rem",
                        textAlign: "left"
                     }}
                  />
                  <Column
                     field="phone"
                     header="Phone"
                     body={phoneBodyTemplate}
                     className="text-center"
                     headerStyle={{
                        width: "20%",
                        minWidth: "10rem",
                        textAlign: "center"
                     }}
                  />
                  <Column
                     field="suppliedShops"
                     header="Shops"
                     body={totalSuppliedShopsBodyTemplate}
                     className="text-center"
                     headerStyle={{
                        width: "20%",
                        minWidth: "10rem",
                        textAlign: "center"
                     }}
                  />
                  <Column
                     field="createdBy"
                     header="Created by"
                     body={createdByFullNameBodyTemplate}
                     className="text-center"
                     headerStyle={{
                        width: "20%",
                        minWidth: "8rem",
                        textAlign: "center"
                     }}
                  />
                  <Column
                     field="dateCreated"
                     header="Created on"
                     body={dateCreatedBodyTemplate}
                     className="text-center"
                     headerStyle={{
                        width: "15%",
                        minWidth: "10rem",
                        textAlign: "center"
                     }}
                  />
                  <Column body={actionBodyTemplate} header="Actions" className="text-center" />
               </DataTable>

               <Dialog
                  visible={supplierDialog}
                  style={{ width: "700px" }}
                  header="Supplier"
                  modal
                  className="p-fluid"
                  footer={supplierDialogFoooter}
                  onHide={hideDialog}
               >
                  <Messages ref={dialogMessage} style={{ width: "100%" }} />

                  <div className="formgrid grid">
                     <div className="field col-12 md:col-6">
                        <label htmlFor="companyOrBusinessName">Company or business name</label>
                        <InputText
                           id="companyOrBusinessName"
                           value={supplier.companyOrBusinessName || ""}
                           onChange={(e) => onInputChange(e, "companyOrBusinessName")}
                           autoFocus
                        />
                     </div>
                     <div className="field col-12 md:col-6">
                        <label htmlFor="email">Email</label>
                        <InputText id="email" value={supplier.email || ""} onChange={(e) => onInputChange(e, "email")} autoFocus />
                     </div>
                  </div>

                  <div className="formgrid grid">
                     <div className="field col-6 md:col-6">
                        <label htmlFor="phoneNumber">Phone number</label>
                        <PhoneInput
                           enableSearch
                           id="primaryPhoneNumber"
                           className={classNames({
                              "country-code-reduced error-Input": submitted && !supplier.phoneNumber,
                              "country-code-reduced": true
                           })}
                           value={supplier.phoneNumber || ""}
                           onChange={(e) => onInputChange(e, "phoneNumber")}
                           country={"ug"}
                           inputProps={{
                              required: true
                           }}
                        />
                        {submitted && !supplier.phoneNumber && <small className="p-invalid">Phone is required.</small>}
                     </div>
                     <div className="field col-6 md:col-6">
                        <label htmlFor="alternativePhoneNumber">Alternative number </label>
                        <PhoneInput
                           enableSearch
                           id="altPhoneNumber"
                           className={classNames({ "country-code-reduced": true })}
                           value={supplier.alternativePhoneNumber || ""}
                           onChange={(e) => onInputChange(e, "alternativePhoneNumber")}
                           country={"ug"}
                           inputProps={{
                              required: true
                           }}
                        />
                     </div>
                  </div>
                  <div className="field">
                     <label htmlFor="country">Country</label>
                     <Dropdown
                        id="country"
                        value={selectedCountry}
                        options={countries}
                        onChange={onCurrencyChange}
                        optionLabel={"name"}
                        filter
                        showClear
                        filterBy="name"
                        placeholder="Select Country"
                        className={classNames({
                           "p-invalid": submitted && !selectedCountry
                        })}
                     />
                     {submitted && !selectedCountry && <small className="p-error">Country is required.</small>}
                  </div>
                  <h6>Contact person</h6>
                  <div className="formgrid grid" style={{ marginTop: "10px" }}>
                     <div className="field col-12 md:col-4">
                        <label htmlFor="firstName">First Name</label>
                        <InputText
                           id="firstName"
                           value={supplier.firstName || ""}
                           onChange={(e) => onInputChange(e, "firstName")}
                           required
                           autoFocus
                           className={classNames({
                              "p-invalid": submitted && !supplier.firstName
                           })}
                        />
                        {submitted && !supplier.firstName && <small className="p-invalid">First name is required.</small>}
                     </div>
                     <div className="field col-12 md:col-4">
                        <label htmlFor="lastName">Last Name</label>
                        <InputText
                           id="lastName"
                           value={supplier.lastName || ""}
                           onChange={(e) => onInputChange(e, "lastName")}
                           required
                           autoFocus
                           className={classNames({
                              "p-invalid": submitted && !supplier.lastName
                           })}
                        />
                        {submitted && !supplier.lastName && <small className="p-invalid">Last name is required.</small>}
                     </div>
                     <div className="field col-12 md:col-4">
                        <label htmlFor="otherName">Other Name</label>
                        <InputText id="otherName" value={supplier.otherName || ""} onChange={(e) => onInputChange(e, "otherName")} autoFocus />
                     </div>
                  </div>

                  <div className="field">
                     <label htmlFor="remarks">Remarks</label>
                     <InputTextarea
                        style={{ height: 100 }}
                        id="remarks"
                        value={supplier.remarks || ""}
                        onChange={(e) => onInputChange(e, "remarks")}
                        required
                        autoFocus
                        className={classNames({
                           "p-invalid": submitted && !supplier.remarks
                        })}
                     />
                     {submitted && !supplier.remarks && <small className="p-invalid">Remarks is required.</small>}
                  </div>
               </Dialog>

               <Dialog
                  visible={deleteSupplierDialog}
                  style={{ width: "450px" }}
                  header="Confirm"
                  modal
                  footer={deleteManufacturerDialogFooter}
                  onHide={hideShopDialog}
               >
                  <div className="flex align-items-center justify-content-center">
                     <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                     {supplier && (
                        <span>
                           Are you sure you want to delete <b>{supplier.fullName}</b>?
                        </span>
                     )}
                  </div>
               </Dialog>

               <Dialog
                  visible={deleteSuppliersDialog}
                  style={{ width: "450px" }}
                  header="Confirm"
                  modal
                  footer={deleteManufacturersDialogFooter}
                  onHide={hideDeleteShopsDialog}
               >
                  <div className="flex align-items-center justify-content-center">
                     <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                     {supplier && <span>Are you sure you want to delete the selected suppliers?</span>}
                  </div>
               </Dialog>
            </div>
         </div>
      </div>
   );
};
export default Supplier;
