import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";

import { BaseApiService } from "../../utils/BaseApiService";
import { showErrorMessage } from "../../constants/ErrorMessages";
import { Dropdown } from "primereact/dropdown";
import { formatNumberWithCommas, toReadableDate } from "../../utils/Utils";

import { MAXIMUM_RECORDS_PER_PAGE } from "../../constants/Constants";
import { Paginator } from "primereact/paginator";
import StatusSummary from "../../components/StatusSummary";
import StorageParams from "../../constants/StorageParams";

const ShopFinancialSummary = () => {
   const [financialRecords, setFinancialRecords] = useState(null);

   const [isLoading, setIsLoading] = useState(false);
   const [first, setFirst] = useState(0);
   const [totalRecords, setTotalRecords] = useState(0);
   const [limit, setLimit] = useState(0);
   const [searchTerm, setSearchTerm] = useState("");
   let offset = 0;

   const toast = useRef(null);
   const dt = useRef(null);

   const fetchSummary = () => {
      let searchParameters = {
         offset: offset,
         searchTerm: searchTerm,
         limit: MAXIMUM_RECORDS_PER_PAGE,
         shopOwnerId: localStorage.getItem(StorageParams.IS_SHOP_OWNER) === "true" ? localStorage.getItem(StorageParams.IS_SHOP_OWNER_ID) : ""
      };

      new BaseApiService(`/shops`)
         .getRequestWithJsonResponse(searchParameters)
         .then((response) => {
            setFinancialRecords(response.records);
            setTotalRecords(response?.totalItems);
            setIsLoading(false);
            // console.log(response.records);
         })
         .catch((error) => {});
   };

   useEffect(() => {
      fetchSummary();
   }, [searchTerm]);

   const onPageChange = (e) => {
      offset = e.page * MAXIMUM_RECORDS_PER_PAGE;
      setFirst(e.first);
      setLimit(MAXIMUM_RECORDS_PER_PAGE);
      fetchSummary();
   };

   const header = (
      <div className="flex flex-column md:flex-row md:align-items-center">
         <h5 className="m-0 line relative">Financial Summary</h5>
         <span className="block mt-2 md:mt-0 p-input-icon-left ml-5">
            <div className="p-inputgroup flex-1">
               <InputText placeholder="Search" onInput={(e) => setSearchTerm(e.target.value)} />
               <Button icon="pi pi-search" className="primary-btn" />
            </div>
         </span>
      </div>
   );

   const template2 = {
      layout: "CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink",
      RowsPerPageDropdown: (options) => {
         const dropdownOptions = [
            { label: 10, value: 10 },
            { label: 20, value: 20 },
            { label: 50, value: 50 }
         ];

         return (
            <React.Fragment>
               <span className="mx-1" style={{ color: "var(--text-color)", userSelect: "none" }}></span>
               <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />
               <span className="mr-auto"></span>
            </React.Fragment>
         );
      },
      CurrentPageReport: (options) => {
         return (
            <span
               style={{
                  color: "var(--text-color)",
                  userSelect: "none",
                  width: "auto",
                  textAlign: "center"
               }}
            >
               Showing {options.first} to {options.last} of {options.totalRecords} Financial Records
            </span>
         );
      }
   };
   const cashAtHandTemplate = (rowData) => {
      return (
         <>
            <span className="p-column-title">Cash at hand</span>
            {formatNumberWithCommas(rowData?.performanceSummary?.totalStockValue)}
         </>
      );
   };

   const capitalTemplate = (rowData) => {
      return (
         <>
            <span className="p-column-title">Capital</span>
            {formatNumberWithCommas(rowData?.initialCapital)}
         </>
      );
   };

   const restockingTemplate = (rowData) => {
      return (
         <>
            <span className="p-column-title">Restocking</span>
            {formatNumberWithCommas(0)}
         </>
      );
   };
   const incomeTemplate = (rowData) => {
      return (
         <>
            <span className="p-column-title">Income</span>
            {formatNumberWithCommas(Math.abs(rowData?.performanceSummary?.totalStockValue || 0 - rowData?.performanceSummary?.totalSalesValue || 0))}
         </>
      );
   };
   const expensesTemplate = (rowData) => {
      return (
         <>
            <span className="p-column-title">Expenses</span>
            {formatNumberWithCommas(rowData?.performanceSummary?.totalExpenses || 0)}
         </>
      );
   };

   const dateCreatedTemplate = (rowData) => {
      return (
         <>
            <span className="p-column-title">Date</span>
            {toReadableDate(rowData.dateCreated)}
         </>
      );
   };

   const actionBodyTemplate = (rowData) => {
      return (
         <div className="actions flex justify-content-center md:align-items-center ">
            <Button icon="pi pi-print" className="p-button-rounded p-button-text p-button-info mt-1" />

            <Button
               icon="pi pi-eye"
               className="p-button-rounded p-button-text p-button-success mt-1"
               onClick={() => {
                  // setSelectedSale(rowData);
                  // setProductDialog(true);
               }}
            />
         </div>
      );
   };

   return (
      <div className="grid crud-demo">
         <div className="col-12">
            <div className="py-2">
               <div className="grid">
                  <StatusSummary title="Shops" value={totalRecords} />
                  <StatusSummary title="Stock" value="0" iconName="pi-map-marker" />
                  <StatusSummary title="Income" value="0" iconName="pi-inbox" />
                  <StatusSummary title="Profit" value="0" />
               </div>
            </div>

            <div className="card">
               <Toast ref={toast} />

               <DataTable
                  ref={dt}
                  loading={isLoading}
                  value={financialRecords}
                  dataKey="id"
                  rows={10}
                  className="datatable-responsive"
                  emptyMessage="No records found."
                  header={header}
                  size="small"
                  resizableColumns
               >
                  <Column field="name" header="Date" body={dateCreatedTemplate} />

                  <Column field="name" header="Shop" />
                  <Column field="stock" header="Stock cash" body={cashAtHandTemplate} className="text-center" headerStyle={{ textAlign: "center" }} />
                  <Column
                     field="initialCapital"
                     header="Capital"
                     body={capitalTemplate}
                     className="text-center"
                     headerStyle={{ textAlign: "center" }}
                  />
                  <Column
                     field="distributors"
                     header="Restock"
                     body={restockingTemplate}
                     className="text-center"
                     headerStyle={{ textAlign: "center" }}
                  />
                  <Column field="products" header="Income" body={incomeTemplate} className="text-center" headerStyle={{ textAlign: "center" }} />
                  <Column field="createdBy" header="Expenses" body={expensesTemplate} className="text-center" headerStyle={{ textAlign: "center" }} />
                  <Column body={actionBodyTemplate} header="Actions" className="text-center" headerStyle={{ textAlign: "center" }} />
               </DataTable>
               <Paginator
                  template={template2}
                  first={first}
                  rows={MAXIMUM_RECORDS_PER_PAGE}
                  totalRecords={totalRecords}
                  alwaysShow={true}
                  onPageChange={onPageChange}
               />
            </div>
         </div>
      </div>
   );
};
export default ShopFinancialSummary;
