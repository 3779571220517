import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Messages } from "primereact/messages";
import React, { useEffect, useRef, useState } from "react";
import { BaseApiService } from "../../utils/BaseApiService";
import { UserSessionUtils } from "../../utils/UserSessionUtils";
import { Dropdown } from "primereact/dropdown";
import { isValidPhoneNumber } from "../../utils/Utils";
import { showErrorMessage } from "../../constants/ErrorMessages";
import { classNames } from "primereact/utils";
import PhoneInput from "react-phone-input-2";

export default function NewClientForm({ clientDialog, setClientDialog, toast, refreshTable, selectedClient }) {
   const [isLoading, setIsLoading] = useState(false);
   const [name, setName] = useState("");
   const [phone, setPhone] = useState("");
   const [selectedShop, setSelectedShop] = useState(null);
   const [shops, setShops] = useState([]);
   const [submitted, setSubmitted] = useState(false);
   const [edit, setEdit] = useState(false);

   const pupulateForm = () => {
      clearForm();

      if (selectedClient !== null && selectedClient !== undefined) {
         setEdit(true);
         setPhone(selectedClient?.phoneNumber);
         setName(selectedClient?.fullName);
         setSelectedShop(selectedClient?.shop);
      }
   };

   const clearForm = () => {
      setPhone("");
      setName("");
   };

   const handleSubmit = () => {
      setSubmitted(true);
      const payload = {
         id: edit ? selectedClient?.id : 0,
         phoneNumber: phone,
         fullName: name,
         shopId: selectedShop?.id
      };

      const isValidSubmission = name !== "" && isValidPhoneNumber(phone);

      if (isValidSubmission) {
         setIsLoading(true);
         new BaseApiService("/clients-controller")
            .saveRequestWithJsonResponse(payload, false)
            .then((response) => {
               setIsLoading(false);
               hideDialog();
               toast.current.show({
                  severity: "success",
                  summary: "Success",
                  detail: "Client details saved",
                  life: 3000
               });
               refreshTable();
            })
            .catch((error) => {
               showErrorMessage(dialogMessage, error.message);
               setIsLoading(false);
               setSubmitted(false);
            });
      }
   };

   const fetchShops = async () => {
      let searchParameters = { offset: 0, limit: 0 };

      if (UserSessionUtils.getShopOwnerId()) {
         searchParameters.shopOwnerId = UserSessionUtils.getShopOwnerId();
      }

      new BaseApiService("/shops")
         .getRequestWithJsonResponse(searchParameters)
         .then(async (response) => {
            setShops(response.records);
            if (response.records.length === 1) {
               setSelectedShop(response.records[0]);
            }
         })
         .catch((error) => {
            showErrorMessage(dialogMessage, error.message);
         });
   };

   const dialogMessage = useRef();

   const hideDialog = () => {
      setClientDialog(false);
      setSubmitted(false);
      setEdit(false);
      clearForm();
   };

   const productDialogFooter = (
      <>
         <Button
            loading={isLoading}
            label="Cancel"
            icon="pi pi-times"
            className=" outline-btn"
            onClick={() => {
               hideDialog();
            }}
         />
         <Button loading={isLoading} label="Save" icon="pi pi-check" className=" primary-btn" onClick={handleSubmit} />
      </>
   );

   useEffect(() => {
      fetchShops();
   }, []);

   useEffect(() => {
      pupulateForm();
   }, [selectedClient]);
   return (
      <Dialog
         visible={clientDialog}
         style={{ width: "500px" }}
         header="New Client"
         modal
         className="p-fluid"
         footer={productDialogFooter}
         onHide={() => {
            hideDialog();
         }}
      >
         <Messages ref={dialogMessage} style={{ width: "100%" }} />

         <div className="field">
            <div className="field">
               <label htmlFor="product">Select Shop</label>
               <Dropdown
                  value={selectedShop}
                  options={edit ? [selectedShop] : shops}
                  optionLabel={(product) => `${product.name} `}
                  onChange={(e) => {
                     setSelectedShop(e.value);
                  }}
                  placeholder="Select shop"
                  className={classNames({ "p-invalid": submitted && !selectedShop })}
               />
            </div>
            <div className="field">
               <label>Client name</label>
               <InputText value={name} onChange={(e) => setName(e.target.value)} />
               {submitted && !name && <small className="p-error">Client name is required.</small>}
            </div>

            <div className="field">
               <label>Phone number</label>
               <PhoneInput
                  id="altPhoneNumber"
                  className={classNames({ "country-code-reduced-sm": true })}
                  value={phone || ""}
                  onChange={(e) => setPhone(e)}
                  country={"ug"}
                  enableSearch
                  inputProps={{
                     required: true
                  }}
               />
            </div>
         </div>
      </Dialog>
   );
}
