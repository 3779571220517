import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { BaseApiService } from "../../utils/BaseApiService";
import { Dropdown } from "primereact/dropdown";
import { formatNumberWithCommas, isNotEmpty, toReadableDateTime } from "../../utils/Utils";
import { UserSessionUtils } from "../../utils/UserSessionUtils";
import StatusSummary from "../../components/StatusSummary";
import { SHOPS_ENDPOINT } from "../../utils/EndpointsUtils";
import ShopDetailsForm from "../../components/dialogs/ShopDetailsForm";
import { template2 } from "components/PaginatorTemplate";
import { MAXIMUM_RECORDS_PER_PAGE } from "constants/Constants";
import { Paginator } from "primereact/paginator";
import { PrimeIcons } from "primereact/api";
import { generatePath, useNavigate } from "react-router-dom";
import { SHOP_PROFILE_ROUTE_PATH } from "utils/NavigationRoutes";

const ShopsList = () => {
   const [shops, setShops] = useState(null);
   const [shopDialog, setShopDialog] = useState(false);
   const [deleteShopDialog, setDeleteShopDialog] = useState(false);
   const [shop, setShop] = useState(null);
   const [loading, setLoading] = useState(false);
   const [searchTerm, setSearchTerm] = useState(null);
   let offset = 0;
   const [totalRecords, setTotalRecords] = useState(0);
   const [limit, setLimit] = useState(MAXIMUM_RECORDS_PER_PAGE);
   const toast = useRef(null);
   const dt = useRef(null);
   const navigate = useNavigate();

   const isAdmin = UserSessionUtils.getSuperAdmin();

   const fetchShops = async () => {
      let searchParameters = { offset: offset, limit: limit };
      if (isNotEmpty(searchTerm)) searchParameters.searchTerm = searchTerm;
      if (UserSessionUtils.getShopOwner()) {
         searchParameters.shopOwnerId = UserSessionUtils.getShopOwnerId();
      }
      setLoading(true);
      await new BaseApiService(SHOPS_ENDPOINT)
         .getRequestWithJsonResponse(searchParameters)
         .then(async (response) => {
            setShops(response.records);
            setTotalRecords(response.totalItems);
            setLoading(false);
            const offersDebt = [...response.records].some((shop) => shop?.supportsCreditSales === true);
            UserSessionUtils.setOffersDebt(offersDebt);
         })
         .catch((error) => {
            setLoading(false);
         });
   };

   useEffect(() => {
      fetchShops();
   }, []);

   const handleSelection = (rowData) => {
      const updatedData = { ...rowData, entity: "shop" };
      navigate(generatePath(SHOP_PROFILE_ROUTE_PATH, { shopName: rowData.name }), { state: updatedData });
   };

   const openNew = () => {
      setShop(null);
      setShopDialog(true);
   };

   const hideDialog = () => {
      setShopDialog(false);
      setShop(null);
      setLoading(false);
   };

   const hideShopDialog = () => {
      setDeleteShopDialog(false);
   };

   const editShop = (shop) => {
      setShop({ ...shop });
      setShopDialog(true);
   };

   const confirmDeleteShop = (shop) => {
      setShop(shop);
      setDeleteShopDialog(true);
   };

   const deleteShop = () => {
      new BaseApiService(`${SHOPS_ENDPOINT}/${shop.id}`)
         .deleteRequestWithJsonResponse()
         .then((response) => {
            let _shops = shops.filter((val) => val.id !== shop.id);
            setShops(_shops);
            setDeleteShopDialog(false);
            setShop(null);
            toast.current.show({
               severity: "success",
               summary: "Successful",
               detail: "Shop Deleted",
               life: 3000
            });
         })
         .catch((error) => {
            console.log(error);
         });
   };

   const updateShopSummariesShop = (shopId) => {
      setLoading(true);
      new BaseApiService(`${SHOPS_ENDPOINT}/${shopId}/update-summary`)
         .postRequestWithJsonResponse()
         .then((response) => {
            setLoading(false);
         })
         .catch((error) => {
            console.log(error);
            setLoading(false);
         });
   };

   const dateCreatedBodyTemplate = (rowData) => {
      return <>{toReadableDateTime(rowData.dateCreated)}</>;
   };

   const onPageChange = (e) => {
      offset = e.page * MAXIMUM_RECORDS_PER_PAGE;
      setLimit(MAXIMUM_RECORDS_PER_PAGE);

      fetchShops();
   };

   const actionBodyTemplate = (rowData) => {
      return (
         <div className="actions flex md:flex-row md:align-items-center justify-content-center">
            <Button
               label={String.fromCharCode(0xea12)}
               className="p-button-rounded p-button-text p-button-success"
               style={{ fontFamily: "Material Icons", fontSize: "18px" }}
               onClick={() => handleSelection(rowData)}
            />
            <Button icon="pi pi-pencil" className="p-button-rounded p-button-text p-button-success " onClick={() => editShop(rowData)} />
            <Button
               icon={PrimeIcons.REFRESH}
               loading={loading}
               className="p-button-rounded p-button-text p-button-warning "
               onClick={(r) => updateShopSummariesShop(rowData.id)}
            />

            {shops?.length > 1 && (
               <Button
                  icon="pi pi-trash"
                  className="p-button-rounded p-button-text p-button-danger mt-2"
                  onClick={() => confirmDeleteShop(rowData)}
               />
            )}
         </div>
      );
   };

   const serialTemplate = (rowData) => (
      <label className=" text-black-alpha-50 cursor-pointer" onClick={() => handleSelection(rowData)}>
         {rowData?.serialNumber}
      </label>
   );

   const header = (
      <div className="flex flex-column md:flex-row md:align-items-center">
         <h5 className="m-0 line relative">Shops</h5>
         <span className="block mt-2 md:mt-0 p-input-icon-left ml-5">
            <div className="p-inputgroup flex-1">
               <InputText placeholder="Search" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
               <Button icon="pi pi-search" loading={loading} className="primary-btn" onClick={fetchShops} />
            </div>
         </span>
         {isAdmin === true && (
            <div className="my-2 ml-auto">
               <Button label="New" icon="pi pi-plus" className="primary-btn mr-2" onClick={openNew} />
            </div>
         )}
      </div>
   );

   const deleteShopDialogFooter = (
      <>
         <Button label="No" icon="pi pi-times" className="outline-btn" onClick={hideShopDialog} />
         <Button label="Yes" icon="pi pi-check" className="primary-btn" onClick={deleteShop} />
      </>
   );

   return (
      <div className="grid crud-demo">
         <div className="col-12">
            <div className="py-2">
               <div className="grid">
                  <StatusSummary iconName="pi pi-shopping-cart " title="Shops" value={formatNumberWithCommas(totalRecords)} />
                  <StatusSummary title="Attendants" value={formatNumberWithCommas(0)} iconName="bx bx-receipt" />
                  <StatusSummary title="Capital" value={formatNumberWithCommas(Math.round(0))} iconName="pi pi-money-bill" />
                  <StatusSummary title="Removed Capital" value={formatNumberWithCommas(0)} iconName="bx bx-wallet" />
               </div>
            </div>

            <div className="card">
               <Toast ref={toast} />

               <DataTable
                  ref={dt}
                  loading={loading}
                  loadingIcon={PrimeIcons.SYNC}
                  value={shops}
                  dataKey="id"
                  rows={limit}
                  className="datatable-responsive"
                  emptyMessage="No shop found."
                  header={header}
                  resizableColumns
                  size="small"
               >
                  <Column body={serialTemplate} header="Serial Number"/>
                  <Column field="name" header="Name" />
                  <Column field="ownerName" header="Owner" />
                  <Column field="physicalLocation" header="Location" />
                  <Column field="registrationNumber" header="Reg No." />
                  <Column field="categoryName" header="Category" />
                  <Column field="changedByFullName" header="Created by" />
                  <Column field="dateCreated" header="Created on" body={dateCreatedBodyTemplate} />
                  {isAdmin && <Column body={actionBodyTemplate} header="Actions" className="text-center" />}
               </DataTable>
               <Paginator
                  template={template2}
                  first={offset}
                  rows={MAXIMUM_RECORDS_PER_PAGE}
                  totalRecords={totalRecords}
                  alwaysShow={false}
                  onPageChange={(e) => onPageChange(e)}
               />
               <ShopDetailsForm
                  visible={shopDialog}
                  selectedShop={shop}
                  hideDialog={hideDialog}
                  onComplete={() => {
                     toast.current.show({
                        severity: "success",
                        summary: "Successful",
                        detail: "Shop Details Saved",
                        life: 3000
                     });
                     fetchShops();
                  }}
               />

               <Dialog
                  visible={deleteShopDialog}
                  style={{ width: "450px" }}
                  header="Confirm"
                  modal
                  footer={deleteShopDialogFooter}
                  onHide={hideShopDialog}
               >
                  <div className="flex align-items-center justify-content-center">
                     <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                     {shop && (
                        <span>
                           Are you sure you want to delete <b>{shop.name}</b>?
                        </span>
                     )}
                  </div>
               </Dialog>
            </div>
         </div>
      </div>
   );
};
export default ShopsList;
