import React, { useState, useRef, useEffect } from "react";
import { classNames } from "primereact/utils";
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";

import AppTopbar from "./AppTopbar";
import AppConfig from "./AppConfig";
import AppMenu from "./AppMenu";
import AppInlineProfile from "./AppInlineProfile";

import PrimeReact from "primereact/api";
import { Tooltip } from "primereact/tooltip";

import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "./App.scss";
import { Login } from "./pages/Login";
import { UserSessionUtils } from "./utils/UserSessionUtils";
import { MainNavigationConfig } from "./utils/MainNavigationConfig";
import logo from "./assets/logos/yellow_transparent.png";
import { getTimeDifference } from "./utils/Utils";

const App = () => {
   const [menuActive, setMenuActive] = useState(false);
   const [menuMode, setMenuMode] = useState("static");
   const [darkMenu, setDarkMenu] = useState(true);
   const [overlayMenuActive, setOverlayMenuActive] = useState(false);
   const [topbarMenuActive, setTopbarMenuActive] = useState(false);
   const [staticMenuDesktopInactive, setStaticMenuDesktopInactive] = useState(false);
   const [staticMenuMobileActive, setStaticMenuMobileActive] = useState(false);
   const [activeTopbarItem, setActiveTopbarItem] = useState(null);
   const [inlineMenuActive, setInlineMenuActive] = useState(false);
   const [profileMode, setProfileMode] = useState("popup");
   const [configActive, setConfigActive] = useState(false);
   const [inputStyle, setInputStyle] = useState("outlined");
   const [ripple, setRipple] = useState(false);
   const copyTooltipRef = useRef();
   const location = useLocation();

   const navigate = useNavigate();

   let menuClick = false;
   let configClick = false;
   let topbarItemClick = false;
   let inlineMenuClick = false;

   const breadcrumb = [{ path: "/", parent: "Dashboard", label: "Dashboard" }];
   const token = UserSessionUtils.getBearerToken();

   const routers = token
      ? new MainNavigationConfig().getAuthenticatedComponetRouters()
      : new MainNavigationConfig().getUnAuthenticatedComponetRouters();
   const navMenu = token ? new MainNavigationConfig().getNavigationMenu() : [];

   useEffect(() => {
      copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
   }, [location]);

   const handleLoginSession = () => {
      let prevLoginTime = UserSessionUtils.getLoginTime();

      let logintimeDifferance = getTimeDifference(prevLoginTime, new Date());

      const { hours, days } = logintimeDifferance;

      const timeOut = hours >= 20 || days >= 1;

      if (timeOut && token) {
         UserSessionUtils.clearLocalStorageAndLogout();
         navigate("/login");
      }
   };

   useEffect(() => {
      handleLoginSession();
   }, []);

   let meta = breadcrumb.find((obj) => {
      return obj.path === location.pathname;
   });

   const onInputStyleChange = (inputStyle) => {
      setInputStyle(inputStyle);
   };

   const onRippleChange = (e) => {
      PrimeReact.ripple = e.value;
      setRipple(e.value);
   };

   const onMenuModeChange = (e) => {
      setMenuMode(e.value);
      setStaticMenuDesktopInactive(false);
      setOverlayMenuActive(false);

      if (e.value === "horizontal") {
         setProfileMode("popup");
      }
   };

   const onMenuColorChange = (e) => {
      setDarkMenu(e.value);
   };

   const onProfileChange = (e) => {
      setProfileMode(e.value);
   };

   const onDocumentClick = () => {
      if (!topbarItemClick) {
         setActiveTopbarItem(null);
         setTopbarMenuActive(false);
      }

      if (!menuClick) {
         if (isHorizontal() || isSlim()) {
            setMenuActive(false);
         }
         hideOverlayMenu();
      }

      if (!inlineMenuClick && profileMode === "inline" && isSlim() && !isMobile()) {
         setInlineMenuActive(false);
      }

      if (configActive && !configClick) {
         setConfigActive(false);
      }

      inlineMenuClick = false;
      configClick = false;
      topbarItemClick = false;
      menuClick = false;
   };

   const onMenuitemClick = (event) => {
      if (event.item.label && event.item.label === "Logout") {
         localStorage.clear();
      }

      if (!event.item.items) {
         hideOverlayMenu();

         if (isSlim() || isHorizontal()) {
            setMenuActive(false);
         }
      }
   };

   const onRootMenuitemClick = () => {
      setMenuActive((prevMenuActive) => !prevMenuActive);
   };

   const onMenuClick = () => {
      menuClick = true;

      if (inlineMenuActive && !inlineMenuClick) {
         setInlineMenuActive(false);
      }
   };

   const isMenuVisible = () => {
      if (isDesktop()) {
         if (menuMode === "static") return !staticMenuDesktopInactive;
         else if (menuMode === "overlay") return overlayMenuActive;
         else return true;
      } else {
         return true;
      }
   };

   const onMenuButtonClick = (event) => {
      menuClick = true;
      setTopbarMenuActive(false);

      if (isOverlay() && !isMobile()) {
         setOverlayMenuActive((prevOverlayMenuActive) => !prevOverlayMenuActive);
      } else {
         if (isDesktop()) {
            setStaticMenuDesktopInactive((prevStaticMenuDesktopInactive) => !prevStaticMenuDesktopInactive);
         } else {
            setStaticMenuMobileActive((prevStaticMenuMobileActive) => !prevStaticMenuMobileActive);
         }
      }

      event.preventDefault();
   };

   const onProfileButtonClick = (event) => {
      setInlineMenuActive((prevInlineMenuActive) => !prevInlineMenuActive);
      inlineMenuClick = true;

      if (isSlim() || isHorizontal()) {
         setMenuActive(false);
      }
   };

   const onTopbarMenuButtonClick = (event) => {
      topbarItemClick = true;
      setTopbarMenuActive((prevTopbarMenuActive) => !prevTopbarMenuActive);

      hideOverlayMenu();

      event.preventDefault();
   };

   const onTopbarItemClick = (event, item) => {
      topbarItemClick = true;

      if (activeTopbarItem === item) {
         setActiveTopbarItem(null);
      } else {
         setActiveTopbarItem(item);
      }

      event.preventDefault();
   };

   const onConfigClick = () => {
      configClick = true;
   };

   const onConfigButtonClick = () => {
      setConfigActive((prevConfigActive) => !prevConfigActive);
      configClick = true;
   };

   const hideOverlayMenu = () => {
      setOverlayMenuActive(false);
      setStaticMenuMobileActive(false);
   };

   const isDesktop = () => {
      return window.innerWidth > 896;
   };

   const isMobile = () => {
      return window.innerWidth <= 896;
   };

   const isOverlay = () => {
      return menuMode === "overlay";
   };

   const isHorizontal = () => {
      return menuMode === "horizontal";
   };

   const isSlim = () => {
      return menuMode === "slim";
   };

   const isStatic = () => {
      return menuMode === "static";
   };

   const hasInlineProfile = profileMode === "inline" && !isHorizontal();

   const containerClassName = classNames("layout-wrapper", {
      "layout-static": isStatic(),
      "layout-overlay": isOverlay(),
      "layout-overlay-active": overlayMenuActive,
      "layout-horizontal": isHorizontal(),
      "layout-slim": isSlim(),
      "layout-static-inactive": staticMenuDesktopInactive,
      "layout-mobile-active": staticMenuMobileActive,
      "layout-menu-dark": darkMenu,
      "layout-menu-light": !darkMenu,
      "p-input-filled": inputStyle === "filled",
      "p-ripple-disabled": !ripple
   });

   const menuContainerClassName = classNames("layout-menu-container", {
      "layout-menu-container-inactive": !isMenuVisible()
   });
   if (!token) {
      return <Login />;
   }
   return (
      <div className={containerClassName} onClick={onDocumentClick}>
         <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" />

         <AppTopbar
            topbarMenuActive={topbarMenuActive}
            activeTopbarItem={activeTopbarItem}
            onMenuButtonClick={onMenuButtonClick}
            onTopbarMenuButtonClick={onTopbarMenuButtonClick}
            onTopbarItemClick={onTopbarItemClick}
            isHorizontal={isHorizontal()}
            profileMode={profileMode}
            isMobile={isMobile}
         />

         <div className={menuContainerClassName} onClick={onMenuClick}>
            <div className="layout-menu-logo">
               <button className="p-link flex gap-2 " onClick={() => navigate("/")}>
                  <img src={logo} alt="shopicon" className="shop-image2 mt-3" />
                  <span className="logo_name mt-3">Duqact</span>
               </button>
            </div>
            <div className="layout-menu-wrapper">
               <div className="menu-scroll-content">
                  {hasInlineProfile && <AppInlineProfile inlineMenuActive={inlineMenuActive} onProfileButtonClick={onProfileButtonClick} />}
                  <AppMenu
                     model={navMenu}
                     menuMode={menuMode}
                     active={menuActive}
                     onMenuitemClick={onMenuitemClick}
                     onRootMenuitemClick={onRootMenuitemClick}
                  />
               </div>
            </div>
         </div>

         <div className="layout-main">
            <div className="layout-content">
               <Routes>
                  {routers.map((router, index) => {
                     if (router.exact) {
                        return <Route key={`router${index}`} path={router.path} exact element={router.component} />;
                     }

                     return <Route key={`router${index}`} path={router.path} element={router.component} />;
                  })}
               </Routes>
            </div>

            {/* <AppFooter /> */}
         </div>

         {/* <AppConfig
                configActive={configActive}
                menuMode={menuMode}
                onMenuModeChange={onMenuModeChange}
                isDarkMenu={darkMenu}
                onMenuColorChange={onMenuColorChange}
                profileMode={profileMode}
                onProfileChange={onProfileChange}
                onConfigClick={onConfigClick}
                onConfigButtonClick={onConfigButtonClick}
                rippleActive={ripple}
                onRippleChange={onRippleChange}
                inputStyle={inputStyle}
                onInputStyleChange={onInputStyleChange}
            ></AppConfig> */}

         {staticMenuMobileActive && <div className="layout-mask"></div>}
      </div>
   );
};

export default App;
