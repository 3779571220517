import React from "react";
import { Dropdown } from "primereact/dropdown";

export const template2 = {
   layout: "CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink",
   RowsPerPageDropdown: (options) => {
      const dropdownOptions = [{ label: 10, value: 10 }];

      return (
         <React.Fragment>
            <span className="mx-1" style={{ color: "var(--text-color)", userSelect: "none" }}></span>
            <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />
            <span className="mr-auto"></span>
         </React.Fragment>
      );
   },
   CurrentPageReport: (options) => {
      return (
         <span
            style={{
               color: "var(--text-color)",
               userSelect: "none",
               width: "auto",
               textAlign: "center"
            }}
         >
            Showing {options.first} to {options.last} of {options.totalRecords} record{options.totalRecords > 1 ? "s" : ""}
         </span>
      );
   }
};
