import React, { useState, useEffect, useRef } from "react";
import { classNames } from "primereact/utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { BaseApiService } from "../../utils/BaseApiService";
import { showErrorMessage } from "../../constants/ErrorMessages";
import { Dropdown } from "primereact/dropdown";
import { Messages } from "primereact/messages";
import { formatNumberWithCommas, hasNull, toReadableDateTime } from "../../utils/Utils";
import { InputTextarea } from "primereact/inputtextarea";
import { Calendar } from "primereact/calendar";
import { Accordion, AccordionTab } from "primereact/accordion";
import StatusSummary from "../../components/StatusSummary";

const LookUpValue = () => {
   let initialLookUpValue = {
      id: 0,
      value: null,
      description: null
   };

   const [lookUpValues, setLookUpValues] = useState(null);
   const [lookUpValueDialog, setLookUpValueDialog] = useState(false);
   const [deleteLookUpValueDialog, setDeleteLookUpValueDialog] = useState(false);
   const [deleteLookUpValuesDialog, setDeleteLookUpValuesDialog] = useState(false);
   const [lookUpValue, setLookUpValue] = useState(initialLookUpValue);
   const [selectedLookUpValues, setSelectedLookUpValues] = useState(null);
   const [submitted, setSubmitted] = useState(false);
   const [globalFilter, setGlobalFilter] = useState(null);
   const [edit, setEdit] = useState(false);
   const [refresh, setRefresh] = useState(false);
   const [lookUpTypes, setLookUpTypes] = useState(null);
   const [selectedLookUpType, setSelectedLookUpType] = useState(null);
   const [loading, setLoading] = useState(false);

   const dialogMessage = useRef();
   const toast = useRef(null);
   const dt = useRef(null);

   const fetchLookUpTypes = async () => {
      let searchParameters = { searchTerm: "", offset: 0, limit: 0 };
      new BaseApiService("/lookups/lookup-types")
         .getRequestWithJsonResponse(searchParameters)
         .then(async (response) => {
            setLookUpTypes(response.records);
         })
         .catch((error) => {});
   };

   const fetchLookUpValues = async () => {
      setLoading(true);

      let searchParameters = { searchTerm: "", offset: 0, limit: 0 };
      new BaseApiService("/lookups/lookup-values")
         .getRequestWithJsonResponse(searchParameters)
         .then(async (response) => {
            setLookUpValues(response.records);
            setLoading(false);
         })
         .catch((error) => {
            setLoading(false);
         });
   };

   useEffect(() => {
      fetchLookUpTypes();
   }, []);

   useEffect(() => {
      fetchLookUpValues();
   }, [refresh]);

   const openNew = () => {
      setLookUpValue(initialLookUpValue);
      setSubmitted(false);
      setLookUpValueDialog(true);
      setEdit(false);
   };

   const hideDialog = () => {
      setSubmitted(false);
      setLookUpValueDialog(false);
   };

   const hideLookUpValueDialog = () => {
      setDeleteLookUpValueDialog(false);
   };

   const hideDeleteShopsDialog = () => {
      setDeleteLookUpValuesDialog(false);
   };

   const saveLookUpValue = () => {
      setSubmitted(true);
      let payload = {
         value: lookUpValue?.value,
         description: lookUpValue?.description,
         typeId: selectedLookUpType?.id
      };
      setLoading(true);
      const apiUrl = edit && edit === true ? "/lookups/lookup-values/" + lookUpValue.id : "/lookups/lookup-values";

      if (hasNull(payload) === false) {
         new BaseApiService(apiUrl)
            .saveRequestWithJsonResponse(payload, edit)
            .then((response) => {
               if (edit && edit === true) {
                  setRefresh(true);
               } else {
                  let _lookUpValues = [...lookUpValues];

                  let _lookUpValue = { ...response };

                  _lookUpValues.push(_lookUpValue);

                  setLookUpValues(_lookUpValues);
               }
               setLookUpValueDialog(false);
               setLookUpValue(initialLookUpValue);
               fetchLookUpValues();
               toast.current.show({
                  severity: "success",
                  summary: "Successful",
                  detail: "LookUpValue Details saved",
                  life: 3000
               });
               setLoading(false);
            })
            .catch((error) => {
               showErrorMessage(dialogMessage, error.message);
               setLoading(false);
            });
      } else {
         setLoading(false);
      }
   };

   const editLookUpValue = (lookUpValue) => {
      setEdit(true);

      setLookUpValue({ ...lookUpValue });
      setSelectedLookUpType(lookUpTypes.find((obj) => obj.id === lookUpValue.typeId));
      setLookUpValueDialog(true);
   };

   const confirmDeleteLookUpValue = (lookUpValue) => {
      setLookUpValue(lookUpValue);
      setDeleteLookUpValueDialog(true);
   };

   const deleteLookUpValue = () => {
      new BaseApiService("/lookups/lookup-values/" + lookUpValue.id)
         .deleteRequestWithJsonResponse()
         .then((response) => {
            let _lookUpValues = lookUpValues.filter((val) => val.id !== lookUpValue.id);
            setLookUpValues(_lookUpValues);
            setDeleteLookUpValueDialog(false);
            setLookUpValue(initialLookUpValue);
            toast.current.show({
               severity: "success",
               summary: "Successful",
               detail: "Look up value Deleted",
               life: 3000
            });
         })
         .catch((error) => {});
   };

   const confirmDeleteSelected = () => {
      setDeleteLookUpValuesDialog(true);
   };

   const deleteLookUpValues = () => {
      let _lookUpValues = lookUpValues.filter((val) => !selectedLookUpValues.includes(val));
      setLookUpValues(_lookUpValues);
      setDeleteLookUpValuesDialog(false);
      setSelectedLookUpValues(null);
      toast.current.show({
         severity: "success",
         summary: "Successful",
         detail: "Look up values Deleted",
         life: 3000
      });
   };

   const onInputChange = (e, name) => {
      const val = (e.target && e.target.value) || "";
      let _lookUpValue = { ...lookUpValue };
      _lookUpValue[`${name}`] = val;

      setLookUpValue(_lookUpValue);
   };

   const onLookUpTypeChange = (e) => {
      setSelectedLookUpType(e.target.value);
   };

   const valueBodyTemplate = (rowData) => {
      return (
         <>
            <span className="p-column-title">Value</span>
            {rowData.value}
         </>
      );
   };

   const descriptionBodyTemplate = (rowData) => {
      return (
         <>
            <span className="p-column-title">Description</span>
            {rowData.description}
         </>
      );
   };

   const lookupTypeBodyTemplate = (rowData) => {
      return (
         <>
            <span className="p-column-title">Type name</span>
            {rowData.typeName}
         </>
      );
   };

   const createdByFullNameBodyTemplate = (rowData) => {
      return (
         <>
            <span className="p-column-title">Created by</span>
            {rowData.createdByFullName}
         </>
      );
   };

   const dateCreatedBodyTemplate = (rowData) => {
      return (
         <>
            <span className="p-column-title">Date Created</span>
            {toReadableDateTime(rowData.dateCreated)}
         </>
      );
   };

   const actionBodyTemplate = (rowData) => {
      return (
         <div className="actions flex md:flex-row md:align-items-center">
            <Button icon="pi pi-pencil" className="p-button-rounded p-button-text p-button-success mr-2" onClick={() => editLookUpValue(rowData)} />
            <Button
               icon="pi pi-trash"
               className="p-button-rounded p-button-text p-button-danger mt-2"
               onClick={() => confirmDeleteLookUpValue(rowData)}
            />
         </div>
      );
   };

   const header = (
      <div className="flex flex-column md:flex-row md:align-items-center">
         <h5 className="m-0 line relative">Lookup values</h5>
         <span className="block mt-2 md:mt-0 p-input-icon-left ml-5">
            <div className="p-inputgroup flex-1">
               <InputText placeholder="Search" onInput={(e) => setGlobalFilter(e.target.value)} />
               <Button icon="pi pi-search" className="primary-btn" />
            </div>
         </span>
         <div className="my-2 ml-auto">
            <Button label="New" icon="pi pi-plus" className="primary-btn mr-2" onClick={openNew} />
            <Button
               label="Delete"
               icon="pi pi-trash"
               className="p-button-danger"
               onClick={confirmDeleteSelected}
               style={selectedLookUpValues && selectedLookUpValues.length > 1 ? { display: "inline-flex" } : { display: "none" }}
            />
         </div>
      </div>
   );

   const lookUpValueDialogFooter = (
      <>
         <Button loading={loading} label="Cancel" icon="pi pi-times" className="outline-btn" onClick={hideDialog} />
         <Button loading={loading} label="Save" icon="pi pi-check" className="primary-btn" onClick={saveLookUpValue} />
      </>
   );
   const deleteLookUpValueDialogFooter = (
      <>
         <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideLookUpValueDialog} />
         <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteLookUpValue} />
      </>
   );
   const deleteLookUpValuesDialogFooter = (
      <>
         <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteShopsDialog} />
         <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteLookUpValues} />
      </>
   );

   const template2 = {
      layout: "CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink",
      RowsPerPageDropdown: (options) => {
         const dropdownOptions = [
            { label: 10, value: 10 },
            { label: 20, value: 20 },
            { label: 50, value: 50 }
         ];

         return (
            <React.Fragment>
               <span className="mx-1" style={{ color: "var(--text-color)", userSelect: "none" }}></span>
               <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />
               <span className="mr-auto"></span>
            </React.Fragment>
         );
      },
      CurrentPageReport: (options) => {
         return (
            <span
               style={{
                  color: "var(--text-color)",
                  userSelect: "none",
                  width: "auto",
                  textAlign: "center"
               }}
            >
               Showing {options.first} to {options.last} of {options.totalRecords} lookUp values
            </span>
         );
      }
   };

   return (
      <div className="grid crud-demo">
         <div className="col-12">
            <div className="py-2">
               <div className="grid">
                  <StatusSummary iconName="pi pi-shopping-cart " title="Total" value={formatNumberWithCommas(0)} />
                  <StatusSummary title="Categories" value={formatNumberWithCommas(0)} iconName="bx bx-receipt" />
                  <StatusSummary title="Capital" value={formatNumberWithCommas(Math.round(0))} iconName="pi pi-money-bill" />
                  <StatusSummary title="Active" value={formatNumberWithCommas(0)} iconName="bx bx-wallet" />
               </div>
            </div>
            <div className="py-2 -mt-3">
               <Accordion>
                  <AccordionTab className="p-fluid" header="Filter Lookup">
                     <div className="formgrid grid">
                        <div className="field col">
                           <label htmlFor="purchasedQuantity">Price range</label>
                           <InputNumber id="purchasedQuantity" />
                        </div>
                        <div className="field col">
                           <label htmlFor="purchasePrice">Amount range</label>
                           <InputNumber id="purchasePrice" />
                        </div>
                        <div className="field col">
                           <label htmlFor="purchasePrice">Batch no</label>
                           <InputNumber id="purchasePrice" />
                        </div>
                     </div>

                     <div className="formgrid grid">
                        <div className="field col">
                           <label htmlFor="purchasedQuantity">Purchase date</label>
                           <InputNumber id="purchasedQuantity" />
                        </div>
                        <div className="field col">
                           <label htmlFor="purchasePrice">Purchase by</label>
                           <InputNumber id="purchasePrice" />
                        </div>
                        <div className="field col">
                           <label htmlFor="purchasePrice">Expiry date</label>
                           <Calendar />
                        </div>
                     </div>
                     <div className="formgrid grid">
                        <div className="field col">
                           <label htmlFor="purchasedQuantity">Category</label>
                           <InputNumber id="purchasedQuantity" />
                        </div>
                     </div>
                     <div
                        style={{
                           display: "flex",
                           flexDirection: "row",
                           justifyContent: "flex-end"
                        }}
                     >
                        <Button
                           label="Clear"
                           icon="pi pi-times"
                           className="p-button-text "
                           style={{
                              color: "black",
                              border: "1px solid black",
                              margin: "5px",
                              width: "fit-content"
                           }}
                        />
                        <Button
                           label="Filter"
                           icon="pi pi-check"
                           className="p-button-text"
                           style={{
                              color: "#FFEB3B",
                              border: "1px solid black",
                              backgroundColor: "black",
                              margin: "5px",
                              width: "fit-content"
                           }}
                        />
                     </div>
                  </AccordionTab>
               </Accordion>
            </div>
            <div className="card">
               <Toast ref={toast} />

               <DataTable
                  loading={loading}
                  ref={dt}
                  value={lookUpValues}
                  selection={selectedLookUpValues}
                  onSelectionChange={(e) => setSelectedLookUpValues(e.value)}
                  dataKey="id"
                  paginator
                  rows={10}
                  className="datatable-responsive"
                  paginatorTemplate={template2}
                  globalFilter={globalFilter}
                  emptyMessage="No lookUp value found."
                  header={header}
               >
                  <Column field="value" header="Value" body={valueBodyTemplate} headerStyle={{ width: "20%", minWidth: "10rem" }} />
                  <Column field="type" header="Type" body={lookupTypeBodyTemplate} headerStyle={{ width: "15%", minWidth: "10rem" }} />
                  <Column field="description" header="Description" body={descriptionBodyTemplate} headerStyle={{ width: "20%", minWidth: "10rem" }} />
                  <Column field="dateCreated" header="Created on" body={dateCreatedBodyTemplate} headerStyle={{ width: "20%", minWidth: "10rem" }} />
                  <Column
                     field="createdBy"
                     header="Created by"
                     body={createdByFullNameBodyTemplate}
                     headerStyle={{ width: "14%", minWidth: "8rem" }}
                  />
                  <Column body={actionBodyTemplate} header="Actions" />
               </DataTable>

               <Dialog
                  visible={lookUpValueDialog}
                  style={{ width: "700px" }}
                  header="LookUpValue"
                  modal
                  className="p-fluid"
                  footer={lookUpValueDialogFooter}
                  onHide={hideDialog}
               >
                  <Messages ref={dialogMessage} style={{ width: "100%" }} />
                  <div className="field" style={{ marginTop: "10px" }}>
                     <label htmlFor="lookUpType">Look up type</label>
                     <Dropdown
                        id="lookUpType"
                        value={selectedLookUpType}
                        options={lookUpTypes}
                        onChange={onLookUpTypeChange}
                        optionLabel={"name"}
                        filter
                        showClear
                        filterBy="name"
                        placeholder="Select Look up type"
                     />
                     {submitted && !selectedLookUpType && <small className="p-error">Look up type is required.</small>}
                  </div>
                  <div className="formgrid grid">
                     <div className="field col">
                        <label htmlFor="value">Value</label>
                        <InputText
                           id="value"
                           value={lookUpValue.value || ""}
                           onChange={(e) => onInputChange(e, "value")}
                           required
                           autoFocus
                           className={classNames({
                              "p-invalid": submitted && !lookUpValue.value
                           })}
                        />
                        {submitted && !lookUpValue.value && <small className="p-error">Value is required.</small>}
                     </div>
                  </div>

                  <div className="field">
                     <label htmlFor="description">Description</label>
                     <InputTextarea
                        style={{ height: 100 }}
                        id="description"
                        value={lookUpValue.description || ""}
                        onChange={(e) => onInputChange(e, "description")}
                        required
                        autoFocus
                        className={classNames({
                           "p-invalid": submitted && !lookUpValue.description
                        })}
                     />
                     {submitted && !lookUpValue.description && <small className="p-error">Description is required.</small>}
                  </div>
               </Dialog>

               <Dialog
                  visible={deleteLookUpValueDialog}
                  style={{ width: "450px" }}
                  header="Confirm"
                  modal
                  footer={deleteLookUpValueDialogFooter}
                  onHide={hideLookUpValueDialog}
               >
                  <div className="flex align-items-center justify-content-center">
                     <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                     {lookUpValue && (
                        <span>
                           Are you sure you want to delete <b>{lookUpValue.name}</b>?
                        </span>
                     )}
                  </div>
               </Dialog>

               <Dialog
                  visible={deleteLookUpValuesDialog}
                  style={{ width: "450px" }}
                  header="Confirm"
                  modal
                  footer={deleteLookUpValuesDialogFooter}
                  onHide={hideDeleteShopsDialog}
               >
                  <div className="flex align-items-center justify-content-center">
                     <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                     {lookUpValue && <span>Are you sure you want to delete the selected lookUpValues?</span>}
                  </div>
               </Dialog>
            </div>
         </div>
      </div>
   );
};
export default LookUpValue;
