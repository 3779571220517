import React, { useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { useLocation } from "react-router";
import { formatNumberWithCommas, toReadableDate } from "../utils/Utils";
import { TabView, TabPanel } from "primereact/tabview";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
// import Timelin from '../components/ProfileR/Timeline';

const Profile = () => {
   const { state } = useLocation();
   const { name, id, initialCapital, ownerName, physicalLocation, registrationNumber, locationLatitude, locationLongitude, dateCreated, entity } =
      state;
   const toast = useRef(null);

   const Co_Owner = (
      <TabPanel header="Co-owner information">
         <div className="field">
            <div className="flex mb-4 flex-wrapper">
               <div className="col-12 md:col-6 lg:col-2">
                  <label className="mt-3">
                     <span className="font-medium">First name:</span>
                     <br /> <b>{state?.firstName}</b>
                  </label>
               </div>

               <div className="col-12 md:col-6 lg:col-2">
                  <label className="mt-3">
                     <span className="font-medium">Last name:</span>
                     <br /> <b>{state?.lastName}</b>
                  </label>
               </div>

               <div className="col-12 md:col-6 lg:col-2">
                  <label className="mt-3">
                     <span className="font-medium">Other name:</span>
                     <br />
                     <b>{state?.otherName}</b>
                  </label>
               </div>

               <div className="col-12 md:col-6 lg:col-2">
                  <label className="mt-3">
                     <span className="font-medium">Date of birth:</span>
                     <br /> <b>{state?.dateOfBirth}</b>
                  </label>
               </div>
               <div className="col-12 md:col-6 lg:col-2 mr-3">
                  <label className="mt-3">
                     <span className="font-medium"> Sex:</span>
                     <br /> <b>{state?.gender}</b>
                  </label>
               </div>

               <div className="col-12 md:col-6 lg:col-2 mr-3">
                  <label className="mt-3">
                     <span className="font-medium">Nationality:</span>
                     <br /> <b>{state?.physicalAddress}</b>
                  </label>
               </div>
            </div>
            <div className="flex mb-4 flex-wrapper">
               <div className="col-12 md:col-6 lg:col-2">
                  <label className="mt-3">
                     <span className="font-medium">Primary phone number:</span>
                     <br /> <b>{state?.primaryPhoneNumber}</b>
                  </label>
               </div>

               <div className="col-12 md:col-6 lg:col-2">
                  <label className="mt-3">
                     <span className="font-medium">Alt phone number:</span>
                     <br /> <b>{state?.alternativePhoneNumber}</b>
                  </label>
               </div>

               <div className="col-12 md:col-6 lg:col-2">
                  <label className="mt-3">
                     <span className="font-medium">Email address:</span>
                     <br /> <b>{state?.emailAddress}</b>
                  </label>
               </div>

               <div className="col-12 md:col-6 lg:col-2">
                  <label className="mt-3">
                     <span className="font-medium">Postal Address:</span>
                     <br /> <b>{state?.postalAddress}</b>
                  </label>
               </div>
               <div className="col-12 md:col-6 lg:col-2 mr-3">
                  <label className="mt-3">
                     <span className="font-medium">Physical Address:</span>
                     <br /> <b>{state?.physicalAddress}</b>
                  </label>
               </div>

               <div className="col-12 md:col-6 lg:col-2 mr-3">
                  <label className="mt-3">
                     <span className="font-medium">National ID: </span>
                     <br /> <b> {state?.nationalID}</b>
                  </label>
               </div>
            </div>
            <div className="flex mb-4 flex-wrapper">
               <div className="col-12 md:col-6 lg:col-2">
                  <label className="mt-3">
                     <span className="font-medium">Tin Number:</span>
                     <br /> <b>{state?.tinNumber}</b>
                  </label>
               </div>

               <div className="col-12 md:col-6 lg:col-2">
                  <label className="mt-3">
                     <span className="font-medium">LC1 letter</span>
                     <br /> <b>{state?.tinNumber}</b>
                  </label>
               </div>

               <div className="col-12 md:col-6 lg:col-2">
                  <label className="mt-3">
                     <span className="font-medium">Shop:</span>
                     <br />{" "}
                     <b>
                        {state?.firstName} {state?.lastName}
                     </b>
                  </label>
               </div>

               <div className="col-12 md:col-6 lg:col-2">
                  <label className="mt-3">
                     <span className="font-medium">Serial number:</span>
                     <br />
                     <b>{state?.serialNumber}</b>
                  </label>
               </div>
               <div className="col-12 md:col-6 lg:col-2 mr-3">
                  <label className="mt-3">
                     <span className="font-medium">Date registered:</span>
                     <br /> <b>{toReadableDate(state?.dateCreated)}</b>
                  </label>
               </div>

               <div className="col-12 md:col-6 lg:col-2 mr-3">
                  <label className="mt-3">
                     <span className="font-medium">Created on: </span>
                     <br />{" "}
                     <b>
                        {state?.firstName} {state?.lastName}
                     </b>
                  </label>
               </div>
            </div>
         </div>
      </TabPanel>
   );

   const TabViw = (
      <TabView className="h-10rem">
         {/* <TabPanel header="Timeline">
                <Timelin />
            </TabPanel> */}
         <TabPanel header="Personal Profile">
            <div className="field">
               <div className="flex mb-4 flex-wrapper">
                  <div className="col-12 md:col-6 lg:col-2">
                     <label className="mt-3">
                        <span className="font-medium">First name:</span>
                        <br /> <b>{state?.firstName}</b>
                     </label>
                  </div>

                  <div className="col-12 md:col-6 lg:col-2">
                     <label className="mt-3">
                        <span className="font-medium">Last name:</span>
                        <br /> <b>{state?.lastName}</b>
                     </label>
                  </div>

                  <div className="col-12 md:col-6 lg:col-2">
                     <label className="mt-3">
                        <span className="font-medium">Other name:</span>
                        <br />
                        <b>{state?.otherName}</b>
                     </label>
                  </div>

                  <div className="col-12 md:col-6 lg:col-2">
                     <label className="mt-3">
                        <span className="font-medium">Date of birth:</span>
                        <br /> <b>{state?.dateOfBirth}</b>
                     </label>
                  </div>
                  <div className="col-12 md:col-6 lg:col-2 mr-3">
                     <label className="mt-3">
                        <span className="font-medium"> Sex:</span>
                        <br /> <b>{state?.gender}</b>
                     </label>
                  </div>

                  <div className="col-12 md:col-6 lg:col-2 mr-3">
                     <label className="mt-3">
                        <span className="font-medium">Nationality:</span>
                        <br /> <b>{state?.physicalAddress}</b>
                     </label>
                  </div>
               </div>
               <div className="flex mb-4 flex-wrapper">
                  <div className="col-12 md:col-6 lg:col-2">
                     <label className="mt-3">
                        <span className="font-medium">Primary phone number:</span>
                        <br /> <b>{state?.primaryPhoneNumber}</b>
                     </label>
                  </div>

                  <div className="col-12 md:col-6 lg:col-2">
                     <label className="mt-3">
                        <span className="font-medium">Alt phone number:</span>
                        <br /> <b>{state?.alternativePhoneNumber}</b>
                     </label>
                  </div>

                  <div className="col-12 md:col-6 lg:col-2">
                     <label className="mt-3">
                        <span className="font-medium">Email address:</span>
                        <br /> <b>{state?.emailAddress}</b>
                     </label>
                  </div>

                  <div className="col-12 md:col-6 lg:col-2">
                     <label className="mt-3">
                        <span className="font-medium">Postal Address:</span>
                        <br /> <b>{state?.postalAddress}</b>
                     </label>
                  </div>
                  <div className="col-12 md:col-6 lg:col-2 mr-3">
                     <label className="mt-3">
                        <span className="font-medium">Physical Address:</span>
                        <br /> <b>{state?.physicalAddress}</b>
                     </label>
                  </div>

                  <div className="col-12 md:col-6 lg:col-2 mr-3">
                     <label className="mt-3">
                        <span className="font-medium">National ID: </span>
                        <br /> <b> {state?.nationalID}</b>
                     </label>
                  </div>
               </div>
               <div className="flex mb-4 flex-wrapper">
                  <div className="col-12 md:col-6 lg:col-2">
                     <label className="mt-3">
                        <span className="font-medium">Tin Number:</span>
                        <br /> <b>{state?.tinNumber}</b>
                     </label>
                  </div>

                  <div className="col-12 md:col-6 lg:col-2">
                     <label className="mt-3">
                        <span className="font-medium">LC1 letter</span>
                        <br /> <b>{state?.tinNumber}</b>
                     </label>
                  </div>

                  <div className="col-12 md:col-6 lg:col-2">
                     <label className="mt-3">
                        <span className="font-medium">Shop:</span>
                        <br />{" "}
                        <b>
                           {state?.firstName} {state?.lastName}
                        </b>
                     </label>
                  </div>

                  <div className="col-12 md:col-6 lg:col-2">
                     <label className="mt-3">
                        <span className="font-medium">Serial number:</span>
                        <br />
                        <b>{state?.serialNumber}</b>
                     </label>
                  </div>
                  <div className="col-12 md:col-6 lg:col-2 mr-3">
                     <label className="mt-3">
                        <span className="font-medium">Date registered:</span>
                        <br /> <b>{toReadableDate(state?.dateCreated)}</b>
                     </label>
                  </div>

                  <div className="col-12 md:col-6 lg:col-2 mr-3">
                     <label className="mt-3">
                        <span className="font-medium">Created on: </span>
                        <br />{" "}
                        <b>
                           {state?.firstName} {state?.lastName}
                        </b>
                     </label>
                  </div>
               </div>
            </div>
         </TabPanel>
         {state?.ownerName && Co_Owner}
         <TabPanel header="Password Configuration">
            <div className="ml-8  mt-5">
               <div className="field flex justify-content-between w-6 align-items-center mb-5">
                  <label htmlFor="symbol">Old password</label>
                  <InputText id="symbol" className="w-8" />
               </div>
               <div className="field flex justify-content-between w-6 align-items-center mb-5">
                  <label htmlFor="symbol">New password</label>
                  <InputText id="symbol" className="w-8" />
               </div>
               <div className="field flex justify-content-between w-6 align-items-center mb-5">
                  <label htmlFor="symbol">Confirm password</label>
                  <InputText id="symbol" className="w-8" />
               </div>
            </div>
            <div className="flex justify-content-center">
               <Button label="Update" className="p-button primary-btn" />
            </div>
         </TabPanel>
      </TabView>
   );

   return (
      <div className="grid crud-demo h-full">
         <div className="col-12">
            <div className="card border-round-lg">
               <Toast ref={toast} />

               <div className="col-12">
                  <div className="grid">
                     <div className="col-12 md:col-12 lg:col-1">
                        <div className="flex align-items-center justify-content-center">
                           <i className="pi pi-user shop-image align-items-center justify-content-center flex"></i>
                        </div>
                     </div>

                     <div className="col-12 md:col-12 lg:col-10 flex align-items-center">
                        <div className="field w-full">
                           <div className="">
                              <div className="flex justify-content-between">
                                 <div className="col-12 md:col-6 lg:col-2">
                                    <label className="mt-3">
                                       Owner:
                                       <br />
                                       <span className="font-bold">
                                          {state?.firstName} {state?.lastName}
                                       </span>
                                    </label>
                                 </div>

                                 <div className="col-12 md:col-6 lg:col-2">
                                    <label className="mt-3">
                                       <>Sex:</> <br />
                                       <span className="font-bold"> {state?.gender} </span>
                                    </label>
                                 </div>

                                 <div className="col-12 md:col-6 lg:col-2">
                                    <label className="mt-3">
                                       <>Location:</> <br />
                                       <span className="font-bold"> {state?.physicalAddress} </span>
                                    </label>
                                 </div>

                                 <div className="col-12 md:col-6 lg:col-2">
                                    <label className="mt-3">
                                       <>Nationality:</> <br />
                                       <span className="font-bold"> {state?.nationality} </span>
                                    </label>
                                 </div>
                                 <div className="col-12 md:col-6 lg:col-2">
                                    <label className="mt-3">
                                       <>Date Of Birth: </> <br />
                                       <span className="font-bold">{toReadableDate(state?.dateOfBirth)} </span>
                                    </label>
                                 </div>
                              </div>
                              <div className="flex justify-content-between">
                                 <div className="col-12 md:col-6 lg:col-2">
                                    <label className="mt-3">
                                       <>Primary Phone number:</> <br />
                                       <span className="font-bold"> {state?.primaryPhoneNumber} </span>
                                    </label>
                                 </div>
                                 <div className="col-12 md:col-6 lg:col-2">
                                    <label className="mt-3">
                                       <>Alt phone number:</> <br />
                                       <span className="font-bold"> {state?.alternativePhoneNumber} </span>
                                    </label>
                                 </div>
                                 <div className="col-12 md:col-6 lg:col-2">
                                    <label className="mt-3">
                                       <>Email:</> <br />
                                       <span className="font-bold"> {state?.emailAddress} </span>
                                    </label>
                                 </div>
                                 <div className="col-12 md:col-6 lg:col-2">
                                    <label className="mt-3">
                                       <>National ID:</> <br />
                                       <span className="font-bold"> {state?.entity} </span>
                                    </label>
                                 </div>

                                 <div className="col-12 md:col-6 lg:col-2">
                                    <label className="mt-3">
                                       <>Registration no:</> <br />
                                       <span className="font-bold"> {toReadableDate(dateCreated)}</span>
                                    </label>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div className="card border-round-lg heighty">{TabViw}</div>
         </div>
      </div>
   );
};

export default Profile;
