import React, { useState, useEffect, useRef } from "react";
import { classNames } from "primereact/utils";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { BaseApiService } from "../../utils/BaseApiService";
import { showErrorMessage } from "../../constants/ErrorMessages";
import { Dropdown } from "primereact/dropdown";
import { Messages } from "primereact/messages";
import { InputTextarea } from "primereact/inputtextarea";
import { productionStatuses } from "../../constants/Constants";
import { hasNull } from "../../utils/Utils";

const ProductFormDialog = ({ selectedRecord, onCompleteFn, isProductDialogVisible = false, setIsProductDialogVisible }) => {
   const [isLoading, setIsLoading] = useState(false);
   const [submitted, setSubmitted] = useState(false);

   //Drop down variables
   const [manufacturers, setManufacturers] = useState(null);
   const [packageUnits, setPackageUnits] = useState(null);
   const [containerUnits, setContainerUnits] = useState(null);
   const [categories, setCategories] = useState([]);

   //Form fields
   const [id, setId] = useState(0);
   const [name, setName] = useState(null);
   const [manufacturerId, setManufacturerId] = useState(0);
   const [packageUnitId, setPackageUnitId] = useState(0);
   const [packageQuantity, setPackageQuantity] = useState(0);
   const [productionStatusId, setProductionStatusId] = useState(0);
   const [containerQuantity, setContainerQuantity] = useState(0);
   const [categoryId, setCategoryId] = useState(0);
   const [containerUnitId, setContainerUnitId] = useState(0);
   const [barcode, setBarcode] = useState(null);
   const [remarks, setRemarks] = useState(null);
   const [saleUnits, setSaleUnits] = useState([]);
   const [selectedSaleUnits, setSelecetedSaleUnits] = useState([]);

   const dialogMessage = useRef();
   const toast = useRef(null);

   const handleUnitBarCode = (index, value) => {
      const updatedLineItems = [...selectedSaleUnits];
      updatedLineItems[index].barcode = value;
      setSelecetedSaleUnits([...updatedLineItems]);
   };

   const handleUnitQtyCode = (index, value) => {
      const updatedLineItems = [...selectedSaleUnits];
      updatedLineItems[index].quantity = value;
      setSelecetedSaleUnits([...updatedLineItems]);
   };

   const pupulateForm = () => {
      clearForm();
      if (selectedRecord !== null && selectedRecord !== undefined) {
         setId(selectedRecord.id);
         setName(selectedRecord.name);
         setBarcode(selectedRecord.barcode);
         setRemarks(selectedRecord.remarks);
         setCategoryId(selectedRecord?.categoryId);
         setManufacturerId(selectedRecord?.manufacturerId);
         setContainerUnitId(selectedRecord?.containerUnitId);
         setPackageUnitId(selectedRecord?.packageUnitId);
         setPackageQuantity(selectedRecord.packageQuantity);
         setContainerQuantity(selectedRecord.containerQuantity);
         setProductionStatusId(selectedRecord?.productionStatusId);

         if (selectedRecord.multipleSaleUnits) {
            setSelecetedSaleUnits(selectedRecord?.multipleSaleUnits);
         }
      }
   };
   const clearForm = () => {
      setId(0);
      setName(null);
      setBarcode(null);
      setRemarks(null);
      setCategoryId(null);
      setManufacturerId(null);
      setContainerUnitId(null);
      setPackageUnitId(null);
      setPackageQuantity(null);
      setContainerQuantity(null);
      setProductionStatusId(null);
      setSelecetedSaleUnits([]);
   };

   const onSaleUnitSelect = (unit) => {
      const { id, weight, value, typeId } = unit;

      let itemUnit = {
         id,
         quantity: weight,
         value,
         saleUnitId: id,
         barcode: "",
         typeId
      };

      const isSelected = selectedSaleUnits.find((item) => item.saleUnitId === id);

      if (isSelected) {
         const newList = selectedSaleUnits.filter((item) => item.saleUnitId !== id);

         setSelecetedSaleUnits([...newList]);
      } else {
         setSelecetedSaleUnits((prevSaleUnits) => [...prevSaleUnits, itemUnit]);
      }
   };

   /**
    * Called whenever selected record is updated
    */
   useEffect(() => {
      pupulateForm();
   }, [selectedRecord]);

   /**
    * Called once on initial form init
    */
   useEffect(() => {
      fetchManufacturers();
      fetchPackageUnits();
      fetchContainerUnits();
      fetchCategories();
      fetchSaleUnits();
   }, []);

   const fetchManufacturers = async () => {
      let searchParameters = { searchTerm: "", offset: 0, limit: 0 };
      new BaseApiService("/manufacturers")
         .getRequestWithJsonResponse(searchParameters)
         .then(async (response) => {
            setManufacturers(response.records);
         })
         .catch((error) => {});
   };

   const fetchContainerUnits = async () => {
      let searchParameters = {
         searchTerm: "",
         offset: 0,
         limit: 0,
         commaSeparatedTypeIds: [2]
      };
      new BaseApiService("/lookups/lookup-values")
         .getRequestWithJsonResponse(searchParameters)
         .then(async (response) => {
            setContainerUnits(response.records);
         })
         .catch((error) => {});
   };
   const fetchCategories = async () => {
      let searchParameters = {
         searchTerm: "",
         offset: 0,
         limit: 0,
         commaSeparatedTypeIds: [3]
      };
      new BaseApiService("/lookups/lookup-values")
         .getRequestWithJsonResponse(searchParameters)
         .then(async (response) => {
            setCategories(response.records);
         })
         .catch((error) => {});
   };

   const fetchPackageUnits = async () => {
      let searchParameters = {
         searchTerm: "",
         offset: 0,
         limit: 0,
         commaSeparatedTypeIds: [1]
      };
      new BaseApiService("/lookups/lookup-values")
         .getRequestWithJsonResponse(searchParameters)
         .then(async (response) => {
            setPackageUnits(response.records);
         })
         .catch((error) => {});
   };

   const saveProduct = () => {
      setSubmitted(true);
      setIsLoading(true);
      const apiUrl = id > 0 ? "/products/" + selectedRecord?.id : "/products";
      const payload = {
         id: id,
         name: name,
         manufacturerId: manufacturerId,
         packageUnitId: packageUnitId,
         packageQuantity: packageQuantity,
         productionStatusId: productionStatusId,
         containerQuantity: containerQuantity,
         containerUnitId: containerUnitId,
         barcode: barcode,
         categoryId: categoryId,
         remarks: remarks || "",
         multipleSaleUnits: selectedSaleUnits
      };

      if (!hasNull(payload)) {
         new BaseApiService(apiUrl)
            .saveRequestWithJsonResponse(payload, id > 0)
            .then((response) => {
               clearForm();
               onCompleteFn();
               setIsLoading(false);
               setIsProductDialogVisible(false);
               toast.current.show({
                  severity: "success",
                  summary: "Successful",
                  detail: "Product Details saved",
                  life: 3000
               });
               setSubmitted(false);
            })
            .catch((error) => {
               showErrorMessage(dialogMessage, error.message);
               setSubmitted(false);
               setIsLoading(false);
            });
      } else {
         setIsLoading(false);
      }
   };

   const fetchSaleUnits = async () => {
      let searchParameters = {
         searchTerm: "",
         offset: 0,
         limit: 0,
         commaSeparatedTypeIds: [4]
      };
      new BaseApiService("/lookups/lookup-values")
         .getRequestWithJsonResponse(searchParameters)
         .then(async (response) => {
            setSaleUnits(response.records);
         })
         .catch((error) => {});
   };

   const hide = () => {
      setIsProductDialogVisible(false);
      setSelecetedSaleUnits([]);
      setSubmitted(false);
   };

   const productDialogFooter = (
      <>
         <Button label="Cancel" icon="pi pi-times" className="outline-btn" onClick={hide} />
         <Button label="Save" icon="pi pi-check" className="primary-btn" onClick={saveProduct} loading={isLoading} />
      </>
   );

   return (
      <Dialog
         visible={isProductDialogVisible}
         style={{ width: "800px" }}
         header="Product"
         modal
         className="p-fluid"
         footer={productDialogFooter}
         onHide={hide}
      >
         <Messages ref={dialogMessage} style={{ width: "100%" }} />
         <div className="field" style={{ marginTop: "10px" }}>
            <label htmlFor="manufacturer">Manufacturer</label>
            <Dropdown
               id="manufacturer"
               className={classNames({ "p-invalid": submitted && !manufacturerId })}
               value={manufacturerId}
               optionValue="id"
               options={manufacturers}
               onChange={(e) => setManufacturerId(e.target.value)}
               optionLabel={"name"}
               filter
               filterBy="name"
               placeholder="Select Manufacturer"
            />
            {submitted && !manufacturerId && <small className="p-error">Manufacturer is required.</small>}
         </div>
         <div className="formgrid grid">
            <div className="field col-12 md:col-6">
               <label htmlFor="name">Name</label>
               <InputText
                  id="name"
                  value={name || ""}
                  onChange={(e) => setName(e.target.value)}
                  required
                  className={classNames({ "p-invalid": submitted && !name })}
               />
               {submitted && !name && <small className="p-error">Product name is required.</small>}
            </div>

            <div className="field col-12 md:col-6">
               <label htmlFor="name">Category</label>
               <Dropdown
                  className={classNames({ "p-invalid": submitted && !categoryId })}
                  value={categoryId}
                  options={categories}
                  onChange={(e) => setCategoryId(e.target.value)}
                  optionLabel={"value"}
                  optionValue={"id"}
                  filter
                  filterBy="value"
                  placeholder="Select Category"
               />
               {submitted && !categoryId && <small className="p-error">Category name is required.</small>}
            </div>
         </div>

         <div className="formgrid grid">
            <div className="field col-6 md:col-6">
               <label htmlFor="packageUnit">Package Unit</label>
               <Dropdown
                  className={classNames({ "p-invalid": submitted && !packageUnitId })}
                  id="packageUnit"
                  value={packageUnitId}
                  options={packageUnits}
                  onChange={(e) => setPackageUnitId(e.target.value)}
                  optionLabel={"value"}
                  optionValue={"id"}
                  filter
                  filterBy="value"
                  placeholder="Package Unit"
               />
               {submitted && !packageUnitId && <small className="p-error">Package unit is required.</small>}
            </div>
            <div className="field col-6 md:col-6">
               <label htmlFor="packageQuantity">Package quantity</label>
               <InputNumber
                  id="packageQuantity"
                  value={packageQuantity}
                  onValueChange={(e) => setPackageQuantity(e.target.value)}
                  required
                  className={classNames({
                     "p-invalid": submitted && !packageQuantity
                  })}
               />
               {submitted && !packageQuantity && <small className="p-error">Package quantity is required.</small>}
            </div>
         </div>

         <div className="formgrid grid">
            <div className="field col-6 md:col-6">
               <label htmlFor="containerQuantity">Container quantity</label>
               <InputNumber
                  minFractionDigits={1}
                  id="containerQuantity"
                  value={containerQuantity}
                  onValueChange={(e) => setContainerQuantity(e.target.value)}
                  required
                  className={classNames({
                     "p-invalid": submitted && !containerQuantity
                  })}
               />
               {submitted && !containerQuantity && <small className="p-error">Container quantity is required.</small>}
            </div>
            <div className="field col-6 md:col-6">
               <label htmlFor="containerUnit">Container Unit</label>
               <Dropdown
                  className={classNames({
                     "p-invalid": submitted && !containerUnitId
                  })}
                  id="containerUnit"
                  value={containerUnitId}
                  options={containerUnits}
                  onChange={(e) => setContainerUnitId(e.target.value)}
                  optionLabel={"value"}
                  optionValue={"id"}
                  filter
                  filterBy="value"
                  placeholder="Container Unit"
               />
               {submitted && !containerUnitId && <small className="p-error">Container unit is required.</small>}
            </div>
         </div>

         <div className="formgrid grid">
            <div className="field col-6 md:col-6">
               <label htmlFor="productionStatus">Production status</label>
               <Dropdown
                  className={classNames({
                     "p-invalid": submitted && !productionStatusId
                  })}
                  id="productionStatus"
                  value={productionStatusId}
                  options={productionStatuses}
                  onChange={(e) => setProductionStatusId(e.target.value)}
                  optionLabel={"name"}
                  filter
                  filterBy="name"
                  placeholder="Production status"
               />
               {submitted && !productionStatusId && <small className="p-error">Production status is required.</small>}
            </div>
            <div className="field col-6 md:col-6">
               <label htmlFor="barcode">Barcode</label>
               <InputText id="barcode" value={barcode} onChange={(e) => setBarcode(e.target.value)} />
            </div>
         </div>

         <div>
            <label>Sales units</label>
            <div className="flex flex-wrap gap-3 mt-3 mb-2">
               {saleUnits.map((item) => {
                  const isSelected = selectedSaleUnits?.find((unit) => item?.id === unit?.saleUnitId);

                  return (
                     <Button
                        rounded
                        className={classNames({
                           "outline-btn p-button-rounded": !isSelected,
                           "p-button-rounded": isSelected
                        })}
                        key={item.id}
                        onClick={() => onSaleUnitSelect(item)}
                        label={item.value}
                        style={{ width: "fit-content" }}
                     />
                  );
               })}
            </div>
         </div>

         {selectedSaleUnits.length > 0 && (
            <div className="formgrid grid mt-4 -mb-3">
               <div className="field col">
                  <label htmlFor="UnitPrice">Unit</label>
               </div>

               <div className="field col">
                  <label htmlFor="expiryDate">Quantity</label>
               </div>

               <div className="field col">
                  <label htmlFor="expiryDate">Barcode</label>
               </div>
            </div>
         )}

         {selectedSaleUnits?.map((item, index) => {
            return (
               <div className="formgrid grid" key={item.id}>
                  <div className="field col">
                     <InputText readOnly className="prevent-events " value={item?.value || item?.saleUnitName} />
                  </div>

                  <div className="field col">
                     <InputNumber minFractionDigits={1} value={item?.quantity} onValueChange={(e) => handleUnitQtyCode(index, e.value)} />
                  </div>

                  <div className="field col">
                     <InputText value={item?.barcode} onChange={(e) => handleUnitBarCode(index, e.target.value)} />
                  </div>
               </div>
            );
         })}

         <div className="field">
            <label htmlFor="remarks">Remarks</label>
            <InputTextarea style={{ height: 100 }} id="remarks" value={remarks} onChange={(e) => setRemarks(e.target.value)} />
         </div>
      </Dialog>
   );
};
export default ProductFormDialog;
