import React, { useRef, useState, useEffect } from "react";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { classNames } from "primereact/utils";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { BaseApiService } from "../../utils/BaseApiService";
import { UserSessionUtils } from "../../utils/UserSessionUtils";
import { productionStatuses } from "../../constants/Constants";
import { convertToServerDate, hasNull } from "../../utils/Utils";

import { Dialog } from "primereact/dialog";
import { Messages } from "primereact/messages";
import ChipButton from "../../components/ChipButton";
import { showErrorMessage } from "../../constants/ErrorMessages";
import { TabView, TabPanel } from "primereact/tabview";

export default function NewProduct({ openBulldozer, setOpenBullDozer, toast, onComplete }) {
   const stepperRef = useRef(null);
   let packageOptions = [
      { value: "Packed", type: true },
      { value: "Unpacked", type: false }
   ];

   const [activeIndex, setActiveIndex] = useState(0);
   const [isLoading, setIsLoading] = useState(false);
   const [submitted, setSubmitted] = useState(false);

   //Drop down variables
   const [manufacturers, setManufacturers] = useState(null);
   const [packageUnits, setPackageUnits] = useState(null);
   const [containerUnits, setContainerUnits] = useState(null);
   const [categories, setCategories] = useState([]);

   //Form fields
   const [id, setId] = useState(0);
   const [name, setName] = useState(null);
   const [manufacturerId, setManufacturerId] = useState(0);
   const [packageUnitId, setPackageUnitId] = useState(0);
   const [packageQuantity, setPackageQuantity] = useState(0);
   const [productionStatusId, setProductionStatusId] = useState(0);
   const [containerQuantity, setContainerQuantity] = useState(0);
   const [categoryId, setCategoryId] = useState(0);
   const [containerUnitId, setContainerUnitId] = useState(0);
   const [barcode, setBarcode] = useState(null);
   const [remarks, setRemarks] = useState(null);
   const [saleUnits, setSaleUnits] = useState([]);
   const [selectedSaleUnits, setSelectedSaleUnits] = useState([]); //pannel 1

   const [listingUnits, setListingUnits] = useState([]);
   const [listingUnitSelectections, setListingUnitSelections] = useState([]);

   const [checked, setChecked] = useState(false);
   const [salesPrice, setSalesPrice] = useState(null);
   const [selectedShopId, setSelectedShopId] = useState(null);
   const [shops, setShops] = useState([]);

   const [selectedSaleUnit, setSelectedSaleUnit] = useState(null);

   const [products, setProducts] = useState([]);
   const [selectedProduct, setSelectedProduct] = useState(null);
   const [searchTerm, setSearchTerm] = useState("");

   const [suppliers, setSuppliers] = useState([]);
   const [selectedSupplier, setSelectedSupplier] = useState(null);
   const [expiryDate, setExpiryDate] = useState(null);
   const [purchaseDate, setPurchaseDate] = useState(new Date());

   const [isPackedProduct, setIsPackedProduct] = useState(null);
   const [batchNumber, setBatchNumber] = useState(null);

   const [unpackedPurchasedQuantity, setUnpackedPurchasedQuantity] = useState(null);
   const [packedPurchasedQuantity, setPackedPurchasedQuantity] = useState(null);
   const [purchasePrice, setPurchasePrice] = useState(null);

   const dialogMessage = useRef();

   const handleUnitBarCode = (index, value) => {
      const updatedLineItems = [...selectedSaleUnits];
      updatedLineItems[index].barcode = value;
      setSelectedSaleUnits([...updatedLineItems]);
   };

   const handleUnitQtyCode = (index, value) => {
      const updatedLineItems = [...selectedSaleUnits];
      updatedLineItems[index].quantity = value;
      setSelectedSaleUnits([...updatedLineItems]);
   };

   const onSaleUnitSelect = (unit) => {
      const { id, weight, value, typeId } = unit;

      let itemUnit = {
         id,
         quantity: weight,
         value,
         saleUnitId: id,
         barcode: "",
         typeId
      };

      const isSelected = selectedSaleUnits.find((item) => item.saleUnitId === id);

      if (isSelected) {
         const newList = selectedSaleUnits.filter((item) => item.saleUnitId !== id);

         setSelectedSaleUnits([...newList]);
      } else {
         setSelectedSaleUnits((prevSaleUnits) => [...prevSaleUnits, itemUnit]);
      }
   };

   const pupulateForm = (selectedRecord) => {
      if (selectedRecord !== null && selectedRecord !== undefined) {
         setId(selectedRecord.id);
         setName(selectedRecord.name);
         setBarcode(selectedRecord.barcode);
         setCategoryId(selectedRecord?.categoryId);
         setManufacturerId(selectedRecord?.manufacturerId);
         setContainerUnitId(selectedRecord?.containerUnitId);
         setPackageUnitId(selectedRecord?.packageUnitId);
         setPackageQuantity(selectedRecord.packageQuantity);
         setContainerQuantity(selectedRecord.containerQuantity);
         setProductionStatusId(selectedRecord?.productionStatusId);

         if (selectedRecord.multipleSaleUnits) {
            setSelectedSaleUnits(selectedRecord?.multipleSaleUnits);
         }
      }
   };

   const clearForm = () => {
      setSubmitted(false);
      setId(0);
      setName(null);
      setBarcode("");
      setRemarks(null);
      setCategoryId(null);
      setManufacturerId(null);
      setContainerUnitId(null);
      setPackageUnitId(null);
      setPackageQuantity(null);
      setContainerQuantity(null);
      setProductionStatusId(null);
      setSelectedSaleUnits([]);
      setListingUnitSelections([]);
      setListingUnits([]);
      setIsPackedProduct(null);
      setExpiryDate(null);
      setSelectedSupplier(null);
      setPurchaseDate(new Date());
      setPurchasePrice(null);
      setUnpackedPurchasedQuantity(null);
      setPackedPurchasedQuantity(null);
      setRemarks(null);
      setBatchNumber("");
      setSalesPrice(null);
      setSelectedProduct(null);
   };

   const onProductChange = (e) => {
      setSelectedProduct(e.target.value);
      pupulateForm(e.target.value);
   };

   const fetchSaleUnits = async () => {
      let searchParameters = {
         searchTerm: "",
         offset: 0,
         limit: 0,
         commaSeparatedTypeIds: [4]
      };
      new BaseApiService("/lookups/lookup-values")
         .getRequestWithJsonResponse(searchParameters)
         .then(async (response) => {
            setSaleUnits(response.records);
         })
         .catch((error) => {});
   };
   const fetchManufacturers = async () => {
      let searchParameters = { searchTerm: "", offset: 0, limit: 0 };
      new BaseApiService("/manufacturers")
         .getRequestWithJsonResponse(searchParameters)
         .then(async (response) => {
            setManufacturers(response.records);
         })
         .catch((error) => {});
   };

   const fetchContainerUnits = async () => {
      let searchParameters = {
         searchTerm: "",
         offset: 0,
         limit: 0,
         commaSeparatedTypeIds: [2]
      };
      new BaseApiService("/lookups/lookup-values")
         .getRequestWithJsonResponse(searchParameters)
         .then(async (response) => {
            setContainerUnits(response.records);
         })
         .catch((error) => {});
   };
   const fetchCategories = async () => {
      let searchParameters = {
         searchTerm: "",
         offset: 0,
         limit: 0,
         commaSeparatedTypeIds: [3]
      };
      new BaseApiService("/lookups/lookup-values")
         .getRequestWithJsonResponse(searchParameters)
         .then(async (response) => {
            setCategories(response.records);
         })
         .catch((error) => {});
   };

   const fetchPackageUnits = async () => {
      let searchParameters = {
         searchTerm: "",
         offset: 0,
         limit: 0,
         commaSeparatedTypeIds: [1]
      };
      new BaseApiService("/lookups/lookup-values")
         .getRequestWithJsonResponse(searchParameters)
         .then(async (response) => {
            setPackageUnits(response.records);
         })
         .catch((error) => {});
   };

   const fetchProducts = async (manufacturerId) => {
      let searchParameters = {
         offset: 0,
         limit: 20,
         searchTerm: searchTerm
      };

      if (manufacturerId) {
         searchParameters.manufacturerId = manufacturerId;
      }
      new BaseApiService("/products")
         .getRequestWithJsonResponse(searchParameters)
         .then(async (response) => {
            setProducts(response.records);
         })
         .catch((error) => {});
   };

   const fetchShops = async () => {
      let searchParameters = {
         offset: 0,
         limit: 0
      };

      if (UserSessionUtils.getShopOwner()) {
         searchParameters.shopOwnerId = UserSessionUtils.getShopOwnerId();
      }
      new BaseApiService("/shops")
         .getRequestWithJsonResponse(searchParameters)
         .then(async (response) => {
            setShops(response.records);
            if (response.records.length === 1) {
               setSelectedShopId(response.records[0]);
            }
         })
         .catch((error) => {});
   };

   const fetchSuppliers = async () => {
      let searchParameters = { searchTerm: "", offset: 0, limit: 0 };
      new BaseApiService("/suppliers")
         .getRequestWithJsonResponse(searchParameters)
         .then(async (response) => {
            setSuppliers(response.records);
         })
         .catch((error) => {});
   };

   const handlePanel1 = () => {
      setSubmitted(true);
      const payload1 = {
         id: id,
         name: name,
         manufacturerId: manufacturerId,
         packageUnitId: packageUnitId,
         packageQuantity: packageQuantity,
         productionStatusId: productionStatusId,
         containerQuantity: containerQuantity,
         containerUnitId: containerUnitId,
         barcode: barcode || "",
         categoryId: categoryId,
         multipleSaleUnits: selectedSaleUnits,
         hasMultipleSaleUnits: selectedSaleUnits.length > 0
      };

      const payload2 = {
         id: 0,
         saleUnitId: selectedSaleUnit?.saleUnitId,
         salesPrice: salesPrice,
         shopId: selectedShopId?.id,
         manufacturerId: manufacturerId,
         hasMultipleSaleUnits: listingUnits.length > 0,
         multipleSaleUnits: listingUnits
      };

      const isPacked = isPackedProduct && isPackedProduct === "Packed";

      const payload3 = {
         id: 0,
         // productId: 0,
         purchasePrice: purchasePrice,
         batchNumber: batchNumber,
         expiryDate: convertToServerDate(expiryDate),
         supplierId: selectedSupplier?.id,
         manufacturerId: manufacturerId,
         remarks: remarks || "",
         stockedOnDate: convertToServerDate(purchaseDate),
         ...(isPacked && {
            packedPurchasedQuantity: packedPurchasedQuantity,
            unpackedPurchase: false
         }),
         ...(!isPacked && {
            unpackedPurchase: true,
            unpackedPurchasedQuantity: unpackedPurchasedQuantity
         })
      };

      const requestPayload = {
         productRequestDTO: payload1,
         shopProductRequestDTO: payload2,
         stockEntryRequestDTO: payload3
      };

      if (!isLastpanel) {
         if (!hasNull(payload1) && selectedShopId) {
            setListingUnitSelections(
               selectedSaleUnits.map((item) => {
                  const { saleUnitId, value, barcode, saleUnitName, id } = item;
                  return {
                     saleUnitId,
                     saleUnitName: value || saleUnitName,
                     barcode,
                     id
                  };
               })
            );
            setActiveIndex(activeIndex + 1);
            setSubmitted(false);
         }
      } else {
         if (!hasNull({ ...payload2, ...payload3 })) {
            setIsLoading(true);
            new BaseApiService("/stock-entries/new-product")
               .postRequestWithJsonResponse(requestPayload)
               .then((response) => {
                  setIsLoading(false);
                  handleBackCick();
                  hide();
                  onComplete();
                  toast.current.show({
                     severity: "success",
                     summary: "Successful",
                     detail: "Product Details saved",
                     life: 3000
                  });
               })
               .catch((error) => {
                  setIsLoading(false);
                  showErrorMessage(dialogMessage, error?.message);
               });
         }
      }
   };

   useEffect(() => {
      fetchManufacturers();
      fetchPackageUnits();
      fetchContainerUnits();
      fetchCategories();
      fetchSaleUnits();
      fetchShops();
      fetchSuppliers();
   }, []);

   useEffect(() => {
      fetchProducts();
   }, [searchTerm]);

   useEffect(() => {
      clearForm();
   }, [checked]);

   const hide = () => {
      setOpenBullDozer(false);
      setChecked(false);
      clearForm();
   };
   const handleNextClick = () => {
      handlePanel1();
   };

   const handleBackCick = () => {
      setActiveIndex(activeIndex - 1);
   };
   const isLastpanel = activeIndex === 1;

   const footer = (
      <div
         className={classNames({
            "flex pt-4": true,
            "justify-content-between": activeIndex !== 0,
            "justify-content-end": activeIndex == 0
         })}
      >
         {activeIndex !== 0 && <Button label="Back" icon="pi pi-arrow-left" className="outline-btn w-min" onClick={handleBackCick} />}
         <Button
            loading={isLoading}
            label={isLastpanel ? "Save" : "Next"}
            icon={`pi pi-${isLastpanel ? "save" : "arrow-right"}`}
            iconPos={isLastpanel ? "left" : "right"}
            className="w-min primary-btn"
            onClick={handleNextClick}
         />
      </div>
   );

   const tab1HeaderTemplate = (options) => {
      return (
         <div className="flex align-items-center gap-2 p-3" style={{ cursor: "pointer" }} onClick={options.onClick}>
            <span className="font-bold white-space-nowrap">Product details</span>
         </div>
      );
   };

   const tab2HeaderTemplate = (options) => {
      return (
         <div className="flex align-items-center gap-2 p-3" style={{ cursor: "pointer" }} onClick={options.onClick}>
            <span className="font-bold white-space-nowrap">Stock Information</span>
         </div>
      );
   };

   return (
      <Dialog visible={openBulldozer} style={{ width: "800px" }} header="New Product" modal className="p-fluid" onHide={hide} footer={footer}>
         <TabView ref={stepperRef} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
            {
               //product details 1
            }

            <TabPanel header="Product details" headerTemplate={tab1HeaderTemplate}>
               <div className="flex flex-column">
                  <div className="mt-5">
                     <ChipButton isSelected={checked} label="Existing product" onClick={() => setChecked(!checked)} />
                  </div>
                  <div className="formgrid grid" style={{ marginTop: "10px" }}>
                     {checked ? (
                        <div className="field col-12 md:col-6">
                           <label htmlFor="name">Product</label>
                           <Dropdown
                              id="product"
                              value={selectedProduct}
                              options={products}
                              optionLabel="displayName"
                              onChange={onProductChange}
                              required
                              filter
                              filterBy="name"
                              onFilter={(e) => setSearchTerm(e.filter)}
                              placeholder="Select a product"
                              className={classNames({
                                 "p-invalid": submitted === true && !name
                              })}
                           />
                           {submitted && !name && <small className="p-error">Product is required.</small>}
                        </div>
                     ) : (
                        <div className="field col-12 md:col-6">
                           <label htmlFor="name">Product Name</label>
                           <InputText
                              id="name"
                              value={name || ""}
                              onChange={(e) => setName(e.target.value)}
                              required
                              className={classNames({ "p-invalid": submitted && !name })}
                           />
                           {submitted && !name && <small className="p-error">Product name is required.</small>}
                        </div>
                     )}
                     <div className="field col-12 md:col-6">
                        <label htmlFor="manufacturer">Manufacturer</label>
                        <Dropdown
                           id="manufacturer"
                           className={classNames({
                              "p-invalid": submitted && !manufacturerId
                           })}
                           value={manufacturerId}
                           optionValue="id"
                           options={manufacturers}
                           onChange={(e) => setManufacturerId(e.target.value)}
                           optionLabel={"name"}
                           filter
                           filterBy="name"
                           placeholder="Select Manufacturer"
                        />
                        {submitted && !manufacturerId && <small className="p-error">Manufacturer is required.</small>}
                     </div>
                  </div>

                  <div className="formgrid grid">
                     <div className="field col-12 md:col-6">
                        <label htmlFor="shop">Shop</label>
                        <Dropdown
                           id="shop"
                           value={selectedShopId}
                           options={shops}
                           onChange={(e) => setSelectedShopId(e.target.value)}
                           optionLabel={"name"}
                           filter
                           filterBy="name"
                           placeholder="Select Shop"
                           className={classNames({
                              "p-invalid": submitted === true && !selectedShopId
                           })}
                        />
                        {submitted && !selectedShopId && <small className="p-error">Shop selection is required.</small>}
                     </div>

                     <div className="field col-12 md:col-6">
                        <label htmlFor="name">Category</label>
                        <Dropdown
                           className={classNames({
                              "p-invalid": submitted && !categoryId
                           })}
                           value={categoryId}
                           options={categories}
                           onChange={(e) => setCategoryId(e.target.value)}
                           optionLabel={"value"}
                           optionValue={"id"}
                           filter
                           filterBy="value"
                           placeholder="Select Category"
                        />
                        {submitted && !categoryId && <small className="p-error">Category name is required.</small>}
                     </div>
                  </div>

                  <div className="formgrid grid">
                     <div className="field col-6 md:col-6">
                        <label htmlFor="packageUnit">Package Unit</label>
                        <Dropdown
                           className={classNames({
                              "p-invalid": submitted && !packageUnitId
                           })}
                           id="packageUnit"
                           value={packageUnitId}
                           options={packageUnits}
                           onChange={(e) => setPackageUnitId(e.target.value)}
                           optionLabel={"value"}
                           optionValue={"id"}
                           filter
                           filterBy="value"
                           placeholder="Package Unit"
                        />
                        {submitted && !packageUnitId && <small className="p-error">Package unit is required.</small>}
                     </div>
                     <div className="field col-6 md:col-6">
                        <label htmlFor="packageQuantity">Package quantity</label>
                        <InputNumber
                           id="packageQuantity"
                           value={packageQuantity}
                           onValueChange={(e) => setPackageQuantity(e.target.value)}
                           required
                           className={classNames({
                              "p-invalid": submitted && !packageQuantity
                           })}
                        />
                        {submitted && !packageQuantity && <small className="p-error">Package quantity is required.</small>}
                     </div>
                  </div>

                  <div className="formgrid grid">
                     <div className="field col-6 md:col-6">
                        <label htmlFor="containerQuantity">Container quantity</label>
                        <InputNumber
                           minFractionDigits={1}
                           id="containerQuantity"
                           value={containerQuantity}
                           onValueChange={(e) => setContainerQuantity(e.target.value)}
                           required
                           className={classNames({
                              "p-invalid": submitted && !containerQuantity
                           })}
                        />
                        {submitted && !containerQuantity && <small className="p-error">Container quantity is required.</small>}
                     </div>
                     <div className="field col-6 md:col-6">
                        <label htmlFor="containerUnit">Container Unit</label>
                        <Dropdown
                           className={classNames({
                              "p-invalid": submitted && !containerUnitId
                           })}
                           id="containerUnit"
                           value={containerUnitId}
                           options={containerUnits}
                           onChange={(e) => setContainerUnitId(e.target.value)}
                           optionLabel={"value"}
                           optionValue={"id"}
                           filter
                           filterBy="value"
                           placeholder="Container Unit"
                        />
                        {submitted && !containerUnitId && <small className="p-error">Container unit is required.</small>}
                     </div>
                  </div>

                  <div className="formgrid grid">
                     <div className="field col-6 md:col-6">
                        <label htmlFor="productionStatus">Production status</label>
                        <Dropdown
                           className={classNames({
                              "p-invalid": submitted && !productionStatusId
                           })}
                           id="productionStatus"
                           value={productionStatusId}
                           options={productionStatuses}
                           onChange={(e) => setProductionStatusId(e.target.value)}
                           optionLabel={"name"}
                           filter
                           filterBy="name"
                           placeholder="Production status"
                        />
                        {submitted && !productionStatusId && <small className="p-error">Production status is required.</small>}
                     </div>
                     <div className="field col-6 md:col-6">
                        <label htmlFor="barcode">Barcode</label>
                        <InputText id="barcode" value={barcode} onChange={(e) => setBarcode(e.target.value)} />
                     </div>
                  </div>

                  <div>
                     <label>Sales units</label>
                     <br />
                     {submitted && selectedSaleUnits.length === 0 && <small className="p-error">Please select a sale unit</small>}
                     <div className="flex flex-wrap gap-3 mt-3 mb-2">
                        {saleUnits.map((item) => {
                           const isSelected = selectedSaleUnits?.find((unit) => item?.id === unit?.saleUnitId);

                           return (
                              <Button
                                 rounded
                                 className={classNames({
                                    "outline-btn p-button-rounded": !isSelected,
                                    "p-button-rounded": isSelected
                                 })}
                                 key={item.id}
                                 onClick={() => onSaleUnitSelect(item)}
                                 label={item.value}
                                 style={{ width: "fit-content" }}
                              />
                           );
                        })}
                     </div>
                  </div>

                  {selectedSaleUnits.length > 0 && (
                     <div className="formgrid grid mt-4 -mb-3">
                        <div className="field col">
                           <label htmlFor="UnitPrice">Unit</label>
                        </div>

                        <div className="field col">
                           <label htmlFor="expiryDate">Quantity</label>
                        </div>

                        <div className="field col">
                           <label htmlFor="expiryDate">Barcode</label>
                        </div>
                     </div>
                  )}

                  {selectedSaleUnits?.map((item, index) => {
                     return (
                        <div className="formgrid grid" key={item.id}>
                           <div className="field col">
                              <InputText readOnly className="prevent-events " value={item?.value || item?.saleUnitName} />
                           </div>

                           <div className="field col">
                              <InputNumber minFractionDigits={1} value={item?.quantity} onValueChange={(e) => handleUnitQtyCode(index, e.value)} />
                           </div>

                           <div className="field col">
                              <InputText value={item?.barcode} onChange={(e) => handleUnitBarCode(index, e.target.value)} />
                           </div>
                        </div>
                     );
                  })}
               </div>
            </TabPanel>

            <TabPanel header="Stock Information" headerTemplate={tab2HeaderTemplate} disabled={activeIndex !== 1}>
               <div className="flex flex-column">
                  <div className="formgrid grid mt-5">
                     <Messages ref={dialogMessage} style={{ width: "100%" }} />

                     <div className="field col-6 md:col-6">
                        <label htmlFor="saleUnit">Sale Unit</label>

                        <Dropdown
                           value={selectedSaleUnit}
                           options={listingUnitSelectections}
                           placeholder="Select a sale unit"
                           onChange={(e) => {
                              setSelectedSaleUnit(e.target.value);
                              setListingUnits([]);
                           }}
                           optionLabel="saleUnitName"
                           className={classNames({
                              "p-invalid": submitted === true && !selectedSaleUnit
                           })}
                        />
                        {submitted === true && !selectedSaleUnit && <small className="p-error">Sale unit is required.</small>}
                     </div>

                     <div className="field col-6 md:col-6">
                        <label htmlFor="salesPrice">Sales Price</label>
                        <InputNumber
                           id="salesPrice"
                           value={salesPrice}
                           onValueChange={(e) => setSalesPrice(e.target.value)}
                           required
                           className={classNames({
                              "p-invalid": submitted === true && !salesPrice
                           })}
                        />
                        {(submitted === true) === true && !salesPrice && <small className="p-error">Sales price is required.</small>}
                     </div>
                  </div>

                  <div className="formgrid grid">
                     <div className="field col-12 md:col-6">
                        <label htmlFor="supplier">Supplier</label>
                        <Dropdown
                           id="supplier"
                           value={selectedSupplier}
                           options={suppliers}
                           onChange={(e) => setSelectedSupplier(e.target.value)}
                           filter
                           filterBy="companyOrBusinessName"
                           optionLabel="companyOrBusinessName"
                           placeholder="Select a supplier"
                           className={classNames({
                              "p-invalid": submitted && !selectedSupplier
                           })}
                        />
                        {submitted && !selectedSupplier && <small className="p-error">Supplier is required.</small>}
                     </div>
                     <div className="field col-12 md:col-6">
                        <label htmlFor="product">Package Type</label>
                        <Dropdown
                           id="productType"
                           value={isPackedProduct}
                           options={packageOptions}
                           onChange={(e) => {
                              setIsPackedProduct(e.target.value);
                           }}
                           optionLabel={"value"}
                           placeholder="Select package type"
                           className={classNames({
                              "p-invalid": submitted && !isPackedProduct
                           })}
                        />
                        {submitted && !isPackedProduct && <small className="p-error">Package type is required .</small>}
                     </div>
                  </div>
                  <div
                     className="formgrid grid mt-2"
                     style={{
                        display: isPackedProduct === "Packed" ? "flex" : "none"
                     }}
                  >
                     <div className="field col-6 md:col-6">
                        <label htmlFor="purchasedQuantity">{`Packed Qty (${
                           packageUnits?.find((item) => item?.id === packageUnitId)?.value || ""
                        })`}</label>
                        <InputNumber
                           minFractionDigits={1}
                           id="purchasedQuantity"
                           value={packedPurchasedQuantity}
                           onValueChange={(e) => setPackedPurchasedQuantity(e.value)}
                           className={classNames({
                              "p-invalid": submitted && !packedPurchasedQuantity
                           })}
                        />
                        {submitted && !packedPurchasedQuantity && <small className="p-error">Purchased quantity is required .</small>}
                     </div>

                     <div className="field col-6 md:col-6">
                        <label htmlFor="purchasePrice">Purchase price</label>
                        <InputNumber
                           id="purchasePrice"
                           value={purchasePrice}
                           onValueChange={(e) => setPurchasePrice(e.value)}
                           className={classNames({
                              "p-invalid": submitted && !purchasePrice
                           })}
                        />
                        {submitted && !purchasePrice && <small className="p-error">Purchase price is required .</small>}
                     </div>
                  </div>

                  <div
                     className="formgrid grid mt-2"
                     style={{
                        display: isPackedProduct === "Unpacked" ? "flex" : "none"
                     }}
                  >
                     <div className="field col-6 md:col-6">
                        <label htmlFor="unpackedQuantity">Unpacked quantity</label>
                        <InputNumber
                           minFractionDigits={1}
                           id="unpackedQuantity"
                           value={unpackedPurchasedQuantity}
                           onValueChange={(e) => setUnpackedPurchasedQuantity(e.value)}
                           className={classNames({
                              "p-invalid": submitted && !unpackedPurchasedQuantity
                           })}
                        />
                        {submitted && !unpackedPurchasedQuantity && <small className="p-error">Unpacked quantity is required .</small>}
                     </div>

                     <div className="field col">
                        <label htmlFor="unpackedUnitPrice">Purchase amount</label>
                        <InputNumber
                           id="unpackedUnitPrice"
                           value={purchasePrice}
                           onValueChange={(e) => setPurchasePrice(e.value)}
                           className={classNames({
                              "p-invalid": submitted && !purchasePrice
                           })}
                        />
                        {submitted && !purchasePrice && <small className="p-error">Unpacked unit price is required .</small>}
                     </div>
                  </div>

                  <div className="formgrid grid">
                     <div className="field col-6 md:col-6">
                        <label htmlFor="batchNumber">Batch no</label>
                        <InputText
                           id="batchNumber"
                           value={batchNumber}
                           onChange={(e) => setBatchNumber(e.target.value)}
                           className={classNames({
                              "p-invalid": submitted && !batchNumber
                           })}
                        />
                        {submitted && !batchNumber && <small className="p-error">Batch number is required .</small>}
                     </div>

                     <div className="field col-6 md:col-6">
                        <label htmlFor="expiryDate">Expiry date</label>
                        <Calendar
                           id="expiryDate"
                           value={expiryDate}
                           onChange={(e) => setExpiryDate(e.value)}
                           className={classNames({
                              "p-invalid": submitted && !expiryDate
                           })}
                           dateFormat="dd-M-yy"
                        />
                        {submitted && !expiryDate && <small className="p-error">Expiry date is required .</small>}
                     </div>
                  </div>

                  <div className="formgrid grid">
                     <div className="field col-6 md:col-6">
                        <label htmlFor="UnitPrice">Unit price</label>
                        <InputNumber readOnly value={0 || 0} />
                     </div>

                     <div className="field col-6 md:col-6">
                        <label htmlFor="expiryDate">Purchase date</label>
                        <Calendar
                           id="expiryDate"
                           value={purchaseDate}
                           onChange={(e) => setPurchaseDate(e.value)}
                           className={classNames({
                              "p-invalid": submitted && !purchaseDate
                           })}
                           dateFormat="dd-M-yy"
                        />
                        {submitted && !purchaseDate && <small className="p-error">Purchase date is required .</small>}
                     </div>
                  </div>
                  <div className="field mt-4">
                     <label htmlFor="remarks">Remarks</label>
                     <InputTextarea style={{ height: 100 }} id="remarks" value={remarks} onChange={(e) => setRemarks(e.target.value)} />
                  </div>
               </div>
            </TabPanel>
         </TabView>
      </Dialog>
   );
}
