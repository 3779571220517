import { showErrorMessage, showSuccessMessage } from "constants/ErrorMessages";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import { BaseApiService } from "utils/BaseApiService";
import { SETTINGS_ENDPOINT } from "utils/EndpointsUtils";

export default function Settings() {
   const [settings, setSettings] = useState(null);
   const [appName, setAppName] = useState("");
   const [appLogoUrl, setAppLogoUrl] = useState("");
   const [smsGatewayUsername, setSmsGatewayUsername] = useState("");
   const [smsGatewayPassword, setSmsGatewayPassword] = useState("");
   const [paymentGatewayUsername, setPaymentGatewayUsername] = useState("");
   const [paymentGatewayPassword, setPaymentGatewayPassword] = useState("");
   const [smtpAddress, setSmtpAddress] = useState("");
   const [smtpPassword, setSmtpPassword] = useState("");
   const [smtpPort, setSmtpPort] = useState("");
   const [smtpUsername, setSmtpUsername] = useState("");
   const [smtpHost, setSmtpHost] = useState("");
   const [loading, setLoading] = useState(false);

   const toast = useRef(null);

   const getSettings = async () => {
      await new BaseApiService(SETTINGS_ENDPOINT)
         .getRequestWithJsonResponse()
         .then((response) => {
            console.log(response?.data);
            setSettings(response?.data);
         })
         .catch((error) => {
            console.log(error);
         });
   };

   const fillSettings = () => {
      setAppLogoUrl(settings?.appLogoUrl);
      setAppName(settings?.appName);
      setSmsGatewayUsername(settings?.smsGatewayUsername);
      setSmsGatewayPassword(settings?.smsGatewayPassword);
      setPaymentGatewayUsername(settings?.paymentGatewayUsername);
      setPaymentGatewayPassword(settings?.paymentGatewayPassword);
      setSmtpAddress(settings?.smtpAddress);
      setSmtpPassword(settings?.smtpPassword);
      setSmtpPort(settings?.smtpPort);
      setSmtpUsername(settings?.smtpUsername);
      setSmtpHost(settings?.smtpHost);
   };

   const saveSettings = async () => {
      const payload = {
         appName: appName,
         appLogoUrl: appLogoUrl,
         smsGatewayUsername: smsGatewayUsername,
         smsGatewayPassword: smsGatewayPassword,
         paymentGatewayUsername: paymentGatewayUsername,
         paymentGatewayPassword: paymentGatewayPassword,
         smtpAddress: smtpAddress,
         smtpPassword: smtpPassword,
         smtpHost: smtpHost,
         smtpPort: smtpPort,
         smtpUsername: smtpUsername
      };
      if (!loading) {
         setLoading(true);
         await new BaseApiService(SETTINGS_ENDPOINT)
            .postRequestWithJsonResponse(payload)
            .then((response) => {
               setSettings(response);
               showSuccessMessage(toast, "Settings saved successfully");
               setLoading(false);
            })
            .catch((e) => {
               showErrorMessage(toast, e?.message);
               setLoading(false);
            });
      }
   };

   useEffect(() => {
      getSettings();
   }, []);

   useEffect(() => {
      if (settings) {
         fillSettings();
      }
   }, [settings]);
   return (
      <div className="card p-fluid min-h-full">
         <Toast ref={toast} />
         <h4 className="mb-5">Application settings</h4>

         <div className="formgrid grid ">
            <div className="field col-12 md:col-6 lg:col-3">
               <label>App name</label>

               <InputText value={appName} onChange={(e) => setAppName(e.target.value)} />
            </div>
            <div className="field col-12 md:col-6 lg:col-3">
               <label>Logo Url</label>

               <InputText value={appLogoUrl} onChange={(e) => setAppLogoUrl(e.target.value)} />
            </div>
            <div className="field col-12 md:col-6 lg:col-3">
               <label>Sms Gateway Username</label>

               <InputText value={smsGatewayUsername} onChange={(e) => setSmsGatewayUsername(e.target.value)} name="smsm" />
            </div>
            <div className="field col-12 md:col-6 lg:col-3">
               <label>Sms Gateway Password</label>

               <Password feedback={false} toggleMask value={smsGatewayPassword} onChange={(e) => setSmsGatewayPassword(e.target.value)} name="smsm" />
            </div>
         </div>

         <div className="formgrid grid ">
            <div className="field col-12 md:col-6 lg:col-3">
               <label>Payment Gateway Username</label>

               <InputText value={paymentGatewayUsername} onChange={(e) => setPaymentGatewayUsername(e.target.value)} />
            </div>
            <div className="field col-12 md:col-6 lg:col-3">
               <label>Payment Gateway Password</label>

               <Password feedback={false} toggleMask value={paymentGatewayPassword} onChange={(e) => setPaymentGatewayPassword(e.target.value)} />
            </div>
            <div className="field col-12 md:col-6 lg:col-3">
               <label>Smtp Address</label>

               <InputText value={smtpAddress} onChange={(e) => setSmtpAddress(e.target.value)} />
            </div>
            <div className="field col-12 md:col-6 lg:col-3">
               <label>Smtp Password</label>

               <Password feedback={false} toggleMask value={smtpPassword} onChange={(e) => setSmtpPassword(e.target.value)} />
            </div>
         </div>

         <div className="formgrid grid ">
            <div className="field col-12 md:col-6 lg:col-3">
               <label>Smtp Host</label>

               <InputText value={smtpHost} onChange={(e) => setSmtpHost(e.target.value)} />
            </div>
            <div className="field col-12 md:col-6 lg:col-3">
               <label>Smtp Port</label>

               <InputText value={smtpPort} onChange={(e) => setSmtpPort(e.target.value)} />
            </div>
            <div className="field col-12 md:col-6 lg:col-3">
               <label>Smtp Username</label>

               <InputText value={smtpUsername} onChange={(e) => setSmtpUsername(e.target.value)} />
            </div>
         </div>

         <div className="formgrid grid flex flex-row-reverse flex-wrap mt-3">
            <div className="field col-12 md:col-6 lg:col-2 ">
               <Button label={"Save"} icon="pi pi-save" className="primary-btn" onClick={saveSettings} loading={loading} />
            </div>
         </div>
      </div>
   );
}
