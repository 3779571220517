import { DateTime } from "luxon";
import { UserSessionUtils } from "./UserSessionUtils";

/**
 * This converts a string of a date time instance to a date time string using the
 * luxon DateTime library
 *
 * @param {string} date_value
 * @returns {string}
 */
export function toReadableDateTime(date_value, date_only = false) {
   if (date_value == null) {
      return "-";
   } else if (date_value && date_only) {
      return DateTime.fromFormat(date_value, "yyyy-MM-dd hh:mm:ss").toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY);
   } else if (date_value && !date_only) {
      return DateTime.fromISO(date_value).toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY);
   }
}

export function formatDate2(inputDate, removeTime = false) {
   let options;
   if (removeTime === true) {
      options = { weekday: "short", year: "numeric", month: "short", day: "numeric" };
   } else {
      options = { weekday: "short", year: "numeric", month: "short", day: "numeric", hour: "2-digit", minute: "2-digit" };
   }
   const date = new Date(inputDate);

   // Format the date
   const formattedDate = date.toLocaleDateString("en-US", options);

   return formattedDate;
}

export function toReadableDate(date_value, date_only = false) {
   if (date_value == null) {
      return "-";
   } else if (date_value && date_only) {
      return DateTime.fromFormat(date_value, "yyyy-MM-dd hh:mm:ss").toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY);
   } else if (date_value && !date_only) {
      return DateTime.fromISO(date_value).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY);
   }
}

export function toReadableTime(date_value, date_only = false) {
   if (date_value == null) {
      return "-";
   } else if (date_value && date_only) {
      return DateTime.fromFormat(date_value, "yyyy-MM-dd hh:mm:ss").toLocaleString(DateTime.TIME_WITH_SECONDS);
   } else if (date_value && !date_only) {
      return DateTime.fromISO(date_value).toLocaleString(DateTime.TIME_WITH_SECONDS);
   }
}

export function formatNumberWithCommas(number, symbol = "") {
   if (number === null || number === undefined || isNaN(number)) {
      return `${symbol}0`;
   }

   const formattedNumber = Number(number).toLocaleString(undefined, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 3
   });

   return `${symbol}${formattedNumber}`;
}

export function formatDateToDDMMYY(dateString) {
   const date = new Date(dateString);

   if (isNaN(date)) {
      return "Invalid Date";
   }

   const day = date.getDate();
   const month = date.getMonth() + 1; // Months are zero-indexed, so we add 1
   const year = date.getFullYear() % 100; // Get last two digits of the year

   // Pad day and month with leading zeros if necessary
   const formattedDay = day < 10 ? "0" + day : day;
   const formattedMonth = month < 10 ? "0" + month : month;

   return `${formattedDay}-${formattedMonth}-${year}`;
}

export function hasNull(obj) {
   for (let key in obj) {
      if (obj[key] === null || obj[key] === undefined) {
         return true;
      }
   }
   return false;
}

export const convertToServerDate = (dateValue) => {
   const utcDate = new Date(dateValue);
   const year = utcDate.getFullYear();
   const month = String(utcDate.getMonth() + 1).padStart(2, "0");
   const day = String(utcDate.getDate()).padStart(2, "0");
   return `${year}-${month}-${day}`;
};

export function convertDateFormat(dateString, getTomorrowDate = false) {
   const date = new Date(dateString); // Create a Date object from the input string

   if (getTomorrowDate === true) {
      date.setDate(date.getDate() + 1); // Increment the date by 1 to get tomorrow's date
   }

   const isoDateString = date.toISOString(); // Convert Date object to ISO string
   return isoDateString;
}

export function convertTo24HourFormat(timeString) {
   // Split the time string into hours, minutes, and optionally seconds
   const timeArray = timeString.split(" ");
   const time = timeArray[0];
   const period = timeArray[1];

   const [hourString, minuteString] = time.split(":");

   let hour = parseInt(hourString);
   const minute = parseInt(minuteString);

   // If it's PM and not already 12, add 12 to the hour
   if (period.toLowerCase() === "pm" && hour !== 12) {
      hour += 12;
   }

   // If it's AM and it's 12, set hour to 0
   if (period.toLowerCase() === "am" && hour === 12) {
      hour = 0;
   }

   // Format the hour and minute to ensure leading zeros
   const formattedHour = String(hour).padStart(2, "0");
   const formattedMinute = String(minute).padStart(2, "0");

   // Return the time in 24-hour format
   return `${formattedHour}:${formattedMinute}`;
}

export function datesAreEqual(d1, d2) {
   let date1 = new Date(d1);

   let date2 = new Date(d2);
   const year1 = date1.getFullYear();
   const month1 = date1.getMonth();
   const day1 = date1.getDate();

   const year2 = date2.getFullYear();
   const month2 = date2.getMonth();
   const day2 = date2.getDate();

   // Compare the year, month, and day components
   return year1 === year2 && month1 === month2 && day1 === day2;
}

export function isValidPhoneNumber(phoneNumber) {
   const pattern = /^(256|0)7[0-9]{8}$/;
   return pattern.test(phoneNumber);
}

export const isValidEmail = (email) => {
   const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/u;
   return regex.test(email);
};

export const isValidPassword = (password) => {
   const reg = "^(?=.*[A-Z])(?=.*[a-z])(?=.*d)(?=.*[@$!%*?&])[A-Za-zd@$!%*?&]{8,}$";

   return reg.test(password);
};

export function validatePassword(password) {
   const messages = [];

   // Check length
   if (password.length < 8) {
      messages.push("Password must be at least 8 characters long.");
   }

   // Check for uppercase letter
   if (!/[A-Z]/.test(password)) {
      messages.push("Password must contain at least one uppercase letter.");
   }

   // Check for lowercase letter
   if (!/[a-z]/.test(password)) {
      messages.push("Password must contain at least one lowercase letter.");
   }

   // Check for digit
   if (!/\d/.test(password)) {
      messages.push("Password must contain at least one digit.");
   }

   // Check for special character
   if (!/[!@#$%^&*_]/.test(password)) {
      messages.push("Password must contain at least one special character");
   }

   return messages;
}

export function getTimeDifference(date1, date2) {
   let parsedDate1 = new Date(date1).getTime();
   let parsedDate2 = new Date(date2).getTime();

   let timeDifference = Math.abs(parsedDate1 - parsedDate2);

   const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
   timeDifference -= days * (1000 * 60 * 60 * 24);

   const hours = Math.floor(timeDifference / (1000 * 60 * 60));
   timeDifference -= hours * (1000 * 60 * 60);

   const minutes = Math.floor(timeDifference / (1000 * 60));
   timeDifference -= minutes * (1000 * 60);

   const seconds = Math.floor(timeDifference / 1000);

   return {
      days: days,
      hours: hours,
      minutes: minutes,
      seconds: seconds
   };
}

function getSuffix(day) {
   if (day >= 11 && day <= 13) {
      return "th";
   }
   switch (day % 10) {
      case 1:
         return "st";
      case 2:
         return "nd";
      case 3:
         return "rd";
      default:
         return "th";
   }
}

export function formatDate(date, showSuffix = true) {
   const options = { day: "numeric", month: "short", weekday: "short" };
   const formattedDate = new Intl.DateTimeFormat("en-US", options).format(date);
   const [day, month, weekday] = formattedDate.split(" ");
   const suffix = showSuffix ? getSuffix(parseInt(weekday)) : "";
   return `${weekday}${suffix}, ${month} ${day.replace(",", "")}`;
}

/**
 *
 *@param anyValue Returns true if a given object is null or undefined
 *@returns
 */
export function isEmpty(anyValue) {
   if (anyValue === undefined || anyValue === null) {
      return true;
   } else {
      return false;
   }
}

export function isNotEmpty(anyValue) {
   return !isEmpty(anyValue);
}
