import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import thunk from "redux-thunk";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import storage from "redux-persist/lib/storage";
import rootReducer from "reducers";

const persistConfig = { key: "duqact", storage, timeout: 5000, stateReconciler: autoMergeLevel2 };

const persistedReducer = persistReducer(persistConfig, rootReducer);

const duqactStore = configureStore({
   reducer: persistedReducer,
   middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false, thunk }),
   devTools: process.env.NODE_ENV !== "production"
});
const persistor = persistStore(duqactStore);

export { duqactStore, persistor };
