import React, { useEffect, useRef, useState } from "react";
import { BaseApiService } from "../../utils/BaseApiService";
import { Toast } from "primereact/toast";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";

function Permissions() {
   const [roles, setRoles] = useState([]);
   const [permissions, setPermisions] = useState([]);
   const [loading, setIsLoading] = useState(false);

   const getPermissions = () => {
      new BaseApiService("/users/permissions").getRequestWithJsonResponse({ offset: 0, limit: 0 }).then((response) => {
         let permissionList = [];
         response.forEach((item) => {
            if (item.permissions) {
               permissionList.push(...item.permissions);
            }
         });
         setPermisions(permissionList);
      });
   };

   const getRoles = () => {
      new BaseApiService("/users/roles").getRequestWithJsonResponse({ offset: 0, limit: 0 }).then((response) => {
         setRoles(response.records);
      });
   };

   const onSave = async () => {
      setIsLoading(true);
      for (let role of roles) {
         let payload = {
            name: role.name,
            description: role.description,
            permissionIds: role.permissionIds || [0],
            id: role?.id
         };

         await new BaseApiService(`/users/roles`)
            .saveRequestWithJsonResponse(payload, false)
            .then((response) => {})
            .catch((error) => {
               // setIsLoading(false);
            });
      }

      toast.current.show({
         severity: "success",
         summary: "Successful",
         detail: "Permission details saved",
         life: 3000
      });
      getRoles();
      setIsLoading(false);
   };

   const handleCheck = (roleIndex, permission) => {
      const isChecked = roles[roleIndex].permissions.some((item) => item.id === permission.id);

      if (isChecked) {
         const updatedList = roles[roleIndex].permissions.filter((item) => item.id !== permission.id);

         roles[roleIndex].permissions = updatedList;
         roles[roleIndex].permissionIds = roles[roleIndex]?.permissions?.map((i) => i.id);

         let newRoles = [...roles];
         setRoles(newRoles);
      } else {
         roles[roleIndex]?.permissions?.push(permission);
         roles[roleIndex].permissionIds = roles[roleIndex]?.permissions?.map((i) => i.id);

         let newRoles = [...roles];
         setRoles(newRoles);
      }
   };

   useEffect(() => {
      getRoles();
      getPermissions();
   }, []);
   const toast = useRef(null);

   return (
      <div className="grid crud-demo">
         <div className="col-12">
            <div className="py-2 -mt-3">
               <div className="grid">
                  <div className="col-12 md:col-12 lg:col-12">
                     <div className="col card p-4 p-fluid">
                        <Toast ref={toast} />
                        <h5>User Permissions</h5>

                        <div className="field grid col-12 ">
                           <div className="col-12 md:col-6 lg:col-2">
                              <div>
                                 <label className="font-medium">Permissions</label>

                                 <div className="">
                                    {permissions.map((perm, i) => (
                                       <div key={perm.id} className="field mt-4 h-3rem flex align-items-center">
                                          <label className="font-medium"> {perm.name}</label>
                                       </div>
                                    ))}
                                 </div>
                              </div>
                           </div>

                           <div className="col-12 md:col-6 lg:col-10 flex justify-content-between ">
                              {roles.map((role, roleIndex) => {
                                 roles[roleIndex].permissionIds = roles[roleIndex]?.permissions?.map((i) => i.id);
                                 let rolePermissions = role?.permissions ? role?.permissions : (role.permissions = []);
                                 return (
                                    <div className="" key={role.name}>
                                       <label className="font-medium">{role.name}</label>
                                       <div className="text-center ">
                                          {permissions.map((permission, i) => {
                                             const isChecked = rolePermissions?.some((perm) => perm?.id === permission.id);
                                             return (
                                                <>
                                                   <div
                                                      key={permission.name}
                                                      className="field mt-4 h-3rem flex justify-content-center align-items-center"
                                                   >
                                                      <label>
                                                         <Checkbox
                                                            onChange={() => handleCheck(roleIndex, permission)}
                                                            checked={isChecked ? isChecked : false}
                                                         ></Checkbox>
                                                      </label>
                                                   </div>
                                                </>
                                             );
                                          })}
                                       </div>
                                    </div>
                                 );
                              })}
                           </div>
                        </div>

                        <div
                           style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "flex-end"
                           }}
                        >
                           {/* <Button label="Clear" icon="pi pi-times" className="p-button-text " style={{ color: 'black', border: '1px solid black', margin: '5px', width: 'fit-content' }} /> */}
                           <Button
                              label="Save"
                              loading={loading}
                              onClick={onSave}
                              icon="pi pi-check"
                              className="p-button-text"
                              style={{
                                 color: "#FFEB3B",
                                 border: "1px solid black",
                                 backgroundColor: "black",
                                 margin: "5px",
                                 width: "fit-content"
                              }}
                           />
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
}

export default Permissions;
