import React from "react";

function StatusSummary({
   title = "",
   value = "",
   iconName = "pi-shopping-cart",
   iconBg = "bg-blue-100",
   iconColor = "text-black-500",
   showStats = false
}) {
   return (
      <div className="col-6 md:col-6 lg:col-3">
         <div className="surface-card shadow-2 p-3 border-round">
            <div className="flex justify-content-between mb-3">
               <div>
                  <span className="block text-1000 font-medium text-xl mb-3">{title}</span>
                  <div className="text-900 font-medium text-xl">{value}</div>
               </div>
               <div
                  className={`flex align-items-center justify-content-center  border-round`}
                  style={{
                     width: "2.5rem",
                     height: "2.5rem",
                     backgroundColor: "yellow"
                  }}
               >
                  <i className={`pi ${iconName} ${iconColor} text-xl`}></i>
               </div>
            </div>
            {showStats && (
               <>
                  <span className="text-green-500 font-medium">24 new </span>
                  <span className="text-500">since last visit</span>
               </>
            )}
         </div>
      </div>
   );
}

export default StatusSummary;
