import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { BaseApiService } from "../../utils/BaseApiService";
import { showErrorMessage } from "../../constants/ErrorMessages";
import { Messages } from "primereact/messages";
import { convertToServerDate, formatNumberWithCommas, toReadableDate, toReadableDateTime } from "../../utils/Utils";
import { UserSessionUtils } from "../../utils/UserSessionUtils";
import DeleteRecordDialog from "../../components/dialogs/DeleteRecordDialog";
import { Calendar } from "primereact/calendar";
import { TabView, TabPanel } from "primereact/tabview";
import StatusSummary from "../../components/StatusSummary";
import RenderCurrency from "../../components/RenderCurrency";

const Expenses = () => {
   const [expenses, setExpenses] = useState([]);

   const [categories, setCategories] = useState([]);
   const [shops, setShops] = useState([]);
   const [selectedShop, setSelectedShop] = useState(null);
   const [showForm, setShowForm] = useState(false);
   const [selectedExpense, setSelectedExpense] = useState(null);
   const [showDelete, setShowDelete] = useState(false);

   const [submitted, setSubmitted] = useState(false);
   const [selectedCategory, setSelectedCategory] = useState(null);
   const [amount, setAmount] = useState(null);
   const [description, setDescription] = useState("");
   const [loading, setLoading] = useState(true);
   const [expenseDate, setExpenseDate] = useState(new Date());
   const [activeIndex, setActiveIndex] = useState(0);
   const [expandedRows, setExpandedRows] = useState(null);

   const dialogMessage = useRef();
   const toast = useRef(null);
   const dt = useRef(null);

   const fetchExpenses = async () => {
      setLoading(true);
      let searchParameters = {
         offset: 0,
         limit: 0,
         ...(activeIndex > 0 && { shopId: shops[activeIndex]?.id })
      };
      if (UserSessionUtils.getShopAttendant()) {
         searchParameters.shopId = UserSessionUtils.getShopId();
      }
      if (UserSessionUtils.getShopOwner()) {
         searchParameters.shopOwnerId = UserSessionUtils.getShopOwnerId();
      }

      new BaseApiService("/shop/expenses")
         .getRequestWithJsonResponse(searchParameters)
         .then(async (response) => {
            setExpenses(response.records);
            setLoading(false);
         })
         .catch((error) => {
            setLoading(false);
         });
   };
   const fetchShops = async () => {
      let searchParameters = { searchTerm: "", offset: 0, limit: 0 };
      if (UserSessionUtils.getShopOwner()) {
         searchParameters.shopOwnerId = UserSessionUtils.getShopOwnerId();
      }
      new BaseApiService("/shops")
         .getRequestWithJsonResponse(searchParameters)
         .then(async (response) => {
            if (response?.records?.length === 1) {
               setSelectedShop(response?.records[0]);
            }

            if (response.records.length > 1) {
               setShops([{ name: "All shops", id: UserSessionUtils.getShopOwnerId() }, ...response?.records.reverse()]);
               return;
            }
            setShops(response.records);
         })
         .catch((error) => {});
   };

   const fetchExpenseCategories = async () => {
      let searchParameters = { offset: 0, limit: 0, commaSeparatedTypeIds: [5] };
      new BaseApiService("/lookups/lookup-values")
         .getRequestWithJsonResponse(searchParameters)
         .then(async (response) => {
            setCategories(response.records);
         })
         .catch((error) => {});
   };

   useEffect(() => {
      fetchExpenseCategories();
      fetchShops();
   }, []);

   useEffect(() => {
      fetchExpenses();
   }, [activeIndex]);

   const hideDialog = () => {
      setSubmitted(false);
      setShowForm(false);
      setSelectedExpense(null);
   };

   const amountBodyTemplate = (rowData) => {
      return <RenderCurrency value={rowData.amount} currencySymbol={rowData?.currency} />;
   };

   const createdOnBodyTemplate = (rowData) => {
      return <>{toReadableDateTime(rowData.dateCreated)}</>;
   };

   const expenseDateBodyTemplate = (rowData) => {
      return <>{toReadableDate(rowData.expenseDate)}</>;
   };

   const clearForm = () => {
      setAmount(null);
      setDescription("");
      setSelectedCategory(null);
   };

   const populateForm = (data) => {
      setSelectedExpense(data);
      setAmount(data?.amount);
      setDescription(data?.description);
      setSelectedCategory(data?.categoryId);
      setSelectedShop(shops?.find((shop) => shop?.id === data?.shopId));
   };

   const saveExpense = () => {
      let payload = {
         id: selectedExpense?.id || 0,
         description: description,
         amount: amount,
         categoryId: selectedCategory,
         shopId: selectedShop?.id,
         expenseDate: convertToServerDate(expenseDate)
      };
      setLoading(true);
      setSubmitted(true);
      new BaseApiService("/shop/expenses")
         .saveRequestWithJsonResponse(payload, false)
         .then((response) => {
            clearForm();
            setLoading(false);
            hideDialog();
            fetchExpenses();
            setSubmitted(false);
            toast.current.show({
               severity: "success",
               summary: "Successful",
               detail: "Shop expense added",
               life: 3000
            });
         })
         .catch((e) => {
            setLoading(false);
            showErrorMessage(dialogMessage, e?.message);
         });
   };

   const actionBodyTemplate = (rowData) => {
      return (
         <div className="actions flex md:flex-row md:align-items-center">
            <Button
               icon="pi pi-pencil"
               className="p-button-rounded p-button-text p-button-success"
               onClick={() => {
                  populateForm(rowData);
                  setShowForm(true);
               }}
            />
            <Button
               icon="pi pi-trash"
               className="p-button-rounded p-button-text p-button-danger"
               onClick={() => {
                  setSelectedExpense(rowData);
                  setShowDelete(true);
               }}
            />
         </div>
      );
   };

   const openNew = () => {
      setSelectedExpense(null);
      setSubmitted(false);
      setShowForm(true);
   };

   const header = (
      <div className="flex flex-column md:flex-row md:align-items-center">
         <h5 className="m-0 line relative">Shop expenses</h5>
         <span className="block mt-2 md:mt-0 p-input-icon-left ml-5">
            <div className="p-inputgroup flex-1">
               <InputText placeholder="Search" />
               <Button icon="pi pi-search" className="primary-btn" />
            </div>
         </span>
         <div className="my-2 ml-auto">
            <Button label="Add" icon="pi pi-plus" className="primary-btn mr-2" onClick={openNew} />
         </div>
      </div>
   );

   const shopAttendantDialogFooter = (
      <>
         <Button label="Cancel" icon="pi pi-times" className="outline-btn" onClick={hideDialog} />
         <Button label="Save" loading={loading} icon="pi pi-check" className="primary-btn" onClick={saveExpense} />
      </>
   );

   const template2 = {
      layout: "CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink",
      RowsPerPageDropdown: (options) => {
         const dropdownOptions = [
            { label: 10, value: 10 },
            { label: 20, value: 20 },
            { label: 50, value: 50 }
         ];

         return (
            <React.Fragment>
               <span className="mx-1" style={{ color: "var(--text-color)", userSelect: "none" }}></span>
               <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />
               <span className="mr-auto"></span>
            </React.Fragment>
         );
      },
      CurrentPageReport: (options) => {
         return (
            <span
               style={{
                  color: "var(--text-color)",
                  userSelect: "none",
                  width: "auto",
                  textAlign: "center"
               }}
            >
               Showing {options.first} to {options.last} of {options.totalRecords} shop expenses
            </span>
         );
      }
   };

   const dynamicTabs = () => {
      if (shops?.length > 1 && !UserSessionUtils.getSuperAdmin()) {
         return (
            <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} className="h-2rem">
               {shops.map((shop, index) => (
                  <TabPanel key={index} header={shop.name} className="h-auto"></TabPanel>
               ))}
            </TabView>
         );
      }
   };
   const allowExpansion = (rowData) => {
      return rowData !== null && rowData !== undefined;
   };
   return (
      <div className="grid crud-demo">
         <div className="col-12">
            <div className="py-2">
               <div className="grid">
                  <StatusSummary title="Total" value={formatNumberWithCommas(0)} />
                  <StatusSummary title="Value" value="0" iconName="pi-map-marker" />
                  <StatusSummary title="Items" value="0" iconName="pi-inbox" />
                  <StatusSummary title="Amount" value="0" iconName="pi-inbox" />
               </div>
            </div>
            <div className="card">
               <Toast ref={toast} />

               {header}

               <DataTable
                  loading={loading}
                  ref={dt}
                  value={expenses}
                  size="small"
                  resizableColumns
                  dataKey="id"
                  paginator
                  rows={10}
                  className="datatable-responsive"
                  paginatorTemplate={template2}
                  emptyMessage="No shop expenses found."
                  header={dynamicTabs}
                  onRowToggle={(e) => {
                     setExpandedRows(e.data);
                  }}
                  rowExpansionTemplate={(rowData) => (
                     <div className="field ml-6 pl-4">
                        <div className="col-12">
                           <label className="mt-3">
                              <span className="font-medium "> Desciprition:</span>
                              <p className="wrap">{rowData?.description}</p>
                           </label>
                        </div>
                     </div>
                  )}
                  expandedRows={expandedRows}
               >
                  <Column expander={allowExpansion} style={{ width: "5rem" }} />

                  <Column field="purchasedOn" header="Date" body={expenseDateBodyTemplate} />
                  <Column field="categoryName" header="Category" />
                  <Column field="amount" header="Amount" body={amountBodyTemplate} />

                  <Column field="shopName" header="Shop" />

                  <Column field="changedByFullName" header="Created by" />
                  <Column field="purchasedO" body={createdOnBodyTemplate} header="Action on" />
                  <Column body={actionBodyTemplate} header="Actions" />
               </DataTable>

               <Dialog
                  visible={showForm}
                  style={{ width: "700px" }}
                  header="Expense"
                  modal
                  className="p-fluid"
                  footer={shopAttendantDialogFooter}
                  onHide={hideDialog}
               >
                  <Messages ref={dialogMessage} style={{ width: "100%" }} />

                  <div className="formgrid grid">
                     <div className="field col">
                        <label htmlFor="shop">Shop</label>
                        <Dropdown
                           id="shop"
                           value={selectedShop}
                           //
                           options={shops}
                           onChange={(e) => setSelectedShop(e.target.value)}
                           optionLabel={"name"}
                           filter
                           filterBy="name"
                           placeholder="Select Shop"
                        />

                        {submitted && !selectedShop && <small className="p-error">Shop is required</small>}
                     </div>
                     <div className="field col">
                        <label htmlFor="distributor">Date</label>
                        <Calendar filter value={expenseDate} dateFormat="dd-M-yy" onChange={(e) => setExpenseDate(e?.target?.value)} />
                     </div>
                  </div>
                  <div className="field" style={{ marginTop: "10px" }}>
                     <label htmlFor="manufacturer">Description</label>
                     <InputText value={description} onChange={(e) => setDescription(e.target.value)} />
                     {submitted && !description && <small className="p-error">Desciprition is required</small>}
                  </div>
                  <div className="formgrid grid">
                     <div className="field col">
                        <label htmlFor="distributor">Category</label>
                        <Dropdown
                           filter
                           value={selectedCategory}
                           //
                           options={categories}
                           optionLabel="value"
                           optionValue="id"
                           onChange={(e) => setSelectedCategory(e.value)}
                        />
                        {submitted && !selectedCategory && <small className="p-error">Category is required</small>}
                     </div>
                     <div className="field col">
                        <label htmlFor="purchasedQuantity">Amount</label>
                        <InputNumber id="purchasedQuantity" value={amount} onChange={(e) => setAmount(e.value)} />

                        {submitted && !amount && <small className="p-error">Amount is required</small>}
                     </div>
                  </div>
               </Dialog>

               <DeleteRecordDialog
                  deleteProductDialog={showDelete}
                  setDeleteProductDialog={setShowDelete}
                  selectedRecordName={`${selectedExpense?.categoryName} expense`}
                  //
                  apiUrl={`/shop/expenses/${selectedExpense?.id}`}
                  isLoading={loading}
                  setIsLoading={setLoading}
                  onComplete={() => {
                     setLoading(false);
                     setShowDelete(false);
                     fetchExpenses();
                     toast.current.show({
                        severity: "success",
                        summary: "Successful",
                        detail: "Shop expense deleted",
                        life: 3000
                     });
                  }}
               />
            </div>
         </div>
      </div>
   );
};

export default Expenses;
