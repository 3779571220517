import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { BaseApiService } from "../../utils/BaseApiService";
import { Dropdown } from "primereact/dropdown";
import { UserSessionUtils } from "../../utils/UserSessionUtils";
import { MAXIMUM_RECORDS_PER_PAGE } from "../../constants/Constants";
import { Paginator } from "primereact/paginator";
import DeleteRecordDialog from "../../components/dialogs/DeleteRecordDialog";
import FilterComponent from "components/FilterComponent";
import { DAMAGES_ENDPOINT, SHOPS_ENDPOINT } from "utils/EndpointsUtils";
import { toReadableDate } from "utils/Utils";

export default function Damages() {
   const toast = useRef(null);
   const dt = useRef(null);

   const [damages, setDamages] = useState([]);
   const [selectedDamage, setSelectedDamage] = useState(null); //the table row
   const [limit, setLimit] = useState(0);
   const [isLoading, setIsLoading] = useState(false);
   const [first, setFirst] = useState(0);
   const [totalRecords, setTotalRecords] = useState(0);
   const [searchTerm, setSearchTerm] = useState("");
   const [deleteProductDialog, setDeleteProductDialog] = useState(false);
   const [shops, setShops] = useState([]);
   const [selectedShop, setSelectedShop] = useState(null);

   let offset = 0;

   const fetchDamages = async () => {
      setIsLoading(true);
      let searchParameters = {
         offset: offset,
         limit: MAXIMUM_RECORDS_PER_PAGE,
         ...(selectedShop && { shopId: selectedShop?.id })
      };

      await new BaseApiService(DAMAGES_ENDPOINT)
         .getRequestWithJsonResponse(searchParameters)
         .then((response) => {
            setDamages(response.records);
            setIsLoading(false);
            setTotalRecords(response?.totalItems);
         })
         .catch((error) => {
            toast.current.show({
               severity: "error",
               summary: "Error",
               detail: error?.message,
               life: 3000
            });
            setIsLoading(false);
         });
   };

   const fetchShops = async () => {
      let searchParameters = { offset: 0, limit: 0 };

      if (UserSessionUtils.getShopOwner()) {
         searchParameters.shopOwnerId = UserSessionUtils.getShopOwnerId();
      }
      await new BaseApiService(SHOPS_ENDPOINT)
         .getRequestWithJsonResponse(searchParameters)
         .then(async (response) => {
            setShops(response.records);
         })
         .catch((error) => {
            setIsLoading(false);
         });
   };

   const onPageChange = (e) => {
      offset = e.page * MAXIMUM_RECORDS_PER_PAGE;
      setFirst(e.first);
      setLimit(MAXIMUM_RECORDS_PER_PAGE);

      fetchDamages();
   };

   useEffect(() => {
      fetchShops();
      fetchDamages();
   }, []);

   const header = (
      <div className="flex flex-column md:flex-row md:align-items-center">
         <h5 className="text-center ">Shop Damages</h5>
      </div>
   );

   const template2 = {
      layout: "CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink",
      RowsPerPageDropdown: (options) => {
         const dropdownOptions = [
            { label: 10, value: 10 },
            { label: 20, value: 20 },
            { label: 50, value: 50 }
         ];

         return (
            <React.Fragment>
               <span className="mx-1" style={{ color: "var(--text-color)", userSelect: "none" }}></span>
               <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />
               <span className="mr-auto"></span>
            </React.Fragment>
         );
      },
      CurrentPageReport: (options) => {
         return (
            <span
               style={{
                  color: "var(--text-color)",
                  userSelect: "none",
                  width: "auto",
                  textAlign: "center"
               }}
            >
               Showing {options.first} to {options.last} of {options.totalRecords} records
            </span>
         );
      }
   };

   return (
      <div className="grid crud-demo">
         <div className="col-12">
            <div className="py-2 -mt-1">
               <div className="grid">
                  <div className="col-12 md:col-12 lg:col-12">
                     <FilterComponent onFilter={fetchDamages} onClear={() => setSelectedShop(null)}>
                        <div className="formgrid grid">
                           <div className="field col-4">
                              <label htmlFor="shop">Shop</label>
                              <Dropdown
                                 id="shop"
                                 value={selectedShop}
                                 options={shops}
                                 onChange={(e) => setSelectedShop(e.target.value)}
                                 optionLabel={"name"}
                                 filter
                                 showClear
                                 filterBy="name"
                                 placeholder="Select Shop"
                              />
                           </div>
                        </div>
                     </FilterComponent>

                     <div className="card">
                        <Toast ref={toast} />

                        {header}

                        <DataTable
                           loading={isLoading}
                           ref={dt}
                           value={damages}
                           dataKey="id"
                           rows={10}
                           className="datatable-responsive"
                           size="small"
                           resizableColumns
                        >
                           <Column field="serialNumber" header="Serial Number " />
                           <Column header="Product" field="shopProductName" />

                           <Column header="Type" field="type" />

                           <Column header="Qty lost" field="quantityLost" />

                           <Column header="Unit" field="unitOfQuantity" />
                           <Column header="Created by" field="shopProductName" />
                           <Column header="Created on" body={(e) => toReadableDate(e?.dateCreated)} />
                        </DataTable>
                        <Paginator
                           template={template2}
                           first={first}
                           rows={MAXIMUM_RECORDS_PER_PAGE}
                           totalRecords={totalRecords}
                           alwaysShow
                           onPageChange={onPageChange}
                        />
                     </div>
                  </div>
               </div>
            </div>

            <DeleteRecordDialog
               isLoading={isLoading}
               selectedRecordName={`Sale record ${selectedDamage?.id}`}
               setDeleteProductDialog={setDeleteProductDialog}
               deleteProductDialog={deleteProductDialog}
               setSelectedRecord={setSelectedDamage}
               showForm={true}
               apiUrl={DAMAGES_ENDPOINT + selectedDamage?.id}
               setIsLoading={setIsLoading}
               onComplete={() => {
                  fetchDamages();
                  setSelectedDamage(null);
                  toast.current.show({
                     severity: "success",
                     summary: "Successful",
                     detail: "Damage record deleted",
                     life: 3000
                  });
                  setIsLoading(false);
               }}
            />
         </div>
      </div>
   );
}
