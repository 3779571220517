import React, { useEffect, useRef, useState } from "react";
import { Chart } from "primereact/chart";
import { useNavigate } from "react-router-dom";
import StorageParams from "../constants/StorageParams";
import { GoogleMapsWrapper, MapComponent } from "../utils/GoogleMapUtils";
import GoogleMapWithMarkers from "../utils/ReactMap";
import { BaseApiService } from "../utils/BaseApiService";
import StatusSummary from "../components/StatusSummary";
import { formatNumberWithCommas } from "../utils/Utils";

let times24hr = [];
for (let hour = 0; hour < 24; hour++) {
   times24hr.push(`${hour.toString().padStart(2, "0")}:00`);
}

const dailyData = {
   labels: times24hr,
   datasets: [
      {
         label: "Customers",
         backgroundColor: "#000",
         borderColor: "#000",
         data: [6, 5, 8, 8, 5, 5, 4, 2, 6, 2, 1, 4, 7, 2, 5, 4, 11, 8, 5],
         tension: 0.4
      },
      {
         label: "Items",
         backgroundColor: "#f5e102",
         borderColor: "#f5e102",
         data: [65, 59, 80, 81, 56, 55, 40, 20, 60, 29, 19, 40, 73, 20, 50, 47, 18, 8, 84],
         tension: 0.4
      }
   ]
};

const weeklyData = {
   labels: ["Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat"],
   datasets: [
      {
         label: "Capital",
         backgroundColor: "#000",
         borderColor: "#000",
         data: [60, 50, 68, 38, 50, 50, 40],
         tension: 0.4
      },
      {
         label: "Profit",
         backgroundColor: "#f5e102",
         borderColor: "#f5e102",
         data: [65, 59, 80, 81, 56, 55, 50],
         tension: 0.4
      }
   ]
};
const yearData = {
   labels: ["January", "February", "March", "April", "May", "June", "July"],
   datasets: [
      {
         label: "My First dataset",
         backgroundColor: "#000",
         borderColor: "#000",
         data: [65, 59, 80, 81, 56, 55, 40],
         tension: 0.4
      },
      {
         label: "My Second dataset",
         backgroundColor: "#f5e102",
         borderColor: "#f5e102",
         data: [28, 48, 40, 19, 86, 27, 90],
         tension: 0.4
      }
   ]
};

const pieChartData = {
   labels: ["A", "B", "C"],
   datasets: [
      {
         label: "Capital",
         backgroundColor: ["#aba490", "#f5e102", "#000000"],
         data: [60, 20, 68]
      }
   ]
};

const LOCATIONS = [
   { lat: 48.8566, lng: 2.3522 },
   { lat: 47.1533, lng: 2.9123 }
];

const Dashboard = () => {
   const navigate = useNavigate();
   const isLoggedIn = localStorage.getItem(StorageParams.IS_LOGGED_IN);
   const [locations, setLocations] = useState(null);
   useEffect(() => {
      if (!isLoggedIn) {
         localStorage.clear();
      } else {
         loadShopLocations();
      }
   }, [isLoggedIn]);

   const loadShopLocations = () => {
      new BaseApiService("/shops/locations")
         .getRequestWithJsonResponse({ offset: 0, limit: 0 })
         .then((response) => {
            setLocations(
               response?.records.map((shop) => {
                  return {
                     label: shop.name,
                     lat: shop.locationLatitude,
                     lng: shop.locationLongitude
                  };
               })
            );
         })
         .catch((error) => {});
   };
   return (
      <div className="layout-dashboard">
         <div className="py-2">
            <div className="grid">
               <StatusSummary iconName="bx bxs-store " title="Shops" value={0} />
               <StatusSummary title="Users" value={0} iconName="pi pi-users" />
               <StatusSummary title="Sales Txn" value={formatNumberWithCommas(0)} iconName="bx bx-receipt" />
               <StatusSummary title="Purchases" value={formatNumberWithCommas(0)} iconName="bx bx-layer-plus" />
            </div>
         </div>

         <div className="grid p-fluid">
            <div className="col-12 lg:col-6">
               <div className="card">
                  <h5 className="centerText">Transactions</h5>
                  <Chart type="line" data={dailyData} />
               </div>
            </div>
            <div className="col-12 lg:col-6">
               <div className="card">
                  <h5 className="centerText">Sales</h5>
                  <Chart type="line" data={weeklyData} />
               </div>
            </div>

            <div className="col-12 lg:col-6">
               <div className="card">
                  <h5 className="centerText">Bar Chart</h5>
                  <Chart type="bar" data={yearData} />
               </div>
            </div>
            <div className="col-12 lg:col-6">
               <div className="card">
                  <h5 className="centerText">Bar Chart</h5>
                  <Chart type="doughnut" data={pieChartData} className="w-full md:w-25rem h-25rem" />
               </div>
            </div>
         </div>
         {locations != null && (
            <GoogleMapsWrapper>
               <GoogleMapWithMarkers key={"test"} mapId="map_id" locations={locations} className={"test"} />
            </GoogleMapsWrapper>
         )}
      </div>
   );
};

export default Dashboard;
