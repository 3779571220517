import * as XLSX from "xlsx";
import { BaseApiService } from "./BaseApiService";
import { formatDate2 } from "./Utils";

export const downloadProductExcelSheet = async (shop) => {
   if (shop) {
      const clabel = "UGX";

      await new BaseApiService("/shop-products")
         .getRequestWithJsonResponse({ shopId: shop?.id, limit: 0, offset: 0 })
         .then((response) => {
            const titles = [
               "Product ",
               `Price ${clabel}`,
               "Items sold",
               "Items in stock",
               "Stock value",
               "Category",
               "Manufacturer",
               "Barcode",
               "Package Unit",
               "SerialNumber",
               "Listed by",
               "Listed on"
            ];

            const summarisedata = response?.records?.map((data) => {
               const summary = data?.performanceSummary;
               const productSoldQty = summary?.totalQuantitySold || 0;
               const productStockedQty = summary?.totalQuantityStocked || 0;
               const price = data?.salesPrice;

               let remainingStock = Math.round(productStockedQty - productSoldQty);

               if (remainingStock === undefined || isNaN(remainingStock) || remainingStock < 1) {
                  remainingStock = 0;
               }
               return [
                  data?.productName,
                  price,
                  productSoldQty,
                  remainingStock,
                  Math.round(remainingStock * price),
                  data?.categoryName,
                  data?.manufacturerName,
                  data?.barcode,
                  data?.packageUnitName,
                  data?.serialNumber,
                  data?.createdByFullName,
                  formatDate2(data?.dateCreated, true)
               ];
            });

            const excelData = [titles, ...summarisedata];

            const sheetName = `${shop?.name} product list`;
            const ws = XLSX.utils.aoa_to_sheet(excelData);

            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

            XLSX.writeFile(wb, `${sheetName}.xlsx`);
         })
         .catch((error) => {
            // setIsLoading(false);
            console.log(error);
         });
   }
};
