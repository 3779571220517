let resolveBackendEndpoint = "https://backend.duqact.com/api/v1";
//let resolveBackendEndpoint = 'http://localhost:8080/api/v1';

if (window.location.hostname.includes("duqact.com")) {
   resolveBackendEndpoint = "https://backend.duqact.com/api/v1";
}

export const BASE_URL_ENDPOINT_PATH = resolveBackendEndpoint;

export const CURRENCIES_END_POINT = "/currencies";

export const LOOK_UPS_ENDPOINT = "/lookups/lookup-values";

export const SHOP_OWNERS_ENDPOINT = "/shop/owners";

export const SHOPS_ENDPOINT = "/shops";

export const SUBSCRIPTION_PLANS_ENDPOINT = "/subscription-plans";

export const COUNTRIES_ENDPOINT = "/lookups/countries";

export const SUBSCRIPTIONS_ENDPOINT = "/subscriptions";

export const SUBSCRIPTION_ATTATCHMENT_ENDPOINT = "/subscriptions/attach";

export const SUBSCRIPTION_PAYMENT_ENDPOINT = "/subscriptions/payments";

export const DAMAGES_ENDPOINT = "/damages";

export const SETTINGS_ENDPOINT = "/settings";

export const MESSAGE_TEMPLATES_ENDPOINT = "/settings/message-templates";

export const MESSAGE_TEMPLATE_PARAMS_ENDPOINT = "/settings/message-templates/params";

export const SHOP_SALES_ENDPOINT = "/shop-sales";

export const USERS_ENDPOINT = "/users";

export const SHOP_PRODUCTS_ENDPOINT = "/shop-products";

export const STOCK_ENTRY_ENDPOINT = "/stock-entries";

export const SHOP_ATTENDANTS_ENDPOINT = "/shops/attendants";

export const ROLES_ENDPOINT ="/users/roles"