export const PermissionParams = {
   CAN_CREATE_USER: "Create user",
   CAN_DELETE_USER: "Delete user",
   CAN_BLOCK_USER: "Block user",
   CAN_UPDATE_USER: "Update user",
   CAN_RESET_USER_PASSWORD: "Reset user password",
   CAN_VIEW_ATTENDANTS_LC1_LETTER: "View attendants LC1 letter",
   CAN_CREATE_ROLE: "Create role",
   CAN_DELETE_ROLE: "Delete role",
   CAN_UPDATE_ROLE: "Update role",
   CAN_VIEW_ROLES_PAGE: "View roles page",
   CAN_ADD_SHOP: "Add shop",
   CAN_EDIT_SHOP: "Edit shop",
   CAN_DELETE_SHOP: "Delete shop",
   CAN_MANAGE_SHOP_USERS: "Manage shop users",
   CAN_ADD_SHOP_USER: "Add shop user",
   CAN_VIEW_SHOP_GPS: "View shop GPS",
   CAN_EDIT_SHOP_CAPITAL: "Edit shop capital",
   CAN_WITHDRAW_SHOP_CAPITAL: "Withdraw shop capital",
   CAN_ADD_SHOP_CAPITAL: "Add shop capital",
   CAN_VIEW_SHOP_CAPITAL: "View shop capital",
   CAN_ENTER_SALES: "Enter sales",
   CAN_EDIT_SALES: "Edit sales",
   CAN_DELETE_SALES: "Delete sales",
   CAN_VIEW_SALES: "View sales",
   CAN_VIEW_DEBTS: "View debts",
   CAN_ADD_DEBT: "Add debt",
   CAN_ALLOW_DEBT_IN_SHOP: "Allow debt in shop",
   CAN_DELETE_DEBT: "Delete debt",
   CAN_ACCEPT_DEBT_PAYMENT: "Accept debt payment",
   CAN_CREATE_UPDATE_MY_SHOP_DETAILS: "Create/Update My Shop Details",
   CAN_CREATE_UPDATE_MY_SHOP_ATTENDANTS: "Create/Update My Shop Attendants",
   CAN_CREATE_UPDATE_MY_SHOP_STOCK: "Create/Update My Shop Stock",
   CAN_REGISTER_SHOP_SALES: "Register Shop Sales",
   CAN_VIEW_SHOP_INCOME: "View Shop Income",
   CAN_BLOCK_SHOP: "Block shop",
   CAN_ACCESS_TEST_SHOP: "Access Test shop (Moses Test Shop)",
   CAN_VIEW_LEADS_INCLUDE_VIEW_LEADS_ON_THE_MAP: "View leads include view leads on the map",
   CAN_ADD_LEADS_ON_MOBILE: "Add leads on mobile",
   CAN_DELETE_LEADS: "Delete leads",
   CAN_EDIT_UPDATE_LEADS: "Edit/Update leads",
   CAN_MANAGE_LEAD_OWNER: "Manage lead owner",
   CAN_ACCESS_SUBSCRIPTIONS: "Access subscriptions"
};
