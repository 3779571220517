import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Dropdown } from "primereact/dropdown";
import { BaseApiService } from "../../utils/BaseApiService";
import { showErrorMessage } from "../../constants/ErrorMessages";
import { formatNumberWithCommas, toReadableDate, toReadableDateTime } from "../../utils/Utils";
import { MAXIMUM_RECORDS_PER_PAGE } from "../../constants/Constants";
import { Paginator } from "primereact/paginator";
import { SHOPS_ENDPOINT, SUBSCRIPTION_PAYMENT_ENDPOINT } from "utils/EndpointsUtils";
import FilterComponent from "components/FilterComponent";
import { UserSessionUtils } from "utils/UserSessionUtils";
import SubscriptionPaymentForm from "components/dialogs/SubscriptionPaymentForm";
import { Button } from "primereact/button";

const SubscriptionPayments = () => {
   const [payments, setPayments] = useState([]);
   const [isLoading, setIsLoading] = useState(false);
   const [first, setFirst] = useState(0);
   const [totalRecords, setTotalRecords] = useState(0);
   const [selectedShop, setSelectedShop] = useState(null);
   const [shops, setShops] = useState([]);
   const [paymentForm, setPaymentForm] = useState(false);

   let offset = 0;

   const dialogMessage = useRef();
   const toast = useRef(null);
   const dt = useRef(null);

   const fetchPayments = async () => {
      setIsLoading(true);
      const searchParameters = {
         offset: offset,
         limit: MAXIMUM_RECORDS_PER_PAGE,
         ...(selectedShop && { shopId: selectedShop?.id })
      };

      await new BaseApiService(SUBSCRIPTION_PAYMENT_ENDPOINT)
         .getRequestWithJsonResponse(searchParameters)
         .then(async (response) => {
            setPayments(response.records);
            setTotalRecords(response.totalItems);
            setIsLoading(false);
         })
         .catch((error) => {
            showErrorMessage(dialogMessage, error);
            setIsLoading(false);
         });
   };

   const fetchShops = async () => {
      let searchParameters = { offset: 0, limit: 0 };
      if (UserSessionUtils.getShopOwner()) {
         searchParameters.shopOwnerId = UserSessionUtils.getShopOwnerId();
      }
      await new BaseApiService(SHOPS_ENDPOINT)
         .getRequestWithJsonResponse(searchParameters)
         .then(async (response) => {
            setShops(response.records);
         })
         .catch((error) => {});
   };

   const onPageChange = (e) => {
      offset = e.page * MAXIMUM_RECORDS_PER_PAGE;
      setFirst(e.first);

      fetchPayments();
   };

   useEffect(() => {
      fetchPayments();
      fetchShops();
   }, []);

   const template2 = {
      layout: "CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink",
      RowsPerPageDropdown: (options) => {
         const dropdownOptions = [
            { label: 10, value: 10 },
            { label: 20, value: 20 },
            { label: 50, value: 50 }
         ];

         return (
            <React.Fragment>
               <span className="mx-1" style={{ color: "var(--text-color)", userSelect: "none" }}></span>
               <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />
               <span className="mr-auto"></span>
            </React.Fragment>
         );
      },
      CurrentPageReport: (options) => {
         return (
            <span
               style={{
                  color: "var(--text-color)",
                  userSelect: "none",
                  width: "auto",
                  textAlign: "center"
               }}
            >
               Showing {options.first} to {options.last} of {options.totalRecords} records
            </span>
         );
      }
   };

   const header = (
      <div className="flex flex-column md:flex-row md:align-items-center">
         <h5 className="text-center ">Subscription payments</h5>
         <div className="ml-auto flex gap-3">
            <Button
               label="New"
               icon="pi pi-plus"
               className="primary-btn"
               onClick={() => setPaymentForm(true)}
               aria-controls="popup_menu_right"
               aria-haspopup
            />
         </div>
      </div>
   );

   return (
      <div className="grid crud-demo">
         <div className="col-12">
            {shops?.length > 1 && (
               <FilterComponent onFilter={fetchPayments}>
                  <div className="col-12 md:col-4 lg:col-4">
                     <label>Shop</label>

                     <Dropdown
                        value={selectedShop}
                        options={shops}
                        onChange={(e) => setSelectedShop(e.target.value)}
                        optionLabel={"name"}
                        filter
                        filterBy="name"
                        placeholder="Select shop"
                     />
                  </div>
               </FilterComponent>
            )}

            <div className="card">
               <Toast ref={toast} />
               {header}
               <DataTable
                  loading={isLoading}
                  value={payments}
                  dataKey="id"
                  rows={10}
                  className="datatable-responsive"
                  emptyMessage="No records found."
                  size="small"
                  resizableColumns
               >
                  <Column header="Serial No" field="serialNumber" />

                  <Column header="Plan" body={(e) => `${e?.subscriptionPlan?.name}`} />

                  <Column header="Shop" body={(e) => e?.subscription?.shop?.name} />

                  <Column header="Amount" body={(e) => formatNumberWithCommas(e?.amount)} className="text-center" />

                  <Column header="Period (days)" body={(e) => e?.subscriptionPlan?.durationInDays} className="text-center" />

                  <Column header="End date" body={(e) => toReadableDate(e?.subscription?.endDate)} />

                  <Column field="createdByFullName" header="Created by" />
                  <Column header="Action date" body={(e) => toReadableDateTime(e?.subscription?.startDate)} />
               </DataTable>
               <Paginator
                  template={template2}
                  first={first}
                  rows={MAXIMUM_RECORDS_PER_PAGE}
                  totalRecords={totalRecords}
                  alwaysShow
                  onPageChange={onPageChange}
               />

               <SubscriptionPaymentForm
                  shops={shops}
                  setShowForm={setPaymentForm}
                  toast={toast}
                  isVisible={paymentForm}
                  refreshTable={fetchPayments}
               />
            </div>
         </div>
      </div>
   );
};

export default SubscriptionPayments;
