import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { BaseApiService } from "../../utils/BaseApiService";
import { showErrorMessage } from "../../constants/ErrorMessages";
import { convertDateFormat, formatNumberWithCommas, toReadableDate } from "../../utils/Utils";
import { UserSessionUtils } from "../../utils/UserSessionUtils";
import { MAXIMUM_RECORDS_PER_PAGE } from "../../constants/Constants";
import { Paginator } from "primereact/paginator";
import DeleteRecordDialog from "../../components/dialogs/DeleteRecordDialog";
import StockEntryFileUpload from "../../components/dialogs/StockEntryFileUpload";
import StockEntryForm from "../../components/dialogs/StockEntryForm";
import StatusSummary from "../../components/StatusSummary";
import StockEntryRow from "../../components/row_expansions/StockEntryRow";
import FilterComponent from "../../components/FilterComponent";
import NewDamageDialog from "components/dialogs/NewDamageDialog";
import { template2 } from "components/PaginatorTemplate";
import { SHOPS_ENDPOINT, STOCK_ENTRY_ENDPOINT } from "utils/EndpointsUtils";
import { useSelector } from "react-redux";
import { getCanCreateUpdateMyShopStock } from "duqactStore/selectors";

const StockEntry = () => {
   const [stockEntries, setStockEntries] = useState([]);
   const [stockEntryDialog, setStockEntryDialog] = useState(false);
   const [deleteStockEntryDialog, setDeleteStockEntryDialog] = useState(false);
   const [selectedStockEntries, setSelectedStockEntries] = useState([]);
   const [searchTerm, setSearchTerm] = useState(null);
   const [isLoading, setIsLoading] = useState(false);
   const [expandedRows, setExpandedRows] = useState(null);
   const [first, setFirst] = useState(0);
   const [totalRecords, setTotalRecords] = useState(0);
   const [limit, setLimit] = useState(0);
   const [isUploadDialogVisible, setIsUploadDialogVisible] = useState(false);
   const [selectedRecord, setSelectedRecord] = useState(null);
   const [shops, setShops] = useState([]);
   const [selectedShop, setSelectedShop] = useState(null);
   const [selectedShopUser, setSelectedShopUser] = useState(null);
   const [startDate, setStartDate] = useState(null);
   const [endDate, setEndDate] = useState(null);
   const [damagesForm, setDamagesForm] = useState(false);
   const [shopUsers, setShopUsers] = useState([]);

   let offset = 0;

   const dialogMessage = useRef();
   const toast = useRef(null);
   const dt = useRef(null);
   const isShopOwner = UserSessionUtils.getShopOwner();
   const isShopAttendant = UserSessionUtils.getShopAttendant();
   const canDoStockCRUD = useSelector(getCanCreateUpdateMyShopStock);

   const fetchStockEntries = async () => {
      setIsLoading(true);
      const searchParameters = {
         offset: offset,
         limit: MAXIMUM_RECORDS_PER_PAGE,
         ...(isShopOwner && { shopOwnerId: UserSessionUtils.getShopOwnerId() }),
         ...(isShopAttendant && { shopId: UserSessionUtils.getShopId() }),
         ...(searchTerm && { searchTerm: searchTerm }),
         ...(selectedShop && { shopId: selectedShop?.id }),
         ...(selectedShopUser && { userId: selectedShopUser?.id }),
         ...(startDate && { startDate: convertDateFormat(startDate) }),
         ...(endDate && { endDate: convertDateFormat(endDate, true) })
      };

      await new BaseApiService(STOCK_ENTRY_ENDPOINT)
         .getRequestWithJsonResponse(searchParameters)
         .then(async (response) => {
            setStockEntries(response.records);
            setTotalRecords(response.totalItems);

            setIsLoading(false);
         })
         .catch((error) => {
            showErrorMessage(dialogMessage, error);
            setIsLoading(false);
         });
   };

   const fetchShops = async () => {
      if (isShopAttendant == false) {
         const searchParameters = { offset: 0, limit: 0, ...(isShopOwner && { shopOwnerId: UserSessionUtils.getShopOwnerId() }) };
         new BaseApiService(SHOPS_ENDPOINT)
            .getRequestWithJsonResponse(searchParameters)
            .then(async (response) => {
               if (response?.records?.length > 1) {
                  setShops([{ name: "All shops", id: UserSessionUtils.getShopOwnerId() }, ...response?.records.reverse()]);
                  return;
               }
               fetchShopUsers(response?.records[0]?.id);
               setShops(response.records);
            })
            .catch((error) => {});
      }
   };

   const fetchShopUsers = (id) => {
      let searchParameters = {
         offset: 0,
         limit: 0
      };

      const shopId = selectedShop?.id || id;

      new BaseApiService(`/shops/${shopId}/user-accounts`)
         .getRequestWithJsonResponse(searchParameters)
         .then((response) => {
            setShopUsers(response?.records);
         })
         .catch((error) => {
            console.error(error);
         });
   };

   const onPageChange = (e) => {
      offset = e.page * MAXIMUM_RECORDS_PER_PAGE;
      setFirst(e.first);
      setLimit(MAXIMUM_RECORDS_PER_PAGE);

      fetchStockEntries();
   };

   const clearFilterFields = () => {
      setSearchTerm(null);
      setSelectedShop(null);
      setEndDate(null);
      setStartDate(null);
   };

   useEffect(() => {
      fetchShops();
      fetchStockEntries();
   }, []);

   useEffect(() => {
      fetchShopUsers();
   }, [selectedShop]);

   const openNew = () => {
      setSelectedRecord(null);
      setStockEntryDialog(true);
   };

   const productBodyTemplate = (rowData) => {
      return (
         <>
            {rowData.productName}
            <br />
            <span className="text-sm opacity-80">SN: {rowData.serialNumber}</span>
         </>
      );
   };

   const purchaseQtyBodyTemplate = (rowData) => {
      const { formattedQuantity } = rowData;

      let x = formattedQuantity?.split(" ");
      let qty = Math.round(Number(x[0]));

      return <>{qty + ", " + x.slice(1).join(" ")}</>;
   };

   const statusBodyTemplate = (rowData) => {
      return <>{rowData?.statusName || "_"}</>;
   };

   const purchasePriceBodyTemplate = (rowData) => {
      return <>{formatNumberWithCommas(rowData.purchasePrice, rowData?.currency)}</>;
   };

   const unitCostBodyTemplate = (rowData) => {
      let price = rowData?.purchasePrice / rowData?.purchasedQuantity;

      return <>{formatNumberWithCommas(Math.floor(price), rowData?.currency)}</>;
   };

   const qtyRemainingBodyTemplate = (rowData) => {
      return <>{rowData?.purchasedQuantity || "_"}</>;
   };

   const actionBodyTemplate = (rowData) => {
      return (
         <div className="actions flex justify-content-center align-items-center">
            <Button
               icon="pi pi-pencil"
               className="p-button-rounded p-button-text p-button-success"
               onClick={() => {
                  setSelectedRecord(rowData);
                  setStockEntryDialog(true);
               }}
            />

            <Button
               icon="pi pi-bolt"
               className="p-button-rounded p-button-text p-button-info"
               onClick={() => {
                  setSelectedRecord(rowData);
                  setDamagesForm(true);
               }}
            />
            <Button
               icon="pi pi-trash"
               className="p-button-rounded p-button-text p-button-danger"
               onClick={() => {
                  setSelectedRecord(rowData);
                  setDeleteStockEntryDialog(true);
               }}
            />
         </div>
      );
   };

   const header = (
      <div className="flex flex-column md:flex-row md:align-items-center">
         <h5 className="m-0 line relative">Shop purchases</h5>
         <span className="block mt-2 md:mt-0 p-input-icon-left ml-5">
            <div className="p-inputgroup flex-1">
               <InputText placeholder="Search" value={searchTerm} onInput={(e) => setSearchTerm(e.target.value)} />
               <Button icon="pi pi-search" className="primary-btn" onClick={fetchStockEntries} />
            </div>
         </span>
         <div className="my-2 ml-auto flex flex-column md:flex-row md:align-items-center">
            <Button label="Upload" icon="pi pi-upload" className="outline-btn mr-2" onClick={() => setIsUploadDialogVisible(true)} />
            <Button label="Template" icon="pi pi-download" className="d-btn mr-2" />
            {canDoStockCRUD && <Button label="New" icon="pi pi-plus" className="primary-btn mr-2" onClick={openNew} />}
         </div>
      </div>
   );

   const allowExpansion = (rowData) => {
      return rowData !== null && rowData !== undefined;
   };

   return (
      <div className="grid crud-demo">
         <div className="col-12">
            <NewDamageDialog
               damagesForm={damagesForm}
               setDamagesForm={setDamagesForm}
               shops={shops}
               stockEntry={selectedRecord}
               onSave={() => {
                  // fetchDamages();
                  toast.current.show({
                     severity: "success",
                     summary: "Successful",
                     detail: "Damage record saved",
                     life: 3000
                  });
               }}
            />

            <FilterComponent onFilter={fetchStockEntries} onClear={clearFilterFields}>
               <div className="formgrid grid">
                  {isShopAttendant == false && shops?.length > 1 && (
                     <div className="field col-4">
                        <label htmlFor="shop">Shop</label>
                        <Dropdown
                           id="shop"
                           value={selectedShop}
                           options={shops}
                           onChange={(e) => setSelectedShop(e.target.value)}
                           optionLabel={"name"}
                           filter
                           filterBy="name"
                           placeholder="Select Shop"
                        />
                     </div>
                  )}

                  {isShopAttendant == false && (
                     <div className="field col-4">
                        <label htmlFor="shop">User</label>
                        <Dropdown
                           id="shop"
                           value={selectedShopUser}
                           options={shopUsers}
                           onChange={(e) => setSelectedShopUser(e.target.value)}
                           optionLabel={"fullName"}
                           filter
                           filterBy="fullName"
                           placeholder="Select User"
                        />
                     </div>
                  )}

                  <div className="field col-4">
                     <label htmlFor="shop">Start Date</label>
                     <Calendar
                        className="w-full"
                        maxDate={endDate ? endDate : new Date()}
                        value={startDate}
                        onChange={(e) => setStartDate(e.value)}
                        placeholder="Start date"
                        dateFormat="dd-M-yy"
                     />
                  </div>

                  <div className="field col-4">
                     <label htmlFor="shop">End Date</label>
                     <Calendar
                        className="w-full"
                        maxDate={new Date()}
                        value={endDate}
                        minDate={startDate ? startDate : new Date()}
                        onChange={(e) => setEndDate(e.value)}
                        placeholder="End date"
                        dateFormat="dd-M-yy"
                     />
                  </div>
               </div>
            </FilterComponent>

            <div className="card">
               <Toast ref={toast} />

               {header}

               <DataTable
                  loading={isLoading}
                  ref={dt}
                  value={stockEntries}
                  selection={selectedStockEntries}
                  onSelectionChange={(e) => setSelectedStockEntries(e.value)}
                  dataKey="id"
                  rows={10}
                  className="datatable-responsive"
                  emptyMessage="No shop purchases found."
                  size="small"
                  resizableColumns
                  onRowToggle={(e) => {
                     setExpandedRows(e.data);
                  }}
                  rowExpansionTemplate={(rowData) => <StockEntryRow rowData={rowData} />}
                  expandedRows={expandedRows}
               >
                  <Column expander={allowExpansion} style={{ width: "5rem" }} />

                  <Column field="product" header="Product" body={productBodyTemplate} style={{ width: "10%" }} />
                  <Column field="formattedQuantity" header="Package" body={purchaseQtyBodyTemplate} />

                  <Column
                     field="purchasedQuantity"
                     header="Qty "
                     className="text-center"
                     headerStyle={{ textAlign: "center" }}
                     body={qtyRemainingBodyTemplate}
                  />

                  {!isShopAttendant && <Column field="purchasePrice" header="Amount" body={purchasePriceBodyTemplate} />}
                  {!isShopAttendant && <Column field="unitCost" header="Unit cost" body={unitCostBodyTemplate} />}

                  <Column field="batchNumber" header="Batch no." />

                  {shops?.length > 1 && <Column field="shopName" header="Shop" />}

                  <Column field="statusName" header="Status" className="text-center" body={statusBodyTemplate} />
                  <Column
                     field="stockedOnDate"
                     header="Stocked on "
                     body={(rowData) => <> {toReadableDate(rowData.stockedOnDate)}</>}
                     className="text-center"
                  />
                  {canDoStockCRUD && (
                     <Column body={actionBodyTemplate} header="Actions" className="text-center" headerStyle={{ textAlign: "center" }} />
                  )}
               </DataTable>
               <Paginator
                  template={template2}
                  first={first}
                  rows={MAXIMUM_RECORDS_PER_PAGE}
                  totalRecords={totalRecords}
                  alwaysShow={false}
                  onPageChange={onPageChange}
               />

               <StockEntryForm
                  onSave={() => {
                     fetchStockEntries();
                     setSelectedRecord(null);
                     setStockEntryDialog(false);
                     toast.current.show({
                        severity: "success",
                        summary: "Successful",
                        detail: "Stock details saved",
                        life: 3000
                     });
                  }}
                  selectedRecord={selectedRecord}
                  setStockEntryDialog={setStockEntryDialog}
                  stockEntryDialog={stockEntryDialog}
                  shops={shops}
               />

               <DeleteRecordDialog
                  isLoading={isLoading}
                  selectedRecordName={`${selectedRecord?.productName} purchase record?`}
                  setDeleteProductDialog={setDeleteStockEntryDialog}
                  deleteProductDialog={deleteStockEntryDialog}
                  setSelectedRecord={setSelectedRecord}
                  showForm={true}
                  apiUrl={"/stock-entries/" + selectedRecord?.id}
                  setIsLoading={setIsLoading}
                  onComplete={() => {
                     fetchStockEntries();
                     setSelectedRecord(null);
                     toast.current.show({
                        severity: "success",
                        summary: "Successful",
                        detail: "Purchase record deleted",
                        life: 3000
                     });
                     setIsLoading(false);
                  }}
               />

               <StockEntryFileUpload
                  toast={toast}
                  isUploadDialogVisible={isUploadDialogVisible}
                  setIsUploadDialogVisible={setIsUploadDialogVisible}
                  fetchStockEntries={fetchStockEntries}
               />
            </div>
         </div>
      </div>
   );
};

export default StockEntry;
