import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { BaseApiService } from "../../utils/BaseApiService";
import { showSuccessMessage } from "../../constants/ErrorMessages";
import { formatNumberWithCommas, isNotEmpty, toReadableDateTime } from "../../utils/Utils";
import { UserSessionUtils } from "../../utils/UserSessionUtils";
import StatusSummary from "../../components/StatusSummary";
import EditAttendantRoles from "../../components/dialogs/EditAttendantRoles";
import FilterComponent from "components/FilterComponent";
import { SHOP_ATTENDANTS_ENDPOINT, SHOPS_ENDPOINT } from "utils/EndpointsUtils";
import { template2 } from "components/PaginatorTemplate";
import ShopAttendantForm from "components/dialogs/ShopAttendantForm";

const ShopAttendant = () => {
   let emptyShopAttendant = {
      id: null,
      firstName: null,
      lastName: null,
      preferredUsername: null,
      otherName: null,
      passportPhoto: null,
      emailAddress: null,
      nationalID: null,
      nationalIDNumber: null,
      primaryPhoneNumber: null,
      alternativePhoneNumber: null,
      postalAddress: null,
      lc1Letter: null,
      dateOfBirth: null,
      genderId: 1,
      genderName: null,
      shopId: null,
      shopName: null
   };

   const [searchTerm, setSearchTerm] = useState(null);
   const [isLoading, setIsLoading] = useState(false);

   const [shopAttendants, setShopAttendants] = useState(null);
   const [shopAttendantDialog, setShopAttendantDialog] = useState(false);
   const [deleteShopAttendantDialog, setDeleteShopAttendantDialog] = useState(false);
   const [shopAttendant, setShopAttendant] = useState(emptyShopAttendant);
   const [genders, setGenders] = useState(null);
   const [selectedShop2, setSelectedShop2] = useState(null);

   const [shops, setShops] = useState([]);
   const [maleAttendants, setMaleAtendants] = useState(null);
   const [femaleAttendants, setFemaleAttendants] = useState(null);
   const [roleDialog, setRoleDialog] = useState(false);
   const [selectedShopAttendant, setSelectedShopAttendant] = useState(null);

   const toast = useRef(null);
   const dt = useRef(null);

   const isShopOwner = UserSessionUtils.getShopOwner();
   const fetchShops = async () => {
      let searchParameters = { searchTerm: "", offset: 0, limit: 0 };

      if (UserSessionUtils.getShopAttendant()) {
         searchParameters.shopId = UserSessionUtils.getShopId();
      }

      if (isShopOwner) {
         searchParameters.shopOwnerId = UserSessionUtils.getShopOwnerId();
      }
      new BaseApiService(SHOPS_ENDPOINT)
         .getRequestWithJsonResponse(searchParameters)
         .then(async (response) => {
            if (response.records.length) {
               setSelectedShop2(response.records[0]);
            }

            setShops(response.records);
         })
         .catch((error) => {});
   };

   const fetchShopAttendants = async () => {
      setIsLoading(true);
      let searchParameters = { offset: 0, limit: 0 };
      if (isNotEmpty(searchTerm)) {
         searchParameters.searchTerm = searchTerm;
      }
      if (selectedShop2) {
         searchParameters.shopId = selectedShop2?.id;
      }

      if (isShopOwner) {
         searchParameters.shopOwnerId = UserSessionUtils.getShopOwnerId();
      }
      new BaseApiService(SHOP_ATTENDANTS_ENDPOINT)
         .getRequestWithJsonResponse(searchParameters)
         .then(async (response) => {
            setShopAttendants(response.records);
            let count = 0;
            for (let item of response.records) {
               if (item.genderName === "Male") {
                  count += 1; // the males
               }
            }
            setMaleAtendants(count);
            setFemaleAttendants(response.records.length - count);
            setIsLoading(false);
         })
         .catch((error) => {
            setIsLoading(false);
         });
   };

   const fetchGenders = async () => {
      new BaseApiService("/lookups/genders")
         .getRequestWithJsonResponse()
         .then(async (response) => {
            setGenders(response?.records);
         })
         .catch((error) => {});
   };

   useEffect(() => {
      fetchGenders();
      fetchShops();
      fetchShopAttendants();
   }, []);

   const hideShopAttendantDialog = () => {
      setDeleteShopAttendantDialog(false);
   };

   const confirmDeleteShopAttendant = (shopAttendant) => {
      setShopAttendant(shopAttendant);
      setDeleteShopAttendantDialog(true);
   };

   const deleteShopAttendant = () => {
      new BaseApiService("/shops/attendants/" + shopAttendant.id)
         .deleteRequestWithJsonResponse()
         .then((response) => {
            let _shopAttendants = shopAttendants.filter((val) => val.id !== shopAttendant.id);
            setShopAttendants(_shopAttendants);
            setDeleteShopAttendantDialog(false);
            setShopAttendant(emptyShopAttendant);
            toast.current.show({
               severity: "success",
               summary: "Successful",
               detail: "Shop attendant Deleted",
               life: 3000
            });
         })
         .catch((error) => {});
   };

   const actionBodyTemplate = (rowData) => {
      return (
         <div className="actions flex md:flex-row md:align-items-center">
            <Button
               icon="pi pi-pencil"
               className="p-button-rounded p-button-text p-button-success mr-2"
               onClick={() => {
                  setSelectedShopAttendant(rowData);
                  setShopAttendantDialog(true);
               }}
            />
            <Button
               icon="pi pi-shield"
               className="p-button-rounded p-button-text p-button-success mr-2"
               onClick={() => {
                  setSelectedShopAttendant(rowData);
                  setRoleDialog(true);
               }}
            />

            <Button
               icon="pi pi-trash"
               className="p-button-rounded p-button-text p-button-danger mr-2"
               onClick={() => confirmDeleteShopAttendant(rowData)}
            />
         </div>
      );
   };

   const header = (
      <div className="flex flex-column md:flex-row md:align-items-center">
         <h5 className="m-0 line relative">Shop users</h5>
         <span className="block mt-2 md:mt-0 p-input-icon-left ml-5">
            <div className="p-inputgroup flex-1">
               <InputText placeholder="Search" onInput={(e) => setSearchTerm(e.target.value)} />
               <Button icon="pi pi-search" className="primary-btn" onClick={fetchShopAttendants} />
            </div>
         </span>
         <div className="my-2 ml-auto">
            <Button label="New" icon="pi pi-plus" className="primary-btn mr-2" onClick={() => setShopAttendantDialog(true)} />
         </div>
      </div>
   );

   const deleteShopAttendantDialogFooter = (
      <>
         <Button label="No" icon="pi pi-times" className="outline-btn" onClick={hideShopAttendantDialog} />
         <Button label="Yes" icon="pi pi-check" className="primary-btn" onClick={deleteShopAttendant} />
      </>
   );

   return (
      <div className="grid crud-demo">
         <div className="col-12">
            <div className="py-2">
               <div className="grid">
                  <StatusSummary iconName="pi pi-shopping-cart " title="Shops" value={formatNumberWithCommas(shops?.length)} />
                  <StatusSummary title="Male" value={formatNumberWithCommas(maleAttendants)} iconName="bx bx-receipt" />
                  <StatusSummary title="Female" value={formatNumberWithCommas(femaleAttendants)} iconName="pi pi-money-bill" />
                  <StatusSummary title="Total" value={formatNumberWithCommas(maleAttendants + femaleAttendants)} iconName="bx bx-wallet" />
               </div>
            </div>

            <EditAttendantRoles
               selectedRecord={selectedShopAttendant}
               roleDialog={roleDialog}
               setRoleDialog={setRoleDialog}
               onComplete={() => {
                  fetchShopAttendants();
                  showSuccessMessage(toast, "Shop User Details saved");
               }}
            />
            {UserSessionUtils.getSuperAdmin() && (
               <FilterComponent onFilter={fetchShopAttendants} onClear={() => setSelectedShop2(null)}>
                  <div className="field col-4">
                     <label htmlFor="shop">Shop</label>
                     <Dropdown
                        id="shop"
                        showClear
                        value={selectedShop2}
                        options={shops}
                        onChange={(e) => setSelectedShop2(e.target.value)}
                        optionLabel={"name"}
                        filter
                        filterBy="name"
                        placeholder="Select Shop"
                     />
                  </div>
               </FilterComponent>
            )}
            <div className="card">
               <Toast ref={toast} />

               {header}

               <DataTable
                  ref={dt}
                  value={shopAttendants}
                  dataKey="id"
                  paginator
                  rows={10}
                  loading={isLoading}
                  className="datatable-responsive"
                  paginatorTemplate={template2}
                  emptyMessage="No shop attendants found."
                  size="small"
                  resizableColumns
               >
                  <Column field="fullName" header="Name" body={(d) => d?.firstName + " " + d?.lastName} />
                  <Column field="preferredUsername" header="Username" />
                  <Column field="primaryPhoneNumber" header="Phone" />
                  <Column field="shopName" header="Shop" />
                  <Column field="createdByFullName" header="Created by" />
                  <Column field="dateCreated" header="Created on" body={(d) => toReadableDateTime(d?.dateCreated)} />
                  <Column body={actionBodyTemplate} header="Actions" />
               </DataTable>

               <ShopAttendantForm
                  shops={shops}
                  selectedShopAttendant={selectedShopAttendant}
                  setSelectedShopAttendant={setSelectedShopAttendant}
                  visible={shopAttendantDialog}
                  setVisible={setShopAttendantDialog}
                  genders={genders}
                  onFinish={() => {
                     showSuccessMessage(toast, "Shop User Details saved");
                     fetchShopAttendants();
                  }}
               />

               <Dialog
                  visible={deleteShopAttendantDialog}
                  style={{ width: "450px" }}
                  header="Confirm"
                  modal
                  footer={deleteShopAttendantDialogFooter}
                  onHide={hideShopAttendantDialog}
               >
                  <div className="flex align-items-center justify-content-center">
                     <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                     {shopAttendant && (
                        <span>
                           Are you sure you want to delete <b>{shopAttendant.firstName}</b>?
                        </span>
                     )}
                  </div>
               </Dialog>
            </div>
         </div>
      </div>
   );
};

export default ShopAttendant;
