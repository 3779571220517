import FormFooter from "components/FormFooter";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import React, { useEffect, useRef, useState } from "react";
import { MESSAGE_CHANNELS, MESSAGE_TYPES } from "./templateEnums";
import { BaseApiService } from "utils/BaseApiService";
import { MESSAGE_TEMPLATES_ENDPOINT, MESSAGE_TEMPLATE_PARAMS_ENDPOINT } from "utils/EndpointsUtils";
import { Messages } from "primereact/messages";
import { showErrorMessage } from "constants/ErrorMessages";
import ChipButton from "components/ChipButton";

export default function TemplateForm({ showForm, setShowForm, selectedTemplate, onSave, setSelectedTemplate }) {
   const [loading, setLoading] = useState(false);

   const [name, setName] = useState("");
   const [subject, setSubject] = useState("");
   const [type, setType] = useState(null);
   const [body, setBody] = useState("");
   const [channel, setChannel] = useState(null);
   const [messageParams, setMessageParams] = useState([]);

   const messagesRef = useRef(null);
   const bodyRef = useRef(null);

   const copyText = (text) => {
      if (text) {
         navigator.clipboard.writeText(text);
         const body2 = body + text;
         setBody(body2);
         bodyRef.current.focus();
      }
   };

   const hideDialog = () => {
      setShowForm(false);
      setName("");
      setSubject("");
      setType(null);
      setBody("");
      setChannel(null);
      setSelectedTemplate(null);
   };

   const fillFields = () => {
      if (selectedTemplate) {
         setName(selectedTemplate?.name);
         setSubject(selectedTemplate?.subject);
         setType(selectedTemplate?.type);
         setBody(selectedTemplate?.body);
         setChannel(selectedTemplate?.channel);
      }
   };

   const getTemplateParams = async () => {
      await new BaseApiService(MESSAGE_TEMPLATE_PARAMS_ENDPOINT)
         .getRequestWithJsonResponse()
         .then((r) => {
            setMessageParams(r?.records);
         })
         .catch((e) => {
            console.log(e);
         });
   };

   const save = async () => {
      const payload = { id: selectedTemplate?.id || 0, name: name, subject: subject, type: type, body: body, channel: channel };

      if (!loading) {
         setLoading(true);
         await new BaseApiService(MESSAGE_TEMPLATES_ENDPOINT)
            .postRequestWithJsonResponse(payload)
            .then((r) => {
               setLoading(false);
               onSave();
               hideDialog();
            })
            .catch((e) => {
               setLoading(false);
               showErrorMessage(messagesRef, e?.message);
            });
      }
   };

   useEffect(() => {
      if (selectedTemplate) {
         fillFields();
      }
   }, [selectedTemplate]);

   useEffect(() => {
      getTemplateParams();
   }, []);

   return (
      <Dialog
         className="p-fluid"
         header="Message Template"
         visible={showForm}
         onHide={hideDialog}
         footer={() => <FormFooter onClickLeft={hideDialog} onClickRight={save} isLoading={loading} />}
         style={{ width: "600px" }}
      >
         <Messages ref={messagesRef} />
         <div className="formgrid grid ">
            <div className="field col-12 md:col-6 lg:col-6">
               <label>Name</label>

               <InputText value={name} onChange={(e) => setName(e.target.value)} />
            </div>

            <div className="field col-12 md:col-6 lg:col-6">
               <label>Subject</label>

               <InputText value={subject} onChange={(e) => setSubject(e.target.value)} />
            </div>
         </div>

         <div className="formgrid grid ">
            <div className="field col-12 md:col-6 lg:col-6">
               <label>Channel</label>

               <Dropdown
                  value={channel}
                  options={MESSAGE_CHANNELS}
                  optionLabel="label"
                  optionValue="channel"
                  onChange={(e) => setChannel(e.target.value)}
                  filter
               />
            </div>

            <div className="field col-12 md:col-6 lg:col-6">
               <label>Type</label>
               <Dropdown
                  value={type}
                  options={MESSAGE_TYPES}
                  optionLabel="label"
                  optionValue="type"
                  onChange={(e) => setType(e.target.value)}
                  filter
               />
            </div>
         </div>

         <div className="formgrid grid ">
            <div className="field col-12">
               <label>Body</label>

               <InputTextarea value={body} ref={bodyRef} onChange={(e) => setBody(e.target.value)} autoResize style={{ minHeight: "100px" }} />
            </div>
         </div>
         <div className="formgrid grid flex flex-wrap gap-2 ">
            {messageParams.map((param, index) => (
               <ChipButton label={param.replace(/[{}]/g, "")} onClick={() => copyText(param)} key={index} />
            ))}
         </div>
      </Dialog>
   );
}
