import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { MAXIMUM_RECORDS_PER_PAGE } from "../../constants/Constants";
import { Paginator } from "primereact/paginator";
import FilterComponent from "components/FilterComponent";
import { BaseApiService } from "utils/BaseApiService";
import { MESSAGE_TEMPLATES_ENDPOINT, MESSAGE_TEMPLATE_PARAMS_ENDPOINT } from "utils/EndpointsUtils";
import TemplateForm from "./TemplateForm";
import { showSuccessMessage } from "constants/ErrorMessages";
import { toReadableDateTime } from "utils/Utils";
import { InputText } from "primereact/inputtext";
import { MESSAGE_CHANNELS, MESSAGE_TYPES } from "./templateEnums";
import { Dropdown } from "primereact/dropdown";
import { template2 } from "components/PaginatorTemplate";
import DeleteRecordDialog from "components/dialogs/DeleteRecordDialog";

export default function MessageTemplates() {
   const toast = useRef(null);
   const dt = useRef(null);

   const [limit, setLimit] = useState(0);
   const [isLoading, setIsLoading] = useState(false);
   const [first, setFirst] = useState(0);
   const [totalRecords, setTotalRecords] = useState(0);
   const [selectedType, setSelectedType] = useState(null);
   const [selectedChannel, setSelectedChannel] = useState(null);
   const [data, setData] = useState([]);
   const [showForm, setShowForm] = useState(false);
   const [showDelete, setShowDelete] = useState(false);
   const [selectedTemplate, setSelectedTemplate] = useState(null);
   const [expandedRows, setExpandedRows] = useState(null);
   const [searchTerm, setSearchTerm] = useState("");

   const allowExpansion = (rowData) => {
      return rowData !== null && rowData !== undefined;
   };

   let offset = 0;

   const clearFields = () => {};

   const getMessageTemplates = async () => {
      const searchParameters = {
         offset: offset,
         limit: MAXIMUM_RECORDS_PER_PAGE,
         ...(searchTerm?.trim() !== "" && { searchTerm: searchTerm }),
         ...(selectedType && { type: selectedType }),
         ...(selectedChannel && { channel: selectedChannel })
      };

      setIsLoading(true);

      await new BaseApiService(MESSAGE_TEMPLATES_ENDPOINT)
         .getRequestWithJsonResponse(searchParameters)
         .then((r) => {
            setIsLoading(false);
            setData(r?.records);
            setTotalRecords(r?.totalItems);
         })
         .catch((e) => {
            console.log(e);
            setIsLoading(false);
         });
   };

   const onPageChange = (e) => {
      offset = e.page * MAXIMUM_RECORDS_PER_PAGE;
      setFirst(e.first);
      setLimit(MAXIMUM_RECORDS_PER_PAGE);

      getMessageTemplates();
   };

   const header = (
      <div className="sm:flex flex-row justify-content-between align-items-center gap-4 mb-5">
         <div className="sm:flex flex-row align-items-center justify-content-center gap-1">
            <label className="font-medium text-xl">Message Templates</label>
            <div className="p-inputgroup flex-1">
               <InputText placeholder="Search" value={searchTerm} onInput={(e) => setSearchTerm(e.target.value)} />
               <Button icon="pi pi-search" className="primary-btn" onClick={getMessageTemplates} />
            </div>
         </div>
         <Button label="New" icon={"pi pi-plus"} className="primary-btn" onClick={() => setShowForm(true)} />
      </div>
   );

   const typeBodyTemplate = (rowData) => {
      const matchtch = MESSAGE_TYPES.find((e) => e.type === rowData?.type);
      return <>{matchtch?.label}</>;
   };

   const channelBodyTemplate = (rowData) => {
      const match = MESSAGE_CHANNELS.find((e) => e.channel === rowData?.channel);
      return <>{match?.label}</>;
   };

   const actionBodyTemplate = (rowData) => {
      return (
         <div className="actions flex justify-content-center md:align-items-center ">
            <Button
               icon="pi pi-pencil"
               className="p-button-rounded p-button-text p-button-success "
               onClick={() => {
                  setSelectedTemplate(rowData);
                  setShowForm(true);
               }}
            />

            <Button
               icon="pi pi-trash"
               className="p-button-rounded p-button-text p-button-danger "
               onClick={() => {
                  setSelectedTemplate(rowData);
                  setShowDelete(true);
               }}
            />
         </div>
      );
   };

   useEffect(() => {
      getMessageTemplates();
   }, []);
   return (
      <div className="grid crud-demo">
         <div className="col-12">
            <div className="py-2 -mt-3">
               {
                  ///sumarries
               }
            </div>

            <Toast ref={toast} />

            <div className="py-2 -mt-1">
               <div className="grid">
                  <div className="col-12 md:col-12 lg:col-12">
                     <FilterComponent
                        onFilter={getMessageTemplates}
                        onClear={() => {
                           clearFields();
                        }}
                     >
                        <div className="formgrid grid">
                           <div className="field col-12 md:col-6 lg:col-3">
                              <label htmlFor="type">Type</label>
                              <Dropdown
                                 id="type"
                                 value={selectedType}
                                 options={MESSAGE_TYPES}
                                 optionLabel="label"
                                 optionValue="type"
                                 onChange={(e) => setSelectedType(e.target.value)}
                                 showClear
                              />
                           </div>
                           <div className="field col-12 md:col-6 lg:col-3">
                              <label htmlFor="type">Channel</label>
                              <Dropdown
                                 id="type"
                                 value={selectedChannel}
                                 options={MESSAGE_CHANNELS}
                                 optionLabel="label"
                                 optionValue="channel"
                                 onChange={(e) => setSelectedChannel(e.target.value)}
                                 showClear
                              />
                           </div>
                        </div>
                     </FilterComponent>

                     <div className="card">
                        <Toast ref={toast} />

                        {header}

                        <DataTable
                           loading={isLoading}
                           ref={dt}
                           value={data}
                           dataKey="id"
                           rows={10}
                           className="datatable-responsive"
                           emptyMessage="No records found."
                           size="small"
                           resizableColumns
                           onRowToggle={(e) => {
                              setExpandedRows(e.data);
                           }}
                           rowExpansionTemplate={(rowData) => (
                              <div>
                                 <label className="font-medium">Body</label>
                                 <p className="wrap mt-2">{rowData?.body}</p>
                              </div>
                           )}
                           expandedRows={expandedRows}
                        >
                           {data?.length > 0 && <Column expander={allowExpansion} style={{ width: "5rem" }} />}
                           <Column field="serialNumber" header="Serial Number" />
                           <Column field="name" header="Name" />
                           <Column field="subject" header="Subject" />
                           <Column field="channel" header="Channel" body={channelBodyTemplate} />

                           <Column field="type" header="Type" body={typeBodyTemplate} />

                           <Column field="changedByFullName" header="Created By" />

                           <Column field="changedByFullName" header="Created on" body={(d) => toReadableDateTime(d?.dateCreated)} />

                           <Column body={actionBodyTemplate} header="Actions" headerStyle={{ textAlign: "center" }} style={{ width: "10%" }} />
                        </DataTable>
                        <Paginator
                           template={template2}
                           first={first}
                           rows={MAXIMUM_RECORDS_PER_PAGE}
                           totalRecords={totalRecords}
                           alwaysShow
                           onPageChange={onPageChange}
                        />

                        <TemplateForm
                           showForm={showForm}
                           setShowForm={setShowForm}
                           selectedTemplate={selectedTemplate}
                           setSelectedTemplate={setSelectedTemplate}
                           onSave={() => {
                              showSuccessMessage(toast, "Details saved");
                              getMessageTemplates();
                           }}
                        />

                        <DeleteRecordDialog
                           selectedRecordName={selectedTemplate?.name}
                           onComplete={() => {
                              showSuccessMessage(toast, "Record deleted");
                              getMessageTemplates();
                           }}
                           apiUrl={`${MESSAGE_TEMPLATES_ENDPOINT}/${selectedTemplate?.id}`}
                           deleteProductDialog={showDelete}
                           setDeleteProductDialog={setShowDelete}
                        />
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
}
