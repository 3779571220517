/* GoogleMaps.js */

import React from "react";
import { Wrapper } from "@googlemaps/react-wrapper";

const apiKeyHandler = () => {
   if (window.location.href.includes("http://localhost")) {
      return "AIzaSyCm5bW5ptLGPPA_az9aHBgccqJKpR5b-1c";
   } else {
      return "AIzaSyBLzletcXGf4MyXHtFBnQ9AueNc7wlMh4k";
   }
};
export const GoogleMapsWrapper = ({ children }) => {
   const apiKey = apiKeyHandler();

   if (!apiKey) {
      return <div>Cannot display the map: google maps api key missing</div>;
   }

   return <Wrapper apiKey={apiKey}>{children}</Wrapper>;
};

export const GMP_API_KY = apiKeyHandler;
