import React from "react";
import { Button } from "primereact/button";

const FormFooter = ({ labelLeft = "Cancel", labelRight = "Save", isLoading = false, onClickLeft, onClickRight }) => {
   return (
      <>
         <Button loading={isLoading} label={labelLeft} icon="pi pi-times" className=" outline-btn" onClick={onClickLeft} />
         <Button loading={isLoading} label={labelRight} icon="pi pi-save" className=" primary-btn" onClick={onClickRight} />
      </>
   );
};

export default FormFooter;
