import React, { useState, useEffect, useRef } from "react";
import { classNames } from "primereact/utils";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { BaseApiService } from "../../utils/BaseApiService";
import { showErrorMessage } from "../../constants/ErrorMessages";
import { Messages } from "primereact/messages";
import PhoneInput from "react-phone-input-2";

import { MultiSelect } from "primereact/multiselect";
import FormFooter from "components/FormFooter";
import { roleTypes } from "constants/Constants";

function ShopAttendantForm({ onFinish, selectedShopAttendant, setSelectedShopAttendant, shops = [], visible, setVisible, genders }) {
   const dialogMessage = useRef();

   let emptyShopAttendant = {
      id: null,
      firstName: null,
      lastName: null,
      preferredUsername: null,
      otherName: null,
      passportPhoto: null,
      emailAddress: null,
      nationalID: null,
      nationalIDNumber: null,
      primaryPhoneNumber: null,
      alternativePhoneNumber: null,
      postalAddress: null,
      lc1Letter: null,
      dateOfBirth: null,
      genderId: 1,
      genderName: null,
      shopId: null,
      shopName: null
   };

   const [shopAttendant, setShopAttendant] = useState(emptyShopAttendant);
   const [submitted, setSubmitted] = useState(false);
   const [selectedGender, setSelectedGender] = useState(null);
   const [selectedShop, setSelectedShop] = useState(null);

   const [edit, setEdit] = useState(false);
   const [phoneNumber, setPhoneNumber] = useState(null);
   const [altphoneNumber, setAltPhoneNumber] = useState(null);
   const [loading, setLoading] = useState(false);

   const saveShopAttendant = async () => {
      setSubmitted(true);
      setLoading(true);
      const apiUrl = edit && edit === true ? "/shops/attendants/" + shopAttendant?.id : "/shops/attendants";

      await new BaseApiService(apiUrl)
         .saveRequestWithJsonResponse(
            {
               firstName: shopAttendant.firstName,
               lastName: shopAttendant.lastName,
               preferredUsername: shopAttendant.preferredUsername,
               otherName: shopAttendant.otherName,
               emailAddress: shopAttendant.emailAddress,
               nationalID: shopAttendant.nationalID,
               nationalIDNumber: shopAttendant.nationalIDNumber,
               primaryPhoneNumber: phoneNumber,
               alternativePhoneNumber: altphoneNumber,
               postalAddress: shopAttendant.postalAddress,
               lc1Letter: shopAttendant?.lc1Letter,
               genderId: selectedGender?.id,
               shopId: selectedShop?.id
            },
            edit
         )
         .then((response) => {
            hideDialog();
            onFinish();
         })
         .catch((error) => {
            showErrorMessage(dialogMessage, error.message);
            setLoading(false);
         });
   };

   const hideDialog = () => {
      setVisible(false);
      setShopAttendant(emptyShopAttendant);
      setSelectedGender(null);
      setPhoneNumber("");
      setSubmitted(false);
      setLoading(false);
      setEdit(false);
      setSelectedShopAttendant(null);
   };

   const onInputChange = (e, name) => {
      const val = (e.target && e.target.value) || "";
      let _shopOwner = { ...shopAttendant };
      _shopOwner[`${name}`] = val;

      setShopAttendant(_shopOwner);
   };

   const onGenderChange = (e) => {
      setSelectedGender(e.target.value);
   };

   const handleEdit = () => {
      if (selectedShopAttendant) {
         setEdit(true);
         setShopAttendant({ ...selectedShopAttendant });
         setSelectedShop(shops?.find((obj) => obj.id === selectedShopAttendant.shopId));
         setSelectedGender(genders?.find((obj) => obj.id === selectedShopAttendant.genderId));
         setPhoneNumber(selectedShopAttendant.primaryPhoneNumber);
         setAltPhoneNumber(selectedShopAttendant.alternativePhoneNumber);
      }
   };

   useEffect(() => {
      handleEdit();
   }, [selectedShopAttendant]);

   return (
      <Dialog
         visible={visible}
         style={{ width: "700px" }}
         header="Shop User Details"
         modal
         className="p-fluid"
         footer={() => <FormFooter onClickRight={saveShopAttendant} onClickLeft={hideDialog} isLoading={loading} />}
         onHide={hideDialog}
      >
         <Messages ref={dialogMessage} style={{ width: "100%" }} />
         <div className="field" style={{ marginTop: "10px" }}>
            <label htmlFor="shop">Shop</label>
            <Dropdown
               id="shop"
               value={selectedShop}
               options={shops}
               onChange={(e) => setSelectedShop(e.target.value)}
               optionLabel={"name"}
               filter={shops?.length > 10}
               filterBy="name"
               placeholder="Select a Shop"
            />
            {submitted && !selectedShop && <small className="p-error">Shop is required.</small>}
         </div>

         <div className="formgrid grid">
            <div className="field col-12 md:col-4">
               <label htmlFor="firstName">First Name</label>
               <InputText
                  id="firstName"
                  value={shopAttendant.firstName || ""}
                  onChange={(e) => onInputChange(e, "firstName")}
                  required
                  autoFocus
                  className={classNames({
                     "p-error": submitted && !shopAttendant.firstName
                  })}
               />
               {submitted && !shopAttendant.firstName && <small className="p-error">First name is required.</small>}
            </div>
            <div className="field col-12 md:col-4">
               <label htmlFor="lastName">Last Name</label>
               <InputText
                  id="lastName"
                  value={shopAttendant.lastName || ""}
                  onChange={(e) => onInputChange(e, "lastName")}
                  required
                  autoFocus
                  className={classNames({ "p-error": submitted && !shopAttendant.lastName })}
               />
               {submitted && !shopAttendant.lastName && <small className="p-error">Last name is required.</small>}
            </div>
            <div className="field col-6 md:col-4">
               <label htmlFor="preferredUsername">Username</label>
               <InputText
                  id="preferredUsername"
                  value={shopAttendant.preferredUsername || shopAttendant.username || ""}
                  onChange={(e) => onInputChange(e, "preferredUsername")}
                  required
                  autoFocus
                  className={classNames({
                     "p-error": submitted && !shopAttendant.preferredUsername
                  })}
               />
               {submitted && !shopAttendant.preferredUsername && <small className="p-error">Username is required.</small>}
            </div>
         </div>
         <div className="formgrid grid">
            <div className="field col-12 col-6 md:col-6">
               <label htmlFor="emailAddress">Email Address</label>
               <InputText
                  id="emailAddress"
                  value={shopAttendant.emailAddress || ""}
                  onChange={(e) => onInputChange(e, "emailAddress")}
                  required
                  autoFocus
                  className={classNames({ "p-error": submitted && !shopAttendant.emailAddress })}
               />
               {submitted && !shopAttendant.emailAddress && <small className="p-error">Email address is required.</small>}
            </div>
            <div className="field col-12 md:col-6 lg:col-6">
               <label htmlFor="gender">Gender</label>
               <Dropdown
                  id="gender"
                  value={selectedGender}
                  options={genders}
                  required
                  onChange={onGenderChange}
                  optionLabel="name"
                  placeholder="Select a Gender"
               />
            </div>
         </div>
         <div className="formgrid grid">
            <div className="field col-12 md:col-6 lg:col-6">
               <label htmlFor="primaryPhoneNumber">Phone number</label>
               <PhoneInput
                  id="primaryPhoneNumber"
                  inputClass="w-full"
                  className={classNames({ "error-Input": submitted && !phoneNumber })}
                  value={phoneNumber || ""}
                  onChange={(e) => setPhoneNumber(e)}
                  country={"ug"}
                  onlyCountries={["ug", "ke"]}
                  inputProps={{
                     required: true
                  }}
               />
               {submitted && !phoneNumber && <small className="p-error">Phone number is required.</small>}
            </div>
            <div className="field col-12 md:col-6 lg:col-6">
               <label htmlFor="alternativePhoneNumber">Alternative number</label>
               <PhoneInput
                  id="altPhoneNumber"
                  inputClass="w-full"
                  value={altphoneNumber || ""}
                  onChange={(e) => setAltPhoneNumber(e)}
                  country={"ug"}
                  onlyCountries={["ug", "ke"]}
                  inputProps={{
                     required: true
                  }}
               />
            </div>
         </div>
         <div className="formgrid grid">
            <div className="field col-6 md:col-6">
               <label htmlFor="nationalIDNumber">National ID Number</label>
               <InputText id="nationalIDNumber" value={shopAttendant.nationalIDNumber || ""} onChange={(e) => onInputChange(e, "nationalIDNumber")} />
            </div>
            <div className="field col-6 md:col-6">
               <label htmlFor="lc1Letter">LC letter</label>
               <InputText id="lc1Letter" value={shopAttendant.lc1Letter || ""} onChange={(e) => onInputChange(e, "lc1Letter")} />
            </div>
         </div>
         <div className="formgrid grid">
            <div className="field col">
               <label htmlFor="postalAddress">Postal Address</label>
               <InputText id="postalAddress" value={shopAttendant.postalAddress || ""} onChange={(e) => onInputChange(e, "postalAddress")} />
            </div>
         </div>
      </Dialog>
   );
}

export default ShopAttendantForm;
