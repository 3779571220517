import { Dialog } from "primereact/dialog";
import React, { useState, useEffect, useRef } from "react";
import { Messages } from "primereact/messages";
import { Dropdown } from "primereact/dropdown";
import { SUBSCRIPTION_ATTATCHMENT_ENDPOINT, SUBSCRIPTION_PLANS_ENDPOINT } from "utils/EndpointsUtils";
import { BaseApiService } from "utils/BaseApiService";
import { showErrorMessage } from "constants/ErrorMessages";
import { hasNull } from "utils/Utils";
import FormFooter from "components/FormFooter";

function SubscriptionAttachmentForm({ isVisible = false, shops = [], toast, setShowForm, refreshTable }) {
   const [isLoading, setLoading] = useState(false);
   const [selectedShop, setSelectedShop] = useState(null);
   const [submitted, setSubmitted] = useState(false);
   const [subscriptionPlans, setSubsriptionPlans] = useState([]);
   const [selectedSubscriptionPlan, setSelectedSubscriptionPlan] = useState(null);

   const dialogMessage = useRef();

   const clearForm = () => {
      setSelectedShop(null);
      setSelectedSubscriptionPlan(null);
      setSubmitted(false);
      setLoading(false);
   };

   const hideDialog = () => {
      clearForm();
      setShowForm();
   };
   const fetchSubscriptionPlans = async () => {
      const searchParameters = { offset: 0, limit: 0 };
      await new BaseApiService(SUBSCRIPTION_PLANS_ENDPOINT)
         .getRequestWithJsonResponse(searchParameters)
         .then(async (response) => {
            setSubsriptionPlans(response.records);
         })
         .catch((error) => {
            // toast?.current?.show();
         });
   };

   const onSave = async () => {
      setSubmitted(true);
      const payload = {
         shopId: selectedShop?.id,
         subscriptionPlanId: selectedSubscriptionPlan?.id
      };

      if (!hasNull(payload)) {
         setLoading(true);
         await new BaseApiService(SUBSCRIPTION_ATTATCHMENT_ENDPOINT)
            .saveRequestWithJsonResponse(payload, false)
            .then((resp) => {
               hideDialog();
               toast.current.show({
                  severity: "success",
                  summary: "Successful",
                  detail: "Subscription details saved",
                  life: 3000
               });
               setSubmitted(false);
               refreshTable();
            })
            .catch((e) => {
               showErrorMessage(dialogMessage, e?.message);
               setLoading(false);
               setSubmitted(false);
            });
      }
   };

   useEffect(() => {
      fetchSubscriptionPlans();
   }, []);

   return (
      <Dialog
         footer={() => <FormFooter isLoading={isLoading} onClickRight={onSave} onClickLeft={hideDialog} />}
         header={"Shop Subscription"}
         visible={isVisible}
         className="p-fluid"
         //
         style={{ minWidth: "600px" }}
         modal
         onHide={hideDialog}
      >
         <div className="">
            <Messages ref={dialogMessage} style={{ width: "100%" }} />

            <div class="field">
               <label>Shop</label>

               <Dropdown
                  //
                  value={selectedShop}
                  options={shops}
                  onChange={(e) => setSelectedShop(e.target.value)}
                  optionLabel={"name"}
                  filter
                  filterBy="name"
                  placeholder="Select shop"
               />

               {submitted && !selectedShop && <small className="p-error">Shop is required.</small>}
            </div>

            <div class="field ">
               <label>Subscription plan</label>
               <Dropdown
                  //
                  value={selectedSubscriptionPlan}
                  options={subscriptionPlans}
                  onChange={(e) => {
                     setSelectedSubscriptionPlan(e.value);
                  }}
                  optionLabel={"name"}
                  filter
                  placeholder="Select a plan"
               />
               {submitted && !selectedSubscriptionPlan && <small className="p-error">Subscription plan is required.</small>}
            </div>
         </div>
      </Dialog>
   );
}

export default SubscriptionAttachmentForm;
