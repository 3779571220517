import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import FormFooter from "../FormFooter";
import { downloadProductExcelSheet } from "../../utils/DownloadUtils";
import { Dropdown } from "primereact/dropdown";

const DownloadWorkBookModal = ({ shops = [], showModal = false, setShowModal }) => {
   const [loading, setLoading] = useState(false);

   const [selectedShop, setSelectedShop] = useState(null);

   const hideDialog = () => {
      setShowModal(false);
      setSelectedShop(null);
   };

   const handleDownload = async () => {
      if (selectedShop !== null) {
         setLoading(true);
         await downloadProductExcelSheet(selectedShop);
         setLoading(false);
         hideDialog();
      }
   };

   return (
      <Dialog
         visible={showModal}
         style={{ width: "500px" }}
         header="Download product excel sheet"
         modal
         className="p-fluid"
         //
         footer={() => <FormFooter labelRight="Download" onClickLeft={hideDialog} onClickRight={handleDownload} isLoading={loading} />}
         onHide={hideDialog}
      >
         <div className="field">
            <Dropdown
               id="shop"
               value={selectedShop}
               options={shops.filter((i) => !i.name.includes("All"))}
               onChange={(e) => setSelectedShop(e.target.value)}
               optionLabel={"name"}
               filter
               showClear
               filterBy="name"
               placeholder="Select Shop"
            />
         </div>
      </Dialog>
   );
};

export default DownloadWorkBookModal;
