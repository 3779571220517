import React, { useState, useEffect, useRef } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import imageIcon from "./assets/icons/man_placeholder.jpg";
import { UserSessionUtils } from "./utils/UserSessionUtils";
import { Menu } from "primereact/menu";
import { LOGIN_ROUTE_PATH, SHOP_OWNER_PROFILE_ROUTE_PATH } from "utils/NavigationRoutes";
const AppTopbar = (props) => {
   const navigate = useNavigate();
   const [user, setUser] = useState(null);
   const menuRef = useRef(null);

   useEffect(() => {
      let obj = UserSessionUtils.getFullSessionObject();
      setUser(obj?.user);
   }, []);

   const imageStyles = {
      border: "1px solid yellow",
      width: "auto",
      height: "3rem",
      borderRadius: "5px"
   };

   const items = [
      {
         label: "Profile",
         icon: "pi pi-user",
         command: () => {
            const formatName = user?.firstName;
            navigate(generatePath(SHOP_OWNER_PROFILE_ROUTE_PATH, { ownerName: formatName }), {
               state: user
            });
         }
      },
      {
         label: "Logout",
         icon: "bx bx-log-out",
         command: () => {
            UserSessionUtils.clearLocalStorageAndLogout();
         }
      }
   ];

   return (
      <div className="layout-topbar" style={{ display: "flex", width: "inherit" }}>
         <button className="layout-topbar-logo p-link" onClick={() => navigate("/")}></button>

         <button className="layout-menu-button p-link" onClick={props.onMenuButtonClick}>
            <i className="pi pi-bars"></i>
         </button>

         <span
            className=" flex align-items-center justify-content-between bg-gray-100 border-round"
            style={{ position: "absolute", bottom: 5, right: 0, marginRight: "2rem", height: "3.5rem", padding: "10px", cursor: "pointer" }}
         >
            <img src={imageIcon} alt="Your Image" style={imageStyles} className="mr-2" />
            <div onClick={(event) => menuRef.current.toggle(event)} aria-controls="popup_menu" aria-haspopup>
               <span style={{ fontWeight: 300, fontSize: "0.8rem" }}>{user?.firstName + " " + user?.lastName}</span>
               <div style={{ fontWeight: 300, fontSize: "0.7rem" }}>{user?.roles[0]?.name}</div>
               <div style={{ fontWeight: 300, fontSize: "0.6rem" }}>{user?.attendantShopName}</div>
            </div>
            <Menu model={items} className="profile-pop" ref={menuRef} popup id="popup_menu" />
         </span>
      </div>
   );
};

export default AppTopbar;
