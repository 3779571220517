import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";

import { BaseApiService } from "../../utils/BaseApiService";
import { showErrorMessage } from "../../constants/ErrorMessages";
import { Dropdown } from "primereact/dropdown";
import { Messages } from "primereact/messages";
import { MAXIMUM_RECORDS_PER_PAGE, PAGINATION_SIZE_OPTIONS } from "../../constants/Constants";
import { Paginator } from "primereact/paginator";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Calendar } from "primereact/calendar";
import ProductFormDialog from "./ProductFormDialog";
import StatusSummary from "../../components/StatusSummary";
import DeleteRecordDialog from "../../components/dialogs/DeleteRecordDialog";

const ProductsView = () => {
   const [isLoading, setIsLoading] = useState(false);
   const [totalRecords, setTotalRecords] = useState(0);
   const [searchTerm, setSearchTerm] = useState("");
   const [products, setProducts] = useState(null);
   const [isProductDialogVisible, setIsProductDialogVisible] = useState(false);
   const [deleteProductDialog, setDeleteProductDialog] = useState(false);
   const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
   const [selectedRecord, setSelectedRecord] = useState(null);
   const [selectedProducts, setSelectedProducts] = useState(null);
   const [submitted, setSubmitted] = useState(false);
   const [edit, setEdit] = useState(false);
   const [refresh, setRefresh] = useState(false);
   const [first, setFirst] = useState(0);
   const [limit, setLimit] = useState(MAXIMUM_RECORDS_PER_PAGE);
   const [expandedRows, setExpandedRows] = useState(null);

   const toast = useRef(null);
   const dt = useRef(null);
   let offset = 0;

   const fetchProducts = async () => {
      setIsLoading(true);
      let searchParameters = {
         offset: offset,
         limit: MAXIMUM_RECORDS_PER_PAGE,
         searchTerm: searchTerm
      };

      new BaseApiService("/products")

         .getRequestWithJsonResponse(searchParameters)
         .then(async (response) => {
            setProducts(response.records);
            setTotalRecords(response.totalItems);
            setIsLoading(false);
         })
         .catch((error) => {
            setIsLoading(false);
         });
   };

   useEffect(() => {
      fetchProducts();
   }, [refresh]);

   const openNew = () => {
      setSelectedRecord(null);
      setSubmitted(false);
      setIsProductDialogVisible(true);
      setEdit(false);
   };

   const hideDialog = () => {
      setSubmitted(false);
      setEdit(false);
      setIsProductDialogVisible(false);
   };

   const onPageChange = (e) => {
      offset = e.page * MAXIMUM_RECORDS_PER_PAGE;
      setFirst(e.first);
      setLimit(MAXIMUM_RECORDS_PER_PAGE);

      fetchProducts();
   };

   const editProduct = (selectedRecord) => {
      setEdit(true);
      setSelectedRecord(selectedRecord);
      setIsProductDialogVisible(true);
   };

   const confirmDeleteManufacturer = (selectedRecord) => {
      setSelectedRecord(selectedRecord);
      setDeleteProductDialog(true);
   };

   const confirmDeleteSelected = () => {
      setDeleteProductsDialog(true);
   };

   const allowExpansion = (rowData) => {
      return rowData !== null && rowData !== undefined;
   };

   const rowExpansionTemplate = (rowData) => {
      return (
         <div className="field ml-6 pl-4">
            <div className="grid col-12">
               <div className="col-12 md:col-6 lg:col-3">
                  <label className="mt-3">
                     <span className="font-medium">Category:</span>
                     <br /> {rowData.categoryName}
                  </label>
               </div>

               <div className="col-12 md:col-6 lg:col-3">
                  <label className="mt-3">
                     <span className="font-medium"> Package:</span>
                     <br /> <>{`${rowData.packageUnitName} (${rowData.packageQuantity})`}</>
                  </label>
               </div>

               <div className="col-12 md:col-6 lg:col-3">
                  <label className="mt-3">
                     <span className="font-medium">Container:</span>
                     <br /> <>{`${rowData.containerUnitName} (${rowData.containerQuantity})`}</>
                  </label>
               </div>
            </div>
            <div className="grid col-12">
               {rowData.remarks && (
                  <div className="col-12 md:col-6 lg:col-6">
                     <label className="mt-3">
                        <span className="font-medium">Remarks:</span>
                        <br /> <span className="wrap">{rowData.remarks}</span>
                     </label>
                  </div>
               )}
            </div>
         </div>
      );
   };

   const nameTemplate = (rowData) => {
      return (
         <>
            {rowData?.name} <br />
            <span className="text-sm opacity-80">SN: {rowData?.serialNumber}</span>
         </>
      );
   };

   const actionBodyTemplate = (rowData) => {
      return (
         <div className="flex justify-content-center actions align-items-center">
            <Button icon="pi pi-pencil" className="p-button-rounded p-button-text p-button-success" onClick={() => editProduct(rowData)} />
            <Button
               icon="pi pi-trash"
               className="p-button-rounded p-button-text p-button-danger"
               onClick={() => confirmDeleteManufacturer(rowData)}
            />
         </div>
      );
   };

   const header = (
      <div className="flex flex-column md:flex-row md:align-items-center">
         <h5 className="m-0 line relative">Products</h5>
         <span className="block mt-2 md:mt-0 p-input-icon-left ml-5">
            <div className="p-inputgroup flex-1">
               <InputText placeholder="Search" onInput={(e) => setSearchTerm(e.target.value)} />
               <Button icon="pi pi-search" className="primary-btn" onClick={fetchProducts} />
            </div>
         </span>
         <div className="my-2 ml-auto">
            <Button isLoading={isLoading} label="New" icon="pi pi-plus" className="primary-btn mr-2" onClick={openNew} />
            <Button
               isLoading={isLoading}
               label="Delete"
               icon="pi pi-trash"
               className="p-button-danger"
               onClick={confirmDeleteSelected}
               style={selectedProducts && selectedProducts.length > 1 ? { display: "inline-flex" } : { display: "none" }}
            />
         </div>
      </div>
   );

   const paginatorTemplate = {
      layout: "CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink",
      RowsPerPageDropdown: (options) => {
         return (
            <React.Fragment>
               <span className="mx-1" style={{ color: "var(--text-color)", userSelect: "none" }}></span>
               <Dropdown value={options.value} options={PAGINATION_SIZE_OPTIONS} onChange={options.onChange} />
               <span className="mr-auto"></span>
            </React.Fragment>
         );
      },
      CurrentPageReport: (options) => {
         return (
            <span
               style={{
                  color: "var(--text-color)",
                  userSelect: "none",
                  width: "auto",
                  textAlign: "center"
               }}
            >
               Showing {options.first} to {options.last} of {options.totalRecords} products
            </span>
         );
      }
   };

   return (
      <div className="grid crud-demo">
         <div className="col-12">
            <div className="py-2">
               <div className="grid">
                  <StatusSummary title="Shops" value="14" />
                  <StatusSummary title="Attendants" value="184" iconName="pi-map-marker" />
                  <StatusSummary title="Capital" value="170,000" />
                  <StatusSummary title="Removed Capital" value="103,000" />
               </div>
            </div>

            <div className="py-2 -mt-3">
               <Accordion>
                  <AccordionTab className="p-fluid" header="Filter selectedRecord">
                     <div className="formgrid grid">
                        <div className="field col">
                           <label htmlFor="purchasedQuantity">Price range</label>
                           <InputNumber id="purchasedQuantity" />
                        </div>
                        <div className="field col">
                           <label htmlFor="purchasePrice">Amount range</label>
                           <InputNumber id="purchasePrice" />
                        </div>
                        <div className="field col">
                           <label htmlFor="purchasePrice">Batch no</label>
                           <InputNumber id="purchasePrice" />
                        </div>
                     </div>

                     <div className="formgrid grid">
                        <div className="field col">
                           <label htmlFor="purchasedQuantity">Purchase date</label>
                           <InputNumber id="purchasedQuantity" />
                        </div>
                        <div className="field col">
                           <label htmlFor="purchasePrice">Purchase by</label>
                           <InputNumber id="purchasePrice" />
                        </div>
                        <div className="field col">
                           <label htmlFor="purchasePrice">Expiry date</label>
                           <Calendar />
                        </div>
                     </div>
                     <div className="formgrid grid">
                        <div className="field col">
                           <label htmlFor="purchasedQuantity">Category</label>
                           <InputNumber id="purchasedQuantity" />
                        </div>
                     </div>
                     <div
                        style={{
                           display: "flex",
                           flexDirection: "row",
                           justifyContent: "flex-end"
                        }}
                     >
                        <Button
                           label="Clear"
                           icon="pi pi-times"
                           className="p-button-text "
                           style={{
                              color: "black",
                              border: "1px solid black",
                              margin: "5px",
                              width: "fit-content"
                           }}
                        />
                        <Button
                           label="Filter"
                           icon="pi pi-check"
                           className="p-button-text"
                           style={{
                              color: "#FFEB3B",
                              border: "1px solid black",
                              backgroundColor: "black",
                              margin: "5px",
                              width: "fit-content"
                           }}
                        />
                     </div>
                  </AccordionTab>
               </Accordion>
            </div>
            <div className="card">
               <Toast ref={toast} />

               <DataTable
                  ref={dt}
                  value={products}
                  selection={selectedProducts}
                  loading={isLoading}
                  paginator={false}
                  onPageChange={onPageChange}
                  rowsPerPageOptions={[10, 20, 50]}
                  className="datatable-responsive"
                  paginatorTemplate={paginatorTemplate}
                  emptyMessage="No Records found."
                  header={header}
                  expandedRows={expandedRows}
                  onRowToggle={(e) => setExpandedRows(e.data)}
                  rowExpansionTemplate={rowExpansionTemplate}
                  size="small"
                  resizableColumns
               >
                  <Column expander={allowExpansion} style={{ width: "5rem" }} />
                  <Column field="displayName" header="Product" body={nameTemplate} />
                  <Column field="barcode" header="Barcode" />
                  <Column field="manufacturerName" header="Manufacturer" />

                  <Column field="changedByFullName" header="Created by" />

                  <Column field="productionStatusName" header="Status" />

                  <Column body={actionBodyTemplate} header="Actions" className="text-center" headerStyle={{ textAlign: "center" }} />
               </DataTable>

               <Paginator
                  template={paginatorTemplate}
                  first={first}
                  rows={MAXIMUM_RECORDS_PER_PAGE}
                  totalRecords={totalRecords}
                  alwaysShow={false}
                  onPageChange={onPageChange}
               />

               <ProductFormDialog
                  onCompleteFn={fetchProducts}
                  objectToEdit={selectedRecord}
                  isProductDialogVisible={isProductDialogVisible}
                  setIsProductDialogVisible={setIsProductDialogVisible}
                  selectedRecord={selectedRecord}
                  setSelectedRecord={setSelectedRecord}
                  edit={edit}
                  hideDialog={hideDialog}
               />

               <DeleteRecordDialog
                  toast={toast}
                  selectedRecordName={selectedRecord?.name}
                  setDeleteProductDialog={setDeleteProductDialog}
                  deleteProductDialog={deleteProductDialog}
                  setSelectedRecord={setSelectedRecord}
                  apiUrl={`/products/${selectedRecord?.id}`}
                  setIsLoading={setIsLoading}
                  onComplete={() => {
                     fetchProducts();
                     toast.current.show({
                        severity: "success",
                        summary: "Successful",
                        detail: "Product deleted",
                        life: 3000
                     });
                     setIsLoading(false);
                  }}
               />
            </div>
         </div>
      </div>
   );
};
export default ProductsView;
