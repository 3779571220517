import { PermissionParams } from "constants/PermissionParams";
import { ADD_PERMISSIONS } from "duqactStore/actions";

const initialState = {
   permissions: [],
   permissionsPool: []
};

const userReducer = (state = initialState, action) => {
   switch (action?.type) {
      case ADD_PERMISSIONS:
         const { list, userObj } = action?.payload;

         const isShopOwner = userObj?.isShopOwner == true;
         const isSuperAdmin = userObj?.isSuperAdmin == true;

         const formattedPermissions = Object.keys(PermissionParams).reduce((acc, key) => {
            if (isShopOwner || isSuperAdmin) {
               acc[key] = true;
            } else {
               const matchingPermission = list.find((perm) => PermissionParams[key] === perm.name);
               acc[key] = matchingPermission ? true : false;
            }
            return acc;
         }, {});

         return {
            ...state,
            permissionsPool: list,
            permissions: formattedPermissions
         };
      default:
         return state;
   }
};

export default userReducer;
