import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { TabView, TabPanel } from "primereact/tabview";

import { BaseApiService } from "../../utils/BaseApiService";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
import { formatNumberWithCommas, isNotEmpty, toReadableDate, toReadableDateTime } from "../../utils/Utils";
import { MAXIMUM_RECORDS_PER_PAGE } from "../../constants/Constants";
import { UserSessionUtils } from "../../utils/UserSessionUtils";
import { Paginator } from "primereact/paginator";
import NewClientForm from "../../components/dialogs/NewClientForm";
import DeleteRecordDialog from "../../components/dialogs/DeleteRecordDialog";
import FilterComponent from "components/FilterComponent";

const ShopClients = () => {
   const [isLoading, setIsLoading] = useState(true);
   const [SelectedClient, setSelectedClient] = useState(null);
   const [clientDialog, setClientDialog] = useState(null);
   const [first, setFirst] = useState(0);
   const [deleteClientDialog, setDeleteClientDialog] = useState(false);

   const [clients, setClients] = useState([]);
   const [shops, setShops] = useState([]);
   const [searchTerm, setSearchTerm] = useState("");
   const [totalItems, setTotalItems] = useState(0);
   const [activeIndex, setActiveIndex] = useState(0);
   const [selectedShop, setSelectedShop] = useState(null);

   let offset = 0;
   const fetchClients = () => {
      setIsLoading(true);
      const searchParameters = {
         searchTerm: "",
         limit: MAXIMUM_RECORDS_PER_PAGE,
         offset: offset,
         ...(activeIndex > 0 && { shopId: shops[activeIndex]?.id })
      };

      if (isNotEmpty(searchTerm)) {
         searchParameters.searchTerm = searchTerm;         
      }

      if (selectedShop !== null) {
         searchParameters.shopId = selectedShop.id;
         
      }

      if (UserSessionUtils.getShopOwner()) {
         searchParameters.shopOwnerId = UserSessionUtils.getShopOwnerId();
      }
      if (UserSessionUtils.getShopAttendant()) {
         searchParameters.shopId = UserSessionUtils.getShopId();
      }
      new BaseApiService("/clients-controller")
         .getRequestWithJsonResponse(searchParameters)
         .then((response) => {
            setClients(response.records);
            setTotalItems(response.totalItems);
            setIsLoading(false);
         })
         .catch((error) => {
            setIsLoading(false);
         });
   };

   const fetchShops = async () => {
      let searchParameters = { offset: 0, limit: 0 };

      if (UserSessionUtils.getShopOwner()) {
         searchParameters.shopOwnerId = UserSessionUtils.getShopOwnerId();
      }
      await new BaseApiService("/shops")
         .getRequestWithJsonResponse(searchParameters)
         .then(async (response) => {
            if (response?.records?.length > 1) {
               setShops([{ name: "All shops", id: UserSessionUtils.getShopOwnerId() }, ...response?.records.reverse()]);
               return;
            }
            setShops(response.records);
         })
         .catch((error) => {
            setIsLoading(false);
         });
   };

   useEffect(() => {
      fetchShops();
   }, []);

   const dialogMessage = useRef();
   const navigate = useNavigate();
   const toast = useRef(null);
   const dt = useRef(null);

   const onPageChange = (e) => {
      offset = e.page * MAXIMUM_RECORDS_PER_PAGE;
      setFirst(e.first);

      fetchClients();
   };

   const actionBodyTemplate = (rowData) => {
      return (
         <div className="actions flex justify-content-center md:align-items-center ">
            <Button
               icon="pi pi-pencil"
               className="p-button-rounded p-button-text p-button-success "
               onClick={() => {
                  setSelectedClient(rowData);
                  setClientDialog(true);
               }}
            />
            <Button
               icon="pi pi-trash"
               className="p-button-rounded p-button-text p-button-danger"
               onClick={() => {
                  setSelectedClient(rowData);
                  setDeleteClientDialog(true);
               }}
            />
         </div>
      );
   };

   const header = (
      <div className="flex flex-column md:flex-row md:align-items-center">
         <h5 className="m-0 line relative">Shop clients</h5>
         <span className="block mt-2 md:mt-0 p-input-icon-left ml-5">
            <div className="p-inputgroup flex-1">
               <InputText placeholder="Search" onInput={(e) => setSearchTerm(e.target.value)} />
               <Button icon="pi pi-search" className="primary-btn" onClick={fetchClients} />
            </div>
         </span>
         <div className="my-2 ml-auto">
            <Button label="New" icon="pi pi-plus" className="primary-btn mr-2" onClick={() => setClientDialog(true)} />
         </div>
      </div>
   );

   const template2 = {
      layout: "CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink",
      RowsPerPageDropdown: (options) => {
         const dropdownOptions = [
            { label: 10, value: 10 },
            { label: 20, value: 20 },
            { label: 50, value: 50 }
         ];

         return (
            <React.Fragment>
               <span className="mx-1" style={{ color: "var(--text-color)", userSelect: "none" }}></span>
               <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />
               <span className="mr-auto"></span>
            </React.Fragment>
         );
      },
      CurrentPageReport: (options) => {
         return (
            <span
               style={{
                  color: "var(--text-color)",
                  userSelect: "none",
                  width: "auto",
                  textAlign: "center"
               }}
            >
               Showing {options.first} to {options.last} of {options.totalRecords} shop clients
            </span>
         );
      }
   };

   const clientNameBodyTemplate = (rowData) => {
      return (
         <>
            {rowData?.fullName} <br />
            <span className="text-sm opacity-80"> {rowData?.serialNumber}</span>
         </>
      );
   };

   const dateCreatedBodyTemplate = (rowData) => {
      return <>{toReadableDateTime(rowData?.dateCreated)}</>;
   };

   useEffect(() => {
      fetchClients();
   }, [searchTerm, activeIndex]);

   const dynamicTabs = () => {
      if (shops?.length > 1 && !UserSessionUtils.getSuperAdmin()) {
         return (
            <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} className="h-3rem">
               {shops.map((shop, index) => (
                  <TabPanel key={index} header={shop.name} className="h-auto"></TabPanel>
               ))}
            </TabView>
         );
      }
   };

   return (
      <div className="grid crud-demo">
         <div className="col-12">
            <div className="py-2">
               {/* <div className="grid">
                        <StatusSummary iconName="pi pi-shopping-cart " title="Shops" value={formatNumberWithCommas(0)} />
                        <StatusSummary title="Attendants" value={formatNumberWithCommas(0)} iconName="bx bx-receipt" />
                        <StatusSummary title="Capital" value={formatNumberWithCommas(Math.round(0))} iconName="pi pi-money-bill" />
                        <StatusSummary title="Removed Capital" value={formatNumberWithCommas(0)} iconName="bx bx-wallet" />
                    </div> */}
            </div>
            {UserSessionUtils.getSuperAdmin() && (
               <FilterComponent onFilter={fetchClients} onClear={() => setSelectedShop(null)}>
                  <div className="field col-4">
                     <label htmlFor="shop">Shop</label>
                     <Dropdown
                        id="shop"
                        showClear
                        value={selectedShop}
                        options={shops}
                        onChange={(e) => setSelectedShop(e.target.value)}
                        optionLabel={"name"}
                        filter
                        filterBy="name"
                        placeholder="Select Shop"
                     />
                  </div>
               </FilterComponent>
            )}

            <div className="card">
               <Toast ref={toast} />

               {header}

               <DataTable
                  ref={dt}
                  value={clients}
                  dataKey="id"
                  rows={10}
                  className="datatable-responsive"
                  paginatorTemplate={template2}
                  emptyMessage="No clients found."
                  header={dynamicTabs}
                  size="small"
                  loading={isLoading}
               >
                  <Column field="serialNumber" header="Name" body={clientNameBodyTemplate} className="text-left" />
                  <Column header="Phone Number" className="text-center" headerStyle={{ textAlign: "center" }} field="phoneNumber" />
                  <Column field="shop" header="Shop" body={(d) => d?.shop?.name} />

                  <Column field="createdByFullName" header="Created by" />
                  <Column field="dateCreated" header="Created on" body={dateCreatedBodyTemplate} />
                  <Column body={actionBodyTemplate} header="Actions" className="text-center" headerStyle={{ textAlign: "center" }} />
               </DataTable>
               <Paginator
                  template={template2}
                  first={first}
                  rows={MAXIMUM_RECORDS_PER_PAGE}
                  totalRecords={totalItems}
                  alwaysShow={true}
                  onPageChange={onPageChange}
               />
            </div>

            <NewClientForm
               clientDialog={clientDialog}
               setClientDialog={setClientDialog}
               toast={toast}
               refreshTable={fetchClients}
               selectedClient={SelectedClient}
            />

            <DeleteRecordDialog
               showForm
               toast={toast}
               selectedRecordName={SelectedClient?.fullName}
               setDeleteProductDialog={setDeleteClientDialog}
               deleteProductDialog={deleteClientDialog}
               apiUrl={`/clients-controller/${SelectedClient?.id}`}
               setIsLoading={setIsLoading}
               onComplete={() => {
                  fetchClients();
                  toast.current.show({
                     severity: "success",
                     summary: "Successful",
                     detail: "Client deleted",
                     life: 3000
                  });
                  setIsLoading(false);
                  setSelectedClient(null);
               }}
            />
         </div>
      </div>
   );
};
export default ShopClients;
