// Using a functional component, you must wrap it in React.forwardRef, and then forward the ref to
// the node you want to be the root of the print (usually the outer most node in the ComponentToPrint)

import React from "react";
import { populateTemplate } from "./RecieptParameterResolver";

// Using a functional component, you must wrap it in React.forwardRef, and then forward the ref to
// the node you want to be the root of the print (usually the outer most node in the ComponentToPrint)
// https://reactjs.org/docs/refs-and-the-dom.html#refs-and-function-components
export const ComponentToPrint = React.forwardRef((props, ref) => {
   return (
      <div ref={ref}>
         <span dangerouslySetInnerHTML={{ __html: populateTemplate(props.saleObject) }}></span>
      </div>
   );
});
