import { Login } from "../pages/Login";
import { AdminNavigationConfig } from "./AdminNavigationConfig";
import { LOGIN_ROUTE_PATH } from "./NavigationRoutes";
import { ShopAttendantNavigationConfig } from "./ShopAttendantNavigationConfig";
import { ShopOwnerNavigationConfig } from "./ShopOwnerNavigationConfig";
import { UserSessionUtils } from "./UserSessionUtils";

export class MainNavigationConfig {
   /**
    *
    * @param userDetails
    */
   constructor() {}

   getNavigationMenu() {
      if (UserSessionUtils.getShopAttendant()) {
         return new ShopAttendantNavigationConfig().getNavigationMenu();
      }
      if (UserSessionUtils.getShopOwner()) {
         const bool = UserSessionUtils.getOffersDebt();
         if (bool == "false") {
            return new ShopOwnerNavigationConfig().getNavigationMenu().filter((i) => {
               return i?.items[0]?.label.trim() !== "Debts management";
            });
         } else {
            return new ShopOwnerNavigationConfig().getNavigationMenu();
         }
      }
      if (UserSessionUtils.getSuperAdmin()) {
         return new AdminNavigationConfig().getNavigationMenu();
      }
      return [];
   }

   getAuthenticatedComponetRouters() {
      if (UserSessionUtils.getShopAttendant()) {
         return new ShopAttendantNavigationConfig().getComponentRouters();
      }
      if (UserSessionUtils.getShopOwner()) {
         return new ShopOwnerNavigationConfig().getComponentRouters();
      }
      if (UserSessionUtils.getSuperAdmin()) {
         return new AdminNavigationConfig().getComponentRouters();
      }
      return [];
   }

   getUnAuthenticatedComponetRouters() {
      return [{ path: LOGIN_ROUTE_PATH, label: "Login", component: Login, exact: true }];
   }
}
