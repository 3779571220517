export const MESSAGE_TYPES = [
   { label: "Shop Owner Activation OTP", type: "SHOP_OWNER_ACTIVATION_OTP" },

   { label: "Password Reset OTP", type: "PASSWORD_RESET_OTP" },

   { label: "Subscription Reminder", type: "SUBSCRIPTION_REMINDER" },

   { label: "Out of Stock Reminder", type: "OUT_OF_STOCK_REMINDER" }
];

export const MESSAGE_CHANNELS = [
   { label: "SMS", channel: "SMS" },

   { label: "Email", channel: "EMAIL" },

   { label: "Push Notification", channel: "PUSH_NOTIFICATION" }
];
