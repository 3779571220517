import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { BaseApiService } from "../../utils/BaseApiService";
import { Dropdown } from "primereact/dropdown";
import { formatNumberWithCommas, toReadableDate } from "../../utils/Utils";
import { UserSessionUtils } from "../../utils/UserSessionUtils";
import { MAXIMUM_RECORDS_PER_PAGE } from "../../constants/Constants";
import { Paginator } from "primereact/paginator";
import { SHOPS_ENDPOINT, SUBSCRIPTIONS_ENDPOINT } from "../../utils/EndpointsUtils";
import SubscriptionAttachmentForm from "components/dialogs/SubscriptionAttachmentForm";
import FilterComponent from "components/FilterComponent";

const Subscriptions = () => {
   const [limit, setLimit] = useState(0);
   const [isLoading, setIsLoading] = useState(false);
   const [first, setFirst] = useState(0);
   const [totalRecords, setTotalRecords] = useState(0);
   const [shops, setShops] = useState([]);
   const [subscriptions, setSubscriptions] = useState([]);
   const [selectedShop, setSelectedShop] = useState(null);
   const [attatchmentForm, setAttachmentForm] = useState(false);

   let offset = 0;

   const toast = useRef(null);
   const dt = useRef(null);
   const isShopOwner = UserSessionUtils.getShopOwner();

   const fetchSubscriptions = async () => {
      const searchParameters = {
         offset: 0,
         limit: MAXIMUM_RECORDS_PER_PAGE,
         ...(selectedShop && { shopId: selectedShop?.id })
      };
      setIsLoading(true);

      await new BaseApiService(SUBSCRIPTIONS_ENDPOINT)
         .getRequestWithJsonResponse(searchParameters)
         .then(async (response) => {
            setSubscriptions(response.records);
            setTotalRecords(response?.totalItems);
            setIsLoading(false);
         })
         .catch((error) => {
            setIsLoading(false);
         });
   };

   const fetchShops = async () => {
      let searchParameters = { offset: 0, limit: 0 };
      if (isShopOwner) {
         searchParameters.shopOwnerId = UserSessionUtils.getShopOwnerId();
      }
      await new BaseApiService(SHOPS_ENDPOINT)
         .getRequestWithJsonResponse(searchParameters)
         .then(async (response) => {
            setShops(response.records);
         })
         .catch((error) => {});
   };

   const onPageChange = (e) => {
      offset = e.page * MAXIMUM_RECORDS_PER_PAGE;
      setFirst(e.first);
      setLimit(MAXIMUM_RECORDS_PER_PAGE);
   };

   const openAttachmentForm = () => {
      setAttachmentForm(true);
   };

   const createdByFullNameBodyTemplate = (rowData) => {
      return <>{rowData.createdByFullName}</>;
   };

   const header = (
      <div className="flex flex-column md:flex-row md:align-items-center">
         <div className="flex justify-content-center align-items-center gap-2">
            <h5 className="text-center ">Subscriptions</h5>
         </div>
         <div className="ml-auto flex gap-3">
            <Button
               label="New"
               icon="pi pi-plus"
               className="primary-btn"
               onClick={openAttachmentForm}
               aria-controls="popup_menu_right"
               aria-haspopup
            />
         </div>
      </div>
   );

   const template2 = {
      layout: "CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink",
      RowsPerPageDropdown: (options) => {
         const dropdownOptions = [
            { label: 10, value: 10 },
            { label: 20, value: 20 },
            { label: 50, value: 50 }
         ];

         return (
            <React.Fragment>
               <span className="mx-1" style={{ color: "var(--text-color)", userSelect: "none" }}></span>
               <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />
               <span className="mr-auto"></span>
            </React.Fragment>
         );
      },
      CurrentPageReport: (options) => {
         return (
            <span
               style={{
                  color: "var(--text-color)",
                  userSelect: "none",
                  width: "auto",
                  textAlign: "center"
               }}
            >
               Showing {options.first} to {options.last} of {options.totalRecords} subscriptions
            </span>
         );
      }
   };

   useEffect(() => {
      fetchShops();
      fetchSubscriptions();
   }, []);

   return (
      <div className="grid crud-demo">
         <div className="col-12">
            <FilterComponent onFilter={fetchSubscriptions}>
               <div className="col-12 md:col-4 lg:col-4">
                  <label>Shop</label>

                  <Dropdown
                     //
                     value={selectedShop}
                     options={shops}
                     onChange={(e) => setSelectedShop(e.target.value)}
                     optionLabel={"name"}
                     filter
                     filterBy="name"
                     placeholder="Select shop"
                  />
               </div>
            </FilterComponent>

            <div className="card">
               <Toast ref={toast} />
               {header}
               <DataTable
                  loading={isLoading}
                  ref={dt}
                  value={subscriptions}
                  dataKey="id"
                  rows={10}
                  className="datatable-responsive"
                  paginatorTemplate={template2}
                  emptyMessage="No subsriptions found."
                  resizableColumns
               >
                  {<Column field="shopName" header="Shop" body={(rowData) => rowData?.shop?.name} />}
                  <Column
                     field="salesPrice"
                     header="Plan"
                     body={(e) => `${e?.subscriptionPlan?.name} (${formatNumberWithCommas(e?.subscriptionPlan?.costPerSubscription)})`}
                  />

                  <Column field="dateCreated" header="Start date" body={(e) => toReadableDate(e?.startDate)} />
                  <Column field="dateCreated" header="End date" body={(e) => toReadableDate(e?.endDate)} />

                  <Column field="createdBy" header="Created by" body={createdByFullNameBodyTemplate} />

                  <Column field="dateCreated" header="Action date" body={(e) => toReadableDate(e?.dateCreated)} />
               </DataTable>
               <Paginator
                  template={template2}
                  first={first}
                  rows={MAXIMUM_RECORDS_PER_PAGE}
                  totalRecords={totalRecords}
                  alwaysShow={false}
                  onPageChange={onPageChange}
               />
               <SubscriptionAttachmentForm
                  shops={shops}
                  setShowForm={setAttachmentForm}
                  toast={toast}
                  isVisible={attatchmentForm}
                  refreshTable={fetchSubscriptions}
               />
            </div>
         </div>
      </div>
   );
};
export default Subscriptions;
