import React, { useState, useEffect, useRef } from "react";
import { classNames } from "primereact/utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { BaseApiService } from "../../utils/BaseApiService";
import { Dropdown } from "primereact/dropdown";
import { formatNumberWithCommas, toReadableDateTime } from "../../utils/Utils";
import StatusSummary from "../../components/StatusSummary";
import DeleteRecordDialog from "../../components/dialogs/DeleteRecordDialog";
import { roleTypes } from "../../constants/Constants";
import { template2 } from "components/PaginatorTemplate";
import { Messages } from "primereact/messages";
import { showErrorMessage } from "constants/ErrorMessages";
import { ROLES_ENDPOINT } from "utils/EndpointsUtils";

const Role = () => {
   let emptyRole = {
      id: null,
      name: null,
      description: null,
      permissions: [],
      type: null
   };

   const [roles, setRoles] = useState(null);
   const [roleDialog, setRoleDialog] = useState(false);
   const [deleteRoleDialog, setDeleteRoleDialog] = useState(false);
   const [role, setRole] = useState(emptyRole);
   const [loading, setLoading] = useState(true);
   const [submitted, setSubmitted] = useState(false);
   const [searchTerm, setSearchTerm] = useState(null);

   const toast = useRef(null);
   const dt = useRef(null);
   const messageref = useRef(null);

   const fetchRoles = async () => {
      const searchParameters = { offset: 0, limit: 0, ...(searchTerm && searchTerm?.trim() !== "" && { searchTerm: searchTerm }) };
      await new BaseApiService(ROLES_ENDPOINT)
         .getRequestWithJsonResponse(searchParameters)
         .then(async (response) => {
            setRoles(response.records);
            setLoading(false);
         })
         .catch((error) => {});
   };

   useEffect(() => {
      fetchRoles();
   }, []);

   const openNew = () => {
      setRole(emptyRole);
      setRoleDialog(true);
   };

   const hideDialog = () => {
      setRoleDialog(false);
      setRole(emptyRole);
      setSubmitted(false);
   };

   const saveRole = async () => {
      setSubmitted(true);
      if (role?.type && role?.name) {
         setLoading(true);
         await new BaseApiService("/users/roles/")
            .postRequestWithJsonResponse({
               name: role.name,
               description: role.description,
               type: role.type,
               id: role?.id || 0,
               permissionIds: role?.permissions?.map((item) => item?.id) || []
            })
            .then((response) => {
               setRoleDialog(false);
               fetchRoles();
               setSubmitted(false);
               setRole(emptyRole);
            })
            .catch((error) => {
               setLoading(false);
               showErrorMessage(messageref, error?.message);
            });
      }
   };

   const editRole = (role) => {
      setRole({ ...role });
      setRoleDialog(true);
   };

   const deleteRole = (record) => {
      setRole(record);
      setDeleteRoleDialog(true);
      // toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Role Deleted', life: 3000 });
   };

   const onInputChange = (e, name) => {
      const val = (e.target && e.target.value) || "";
      let _user = { ...role };
      _user[`${name}`] = val;
      setRole(_user);
   };

   const createdByFullNameBodyTemplate = (rowData) => {
      return (
         <>
            <span className="p-column-title">Created by</span>
            {rowData.createdByFullName}
         </>
      );
   };

   const dateCreatedBodyTemplate = (rowData) => {
      return (
         <>
            <span className="p-column-title">Date Created</span>
            {toReadableDateTime(rowData.dateCreated)}
         </>
      );
   };

   const actionBodyTemplate = (rowData) => {
      return (
         <div className="actions flex justify-content-center align-items-center">
            <Button icon="pi pi-pencil" className="p-button-rounded p-button-text p-button-success " onClick={() => editRole(rowData)} />
            <Button icon="pi pi-trash" className="p-button-rounded p-button-text p-button-danger" onClick={() => deleteRole(rowData)} />
         </div>
      );
   };

   const header = (
      <div className="flex flex-column md:flex-row md:align-items-center">
         <h5 className="m-0 line relative">Roles</h5>
         <span className="block mt-2 md:mt-0 p-input-icon-left ml-5">
            <div className="p-inputgroup flex-1">
               <InputText placeholder="Search..." onInput={(e) => setSearchTerm(e.target.value)} />
               <Button icon="pi pi-search" className="primary-btn" onClick={fetchRoles} />
            </div>
         </span>

         <div className="my-2 ml-auto">
            <Button label="Add Role" icon="pi pi-plus" className="primary-btn mr-2" onClick={openNew} />
         </div>
      </div>
   );

   const userDialogFooter = (
      <>
         <Button label="Cancel" icon="pi pi-times" className="outline-btn" onClick={hideDialog} loading={loading} />
         <Button label="Save" icon="pi pi-check" className="primary-btn" onClick={saveRole} loading={loading} />
      </>
   );

   return (
      <div className="grid crud-demo">
         <div className="col-12">
            <div className="py-2">
               <div className="grid">
                  <StatusSummary iconName="pi pi-shopping-cart " title="Total" value={formatNumberWithCommas(0)} />
                  <StatusSummary title="Male" value={formatNumberWithCommas(0)} iconName="bx bx-receipt" />
                  <StatusSummary title="Female" value={formatNumberWithCommas(Math.round(0))} iconName="pi pi-money-bill" />
                  <StatusSummary title="Active" value={formatNumberWithCommas(0)} iconName="bx bx-wallet" />
               </div>
            </div>

            <div className="card">
               <Toast ref={toast} />

               <DataTable
                  ref={dt}
                  value={roles}
                  dataKey="id"
                  paginator
                  rows={10}
                  loading={loading}
                  className="datatable-responsive"
                  paginatorTemplate={template2}
                  emptyMessage="No roles found."
                  header={header}
               >
                  <Column field="name" header="Name" />
                  <Column field="description" header="Description" />
                  <Column field="type" header="Type" />

                  <Column field="createdBy" header="Created by" body={createdByFullNameBodyTemplate} />
                  <Column field="dateCreated" header="Created on" body={dateCreatedBodyTemplate} />
                  <Column body={actionBodyTemplate} header="Actions" />
               </DataTable>

               <Dialog
                  visible={roleDialog}
                  style={{ width: "700px" }}
                  header="Role Details"
                  modal
                  className="p-fluid"
                  footer={userDialogFooter}
                  onHide={hideDialog}
               >
                  <Messages ref={messageref} />
                  <div className="formgrid grid">
                     <div className="field col">
                        <label htmlFor="name">Name</label>
                        <InputText
                           id="name"
                           value={role.name || ""}
                           onChange={(e) => onInputChange(e, "name")}
                           required
                           autoFocus
                           className={classNames({
                              "p-invalid": submitted && !role.name
                           })}
                        />
                        {submitted && !role.name && <small className="p-error">Role name is required.</small>}
                     </div>
                     <div className="field col">
                        <label htmlFor="name">Type</label>
                        <Dropdown
                           options={roleTypes}
                           //

                           optionLabel={"value"}
                           value={role.type}
                           onChange={(e) => onInputChange(e, "type")}
                           required
                           className={classNames({
                              "p-invalid": submitted && !role.type
                           })}
                        />
                        {submitted && !role.type && <small className="p-error">Role type is required.</small>}
                     </div>
                  </div>

                  <div className="field">
                     <label htmlFor="description">Description</label>
                     <InputTextarea
                        id="description"
                        rows={5}
                        cols={30}
                        value={role.description || ""}
                        onChange={(e) => onInputChange(e, "description")}
                        className={classNames({
                           "p-invalid": submitted && !role.description
                        })}
                     />
                     {submitted && !role.description && <small className="p-error">Role description is required.</small>}
                  </div>
               </Dialog>

               <DeleteRecordDialog
                  selectedRecordName={role?.name}
                  apiUrl={`/users/roles/${role?.id}`}
                  deleteProductDialog={deleteRoleDialog}
                  setDeleteProductDialog={setDeleteRoleDialog}
                  onComplete={fetchRoles}
                  isLoading={loading}
                  setIsLoading={setLoading}
               />
            </div>
         </div>
      </div>
   );
};

export default Role;
