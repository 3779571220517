import React, { useState, useEffect, useRef } from "react";

import { Dropdown } from "primereact/dropdown";
import { Messages } from "primereact/messages";
import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";
import { Dialog } from "primereact/dialog";
import { showErrorMessage } from "../../constants/ErrorMessages";
import { UserSessionUtils } from "../../utils/UserSessionUtils";
import { classNames } from "primereact/utils";
import { BaseApiService } from "../../utils/BaseApiService";

export default function StockEntryFileUpload({ isUploadDialogVisible, setIsUploadDialogVisible, fetchStockEntries, toast }) {
   const [isUploading, setIsuploading] = useState(false);
   const [selectedShop, setSelectedShop] = useState(null);
   const [shops, setShops] = useState([]);
   const [submitted, setSubmitted] = useState(false);

   const fileInputRef = useRef(null);
   const dialogMessage = useRef();

   const handleFileChange = async (event) => {
      let shopid = selectedShop?.id;
      setSubmitted(true);

      const file = event.files[0];
      const formData = new FormData();
      formData.append("file", file);

      if (shopid) {
         setIsuploading(true);

         new BaseApiService(`/stock-entries/upload/${shopid}`)
            .postMultipartWithJsonResponse(formData)
            .then((response) => {
               toast.current.show({
                  severity: "success",
                  summary: "Successful",
                  detail: response?.message,
                  life: 3000
               });

               fileInputRef.current.clear();
               setIsuploading(false);
               hideDialog();
               fetchStockEntries();
            })
            .catch((error) => {
               setIsuploading(false);
               setSubmitted(false);
               showErrorMessage(dialogMessage, error?.message);
            });
      }
   };

   const hideDialog = () => {
      setIsUploadDialogVisible(false);
      setSelectedShop(null);
      setSubmitted(false);
      fileInputRef.current.clear();
   };

   const fetchShops = async () => {
      let searchParameters = { searchTerm: "", offset: 0, limit: 0 };

      if (UserSessionUtils.getShopOwner()) {
         searchParameters.shopOwnerId = UserSessionUtils.getShopOwnerId();
      }

      new BaseApiService("/shops")
         .getRequestWithJsonResponse(searchParameters)
         .then(async (response) => {
            setShops(response.records);
         })
         .catch((error) => {});
   };

   useEffect(() => {
      fetchShops();
   }, []);
   const footer = (
      <>
         <Button
            label="Clear field"
            icon="pi pi-times"
            className=" outline-btn"
            onClick={() => {
               setSubmitted(false);
               fileInputRef.current.clear();
            }}
         />
      </>
   );
   return (
      <Dialog
         visible={isUploadDialogVisible}
         style={{ width: "600px" }}
         footer={footer}
         header="Upload stock entry document."
         modal
         className="p-fluid"
         onHide={hideDialog}
      >
         <div className="field">
            <Messages ref={dialogMessage} style={{ width: "100%" }} />
            <div className="field col">
               <label htmlFor="packageUnit">Shop</label>
               <Dropdown
                  id="shop"
                  value={selectedShop}
                  options={shops}
                  onChange={(e) => setSelectedShop(e.target.value)}
                  optionLabel={"name"}
                  filter
                  showClear
                  filterBy="name"
                  placeholder="Select Shop"
                  className={classNames({ "p-invalid": submitted && !selectedShop })}
               />
               {submitted && !selectedShop && <small className="p-error">Shop is required.</small>}
            </div>
            <FileUpload
               className="ml-2"
               disabled={isUploading}
               ref={fileInputRef}
               mode="basic"
               chooseLabel="Select File"
               accept=".csv"
               customUpload
               uploadHandler={handleFileChange}
            />
         </div>
      </Dialog>
   );
}
