import React, { useEffect, useState } from "react";
import { classNames } from "primereact/utils";
import { UserSessionUtils } from "./utils/UserSessionUtils";
import imageIcon from "./assets/icons/man_placeholder.jpg";

const AppInlineProfile = (props) => {
   const profileClassName = classNames("layout-profile", {
      "layout-profile-active": props.inlineMenuActive
   });

   const [user, setUser] = useState(null);

   useEffect(() => {
      let obj = UserSessionUtils.getFullSessionObject();
      setUser(obj?.user);
   }, []);

   const imageStyles = {
      border: "1px solid yellow",
      width: "auto",
      height: "3rem",
      borderRadius: "5px"
   };

   return (
      <div className={profileClassName}>
         <button type="button" className="p-link layout-profile-button">
            {
               //onClick={props.onProfileButtonClick}
            }
            <img src={imageIcon} alt="babylon-layout" style={imageStyles} />
            <div className="layout-profile-userinfo">
               <span className="layout-profile-name">{user?.firstName + " " + user?.lastName}</span>
               <span className="layout-profile-role">{user?.roles[0]?.name}</span>
            </div>
            {/* <i className="layout-profile-icon pi pi-angle-down"></i> */}
         </button>
      </div>
   );
};

export default AppInlineProfile;
