import { Button } from "primereact/button";
import React from "react";

export default function ErrorComponent({ handleBackButtonError, errorMessage }) {
   return (
      <>
         <div className="words-div">
            <h1 className="congsheader">Error!</h1>
         </div>
         <div className="congs-div">
            <p>{errorMessage}</p>

            <div>
               <Button onClick={handleBackButtonError} className="send">
                  Back
               </Button>
            </div>
         </div>
      </>
   );
}
