import React, { useState, useEffect, useRef } from "react";
import { classNames } from "primereact/utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Messages } from "primereact/messages";
import { BaseApiService } from "../../utils/BaseApiService";
import { useNavigate, generatePath } from "react-router";
import { showErrorMessage, showSuccessMessage } from "../../constants/ErrorMessages";
import { formatNumberWithCommas, toReadableDate } from "../../utils/Utils";
import PhoneInput from "react-phone-input-2";
import StatusSummary from "../../components/StatusSummary";
import { SHOP_OWNER_PROFILE_ROUTE_PATH } from "utils/NavigationRoutes";

import { ReactComponent as ActiveUser } from "../../assets/icons/user-check-svgrepo-com.svg";
import { template2 } from "components/PaginatorTemplate";

const ShopOwner = () => {
   let emptyShopOwner = {
      id: null,
      firstName: null,
      lastName: null,
      otherName: null,
      passportPhoto: null,
      emailAddress: null,
      nationalID: null,
      nationalIDNumber: null,
      primaryPhoneNumber: null,
      alternativePhoneNumber: null,
      postalAddress: null,
      physicalAddress: null,
      tinNumber: null,
      dateOfBirth: null,
      genderId: 1,
      countryId: null,
      totalShops: 0,
      totalCapital: 0
   };

   const [isLoading, setIsLoading] = useState(false);
   const [shopOwners, setShopOwners] = useState(null);
   const [shopOwnerDialog, setShopOwnerDialog] = useState(false);
   const [deleteShopOwnerDialog, setDeleteShopOwnerDialog] = useState(false);
   const [deleteShopOwnersDialog, setDeleteShopsDialog] = useState(false);
   const [shopOwner, setShopOwner] = useState(emptyShopOwner);
   const [selectedShopOwners, setSelectedShopOwners] = useState(null);
   const [submitted, setSubmitted] = useState(false);
   const [globalFilter, setGlobalFilter] = useState(null);
   const [genders, setGenders] = useState(null);
   const [selectedGender, setSelectedGender] = useState(null);
   const [edit, setEdit] = useState(false);
   const [refresh, setRefresh] = useState(false);
   const [expandedRows, setExpandedRows] = useState(null);
   const [phoneNumber, setPhoneNumber] = useState(null);
   const [altphoneNumber, setAltPhoneNumber] = useState(null);

   const [isActiveTableVisible, setActiveTableVisible] = useState(true);

   const message = useRef();
   const dialogMessage = useRef();
   const navigate = useNavigate();
   const toast = useRef(null);
   const dt = useRef(null);

   const fetchShopOwners = async () => {
      setIsLoading(true);
      let searchParameters = { searchTerm: "", offset: 0, limit: 0 };
      new BaseApiService("/shop/owners")
         .getRequestWithJsonResponse(searchParameters)
         .then(async (response) => {
            setShopOwners(response.records);
            setIsLoading(false);
         })
         .catch(() => {
            setIsLoading(false);
         });
   };

   const fetchGenders = async () => {
      new BaseApiService("/lookups/genders")
         .getRequestWithJsonResponse()
         .then(async (response) => {
            setGenders(response.records);
         })
         .catch((error) => {});
   };

   useEffect(() => {
      fetchGenders();
   }, []);

   useEffect(() => {
      fetchShopOwners();
   }, [refresh]);

   const openNew = () => {
      setShopOwner(emptyShopOwner);
      setSubmitted(false);
      setShopOwnerDialog(true);
      setEdit(false);
   };

   const hideDialog = () => {
      setSubmitted(false);
      setShopOwnerDialog(false);
   };

   const hideShopOwnerDialog = () => {
      setDeleteShopOwnerDialog(false);
   };

   const hideDeleteShopsDialog = () => {
      setDeleteShopsDialog(false);
   };

   const saveShopOwner = () => {
      setSubmitted(true);
      const apiUrl = edit && edit === true ? "/shop/owners/" + shopOwner.id : "/shop/owners";

      new BaseApiService(apiUrl)
         .saveRequestWithJsonResponse(
            {
               firstName: shopOwner.firstName,
               lastName: shopOwner.lastName,
               otherName: shopOwner.otherName,
               emailAddress: shopOwner.emailAddress,
               nationalID: shopOwner.nationalID,
               nationalIDNumber: shopOwner.nationalIDNumber,
               primaryPhoneNumber: phoneNumber,
               alternativePhoneNumber: altphoneNumber,
               postalAddress: shopOwner.postalAddress,
               physicalAddress: shopOwner.physicalAddress,
               tinNumber: shopOwner.tinNumber,
               genderId: selectedGender.id
            },
            edit
         )
         .then((response) => {
            if (edit && edit === true) {
               setRefresh(true);
            } else {
               let _shopOwners = [...shopOwners];

               let _shopOwner = { ...response };

               _shopOwners.push(_shopOwner);

               setShopOwners(_shopOwners);
            }
            setShopOwnerDialog(false);
            setShopOwner(emptyShopOwner);
            setSelectedGender(null);
            toast.current.show({
               severity: "success",
               summary: "Successful",
               detail: "Shop Owner Details saved",
               life: 3000
            });
         })
         .catch((error) => {
            showErrorMessage(dialogMessage, error.message);
         });
   };

   const editShopOwner = (shopOwner) => {
      setEdit(true);

      setShopOwner({ ...shopOwner });
      setSelectedGender(genders.find((obj) => obj.id === shopOwner.genderId));
      setPhoneNumber(shopOwner.primaryPhoneNumber);
      setAltPhoneNumber(shopOwner.alternativePhoneNumber);
      setShopOwnerDialog(true);
   };

   const confirmDeleteUser = (shopOwner) => {
      setShopOwner(shopOwner);
      setDeleteShopOwnerDialog(true);
   };

   const deleteUser = () => {
      new BaseApiService("/shop/owners/" + shopOwner.id)
         .deleteRequestWithJsonResponse()
         .then((response) => {
            let _shopOwners = shopOwners.filter((val) => val.id !== shopOwner.id);
            setShopOwners(_shopOwners);
            setDeleteShopOwnerDialog(false);
            setShopOwner(emptyShopOwner);
            toast.current.show({
               severity: "success",
               summary: "Successful",
               detail: "Shop owner Deleted",
               life: 3000
            });
         })
         .catch((error) => {});
   };

   const confirmDeleteSelected = () => {
      setDeleteShopsDialog(true);
   };

   const deleteSelectedShopOwners = () => {
      let _shopOwners = shopOwners.filter((val) => !selectedShopOwners.includes(val));
      setShopOwners(_shopOwners);
      setDeleteShopsDialog(false);
      setSelectedShopOwners(null);
      toast.current.show({
         severity: "success",
         summary: "Successful",
         detail: "Shop owner Deleted",
         life: 3000
      });
   };

   const onInputChange = (e, name) => {
      const val = (e.target && e.target.value) || "";
      let _shopOwner = { ...shopOwner };
      _shopOwner[`${name}`] = val;

      setShopOwner(_shopOwner);
   };

   const onGenderChange = (e) => {
      setSelectedGender(e.target.value);
   };

   const handleSelection = (rowData) => {
      const { firstName, lastName } = rowData;
      let formartedName = (firstName + lastName).split(" ").join("_");
      const updatedData = { ...rowData, entity: "shopOwner" };
      navigate(generatePath(SHOP_OWNER_PROFILE_ROUTE_PATH, { ownerName: formartedName }), { state: updatedData });
   };

   const handleActivation = (rowData, e) => {
      if (rowData.isActivated === false) {
         new BaseApiService("/shop/owners/" + rowData.id + "/activate")
            .postRequestWithJsonResponse({ isActivated: true })
            .then((response) => {
               setRefresh(true);
               showSuccessMessage(toast, "Shop owner Activated", 3000);
            })
            .catch((error) => {});
      } else if (rowData.isActivated === true) {
         new BaseApiService("/shop/owners/" + rowData.id + "/activate")
            .postRequestWithJsonResponse({
               isActivated: false
            })
            .then((response) => {
               showSuccessMessage(toast, "Shop owner Activated", 3000);
            })
            .catch((error) => {});
      }
   };

   const nameBodyTemplate = (rowData) => {
      return <>{rowData.firstName + " " + rowData.lastName}</>;
   };

   const totalShopsBodyTemplate = (rowData) => {
      return (
         <>
            <Button
               label={rowData.totalShops + ""}
               style={{ color: "#495057" }}
               className="p-button-link numlink"
               onClick={() =>
                  navigate("/administrator/shopOwner/shops", {
                     state: { ownerId: rowData.id, ownerName: rowData.firstName + " " + rowData.lastName }
                  })
               }
            />
         </>
      );
   };

   const totalCapitalBodyTemplate = (rowData) => {
      return <>{formatNumberWithCommas(rowData.totalCapital)}</>;
   };

   const dateCreatedBodyTemplate = (rowData) => {
      return <>{toReadableDate(rowData.dateCreated)}</>;
   };

   const actionBodyTemplate = (rowData) => {
      return (
         <div className="actions flex md:flex-row md:align-items-center">
            <Button icon="pi pi-user" className="p-button-rounded p-button-text p-button-success" onClick={() => handleSelection(rowData)} />
            <Button icon="pi pi-pencil" className="p-button-rounded p-button-text p-button-success " onClick={() => editShopOwner(rowData)} />
            {isActiveTableVisible ? null : (
               <Button
                  icon={<ActiveUser />}
                  className="p-button-rounded p-button-text p-button-success "
                  onClick={(e) => handleActivation(rowData, e)}
               />
            )}
            {/* {isActiveTableVisible ? <Button icon={ <InactiveUser/> }  className="p-button-rounded p-button-text p-button-success mr-2" onClick={(e) => handleActivation(rowData, e)} /> : null} */}
            <Button icon="pi pi-trash" className="p-button-rounded p-button-text p-button-danger" onClick={() => confirmDeleteUser(rowData)} />
         </div>
      );
   };

   const header = (
      <div className="flex flex-column md:flex-row md:align-items-center">
         <h5 className="m-0 line relative">Shop owners</h5>
         <span className="block mt-2 md:mt-0 p-input-icon-left ml-5">
            <i className="pi pi-search" />
            <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
         </span>
         <div className="my-2 ml-auto">
            <Button className=" mr-2" onClick={() => setActiveTableVisible(!isActiveTableVisible)}>
               {isActiveTableVisible ? "Activation" : "Back"}
            </Button>
            <Button label="New" icon="pi pi-plus" className="primary-btn mr-2" onClick={openNew} />
            <Button
               label="Delete"
               icon="pi pi-trash"
               className="p-button-danger"
               onClick={confirmDeleteSelected}
               style={selectedShopOwners && selectedShopOwners.length > 1 ? { display: "inline-flex" } : { display: "none" }}
            />
         </div>
      </div>
   );

   const shopOwnerDialogFooter = (
      <>
         <Button label="Cancel" icon="pi pi-times" className="p-button outline-btn" onClick={hideDialog} />
         <Button label="Save" icon="pi pi-check" className="p-button primary-btn" onClick={saveShopOwner} />
      </>
   );
   const deleteShopOwnerDialogFooter = (
      <>
         <Button label="No" icon="pi pi-times" className="p-button outline-btn" onClick={hideShopOwnerDialog} />
         <Button label="Yes" icon="pi pi-check" className="p-button primary-btn" onClick={deleteUser} />
      </>
   );
   const deleteShopsDialogFooter = (
      <>
         <Button label="No" icon="pi pi-times" className="p-button outline-btn" onClick={hideDeleteShopsDialog} />
         <Button label="Yes" icon="pi pi-check" className="p-button primary-btn" onClick={deleteSelectedShopOwners} />
      </>
   );

   const allowExpansion = (rowData) => {
      return rowData !== null && rowData !== undefined;
   };

   const rowExpansionTemplate = (rowData) => {
      return (
         <div className="field ml-6 pl-4">
            <div className="grid col-12">
               <div className="col-12 md:col-6 lg:col-3">
                  <label className="mt-3">
                     <span className="font-medium">Alternative phone number: </span>
                     <br /> {rowData.alternativePhoneNumber}
                  </label>
               </div>

               <div className="col-12 md:col-6 lg:col-3">
                  <label className="mt-3">
                     <span className="font-medium"> Serial Number: </span>
                     <br /> <>{rowData.serialNumber}</>
                  </label>
               </div>

               <div className="col-12 md:col-6 lg:col-3">
                  <label className="mt-3">
                     <span className="font-medium"> Tax number: </span>
                     <br /> <>{rowData.tinNumber}</>
                  </label>
               </div>

               <div className="col-12 md:col-6 lg:col-3">
                  <label className="mt-3">
                     <span className="font-medium">Postal Address: </span>
                     <br /> <>{rowData.postalAddress}</>
                  </label>
               </div>
            </div>
            <div className="grid col-12">
               <div className="col-12 md:col-6 lg:col-3">
                  <label className="mt-3">
                     <span className="font-medium">Physical Address: </span>
                     <br /> <>{rowData.physicalAddress}</>
                  </label>
               </div>
            </div>
         </div>
      );
   };

   return (
      <div className="grid crud-demo">
         <div className="col-12">
            <div className="py-2">
               <div className="grid">
                  <StatusSummary iconName="pi pi-shopping-cart " title="Males" value={formatNumberWithCommas(0)} />
                  <StatusSummary title="Female" value={formatNumberWithCommas(0)} iconName="bx bx-receipt" />
                  <StatusSummary title="Shops" value={formatNumberWithCommas(Math.round(0))} iconName="pi pi-money-bill" />
                  <StatusSummary title="Capital" value={formatNumberWithCommas(0)} iconName="bx bx-wallet" />
               </div>
            </div>

            <div className="card">
               <Messages ref={message} style={{ width: "100%" }} />
               <DataTable
                  ref={dt}
                  loading={isLoading}
                  value={
                     isActiveTableVisible
                        ? shopOwners?.filter((owner) => owner.isActivated === true)
                        : shopOwners?.filter((owner) => owner.isActivated === false)
                  }
                  selection={selectedShopOwners}
                  // onSelectionChange={handleSelection}
                  dataKey="id"
                  paginator
                  rows={10}
                  className="datatable-responsive"
                  paginatorTemplate={template2}
                  globalFilter={globalFilter}
                  emptyMessage="No shop owners found."
                  selectionMode="single"
                  size="small"
                  header={header}
                  resizableColumns
                  onRowToggle={(e) => {
                     setExpandedRows(e.data);
                  }}
                  rowExpansionTemplate={rowExpansionTemplate}
                  expandedRows={expandedRows}
               >
                  <Column expander={allowExpansion} style={{ width: "5rem" }} />
                  <Column header="Name" body={nameBodyTemplate} />
                  {isActiveTableVisible ? <Column field="nationalIDNumber" header="NIN" /> : null}
                  <Column field="primaryPhoneNumber" header="Phone" />
                  <Column field="emailAddress" header="Email" />
                  <Column field="gender" header="Gender" />
                  {isActiveTableVisible ? <Column field="totalShops" header="Shops" body={totalShopsBodyTemplate} /> : null}
                  {isActiveTableVisible ? <Column field="totalCapital" header="Capital" body={totalCapitalBodyTemplate} /> : null}
                  <Column field="createdByFullName" header="Created by" />
                  <Column field="dateCreated" header="Created on" body={dateCreatedBodyTemplate} />
                  <Column body={actionBodyTemplate} header="Actions" className="text-center" headerStyle={{ textAlign: "center" }} />
               </DataTable>

               <Dialog
                  visible={shopOwnerDialog}
                  style={{ width: "700px" }}
                  header="Shop Owner Details"
                  modal
                  className="p-fluid"
                  footer={shopOwnerDialogFooter}
                  onHide={hideDialog}
               >
                  <Messages ref={dialogMessage} style={{ width: "100%" }} />
                  <div className="formgrid grid" style={{ marginTop: "10px" }}>
                     <div className="field col-12 md:col-4">
                        <label htmlFor="firstName">First Name</label>
                        <InputText
                           id="firstName"
                           value={shopOwner.firstName || ""}
                           onChange={(e) => onInputChange(e, "firstName")}
                           required
                           autoFocus
                           className={classNames({
                              "p-invalid": submitted && !shopOwner.firstName
                           })}
                        />
                        {submitted && !shopOwner.firstName && <small className="p-invalid">First name is required.</small>}
                     </div>
                     <div className="field col-12 md:col-4">
                        <label htmlFor="lastName">Last Name</label>
                        <InputText
                           id="lastName"
                           value={shopOwner.lastName || ""}
                           onChange={(e) => onInputChange(e, "lastName")}
                           required
                           autoFocus
                           className={classNames({
                              "p-invalid": submitted && !shopOwner.lastName
                           })}
                        />
                        {submitted && !shopOwner.lastName && <small className="p-invalid">Last name is required.</small>}
                     </div>
                     <div className="field col-12 md:col-4">
                        <label htmlFor="otherName">Other Name</label>
                        <InputText id="otherName" value={shopOwner.otherName || ""} onChange={(e) => onInputChange(e, "otherName")} />
                     </div>
                  </div>
                  <div className="formgrid grid">
                     <div className="field col-6 md:col-6">
                        <label htmlFor="emailAddress">Email Address</label>
                        <InputText
                           id="emailAddress"
                           value={shopOwner.emailAddress || ""}
                           onChange={(e) => onInputChange(e, "emailAddress")}
                           required
                           autoFocus
                           className={classNames({
                              "p-invalid": submitted && !shopOwner.emailAddress
                           })}
                        />
                        {submitted && !shopOwner.emailAddress && <small className="p-invalid">Email address is required.</small>}
                     </div>
                     <div className="field col-6 md:col-6">
                        <label htmlFor="gender">Gender</label>
                        <Dropdown
                           id="gender"
                           value={selectedGender}
                           options={genders}
                           required
                           onChange={onGenderChange}
                           optionLabel="name"
                           placeholder="Select a Gender"
                        />
                     </div>
                  </div>
                  <div className="formgrid grid">
                     <div className="field col-6 md:col-6">
                        <label htmlFor="primaryPhoneNumber">Phone number</label>
                        <PhoneInput
                           enableSearch
                           id="primaryPhoneNumber"
                           className={classNames({
                              "country-code-reduced error-Input": submitted && !phoneNumber,
                              "country-code-reduced": true
                           })}
                           value={phoneNumber || ""}
                           onChange={(e) => setPhoneNumber(e)}
                           country={"ug"}
                           inputProps={{
                              required: true
                           }}
                        />
                        {submitted && !phoneNumber && <small className="p-invalid">Phone number is required.</small>}
                     </div>
                     <div className="field col-6 md:col-6">
                        <label htmlFor="alternativePhoneNumber">Alternative number</label>
                        <PhoneInput
                           id="altPhoneNumber"
                           className={classNames({ "country-code-reduced": true })}
                           value={altphoneNumber || ""}
                           onChange={(e) => setAltPhoneNumber(e)}
                           country={"ug"}
                           enableSearch
                           inputProps={{
                              required: true
                           }}
                        />
                     </div>
                  </div>
                  <div className="formgrid grid">
                     <div className="field col-6 md:col-6">
                        <label htmlFor="nationalIDNumber">National ID Number</label>
                        <InputText
                           id="nationalIDNumber"
                           value={shopOwner.nationalIDNumber || ""}
                           onChange={(e) => onInputChange(e, "nationalIDNumber")}
                           required
                           autoFocus
                           className={classNames({
                              "p-invalid": submitted && !shopOwner.nationalIDNumber
                           })}
                        />
                        {submitted && !shopOwner.nationalIDNumber && <small className="p-invalid">National ID Number is required.</small>}
                     </div>
                     <div className="field col-6 md:col-6">
                        <label htmlFor="tinNumber">Tax ID Number</label>
                        <InputText id="tinNumber" value={shopOwner.tinNumber || ""} onChange={(e) => onInputChange(e, "tinNumber")} />
                     </div>
                  </div>
                  <div className="formgrid grid">
                     <div className="field col-6 md:col-6">
                        <label htmlFor="physicalAddress">Physical address</label>
                        <InputText
                           id="physicalAddress"
                           value={shopOwner.physicalAddress || ""}
                           onChange={(e) => onInputChange(e, "physicalAddress")}
                           required
                           autoFocus
                           className={classNames({
                              "p-invalid": submitted && !shopOwner.physicalAddress
                           })}
                        />
                        {submitted && !shopOwner.physicalAddress && <small className="p-invalid">Physical address is required.</small>}
                     </div>
                     <div className="field col-6 md:col-6">
                        <label htmlFor="postalAddress">Postal Address</label>
                        <InputText id="postalAddress" value={shopOwner.postalAddress || ""} onChange={(e) => onInputChange(e, "postalAddress")} />
                     </div>
                  </div>
               </Dialog>

               <Dialog
                  visible={deleteShopOwnerDialog}
                  style={{ width: "450px" }}
                  header="Confirm"
                  modal
                  footer={deleteShopOwnerDialogFooter}
                  onHide={hideShopOwnerDialog}
               >
                  <div className="flex align-items-center justify-content-center">
                     <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                     {shopOwner && (
                        <span>
                           Are you sure you want to delete <b>{shopOwner.firstName}</b>?
                        </span>
                     )}
                  </div>
               </Dialog>

               <Dialog
                  visible={deleteShopOwnersDialog}
                  style={{ width: "450px" }}
                  header="Confirm"
                  modal
                  footer={deleteShopsDialogFooter}
                  onHide={hideDeleteShopsDialog}
               >
                  <div className="flex align-items-center justify-content-center">
                     <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                     {shopOwner && <span>Are you sure you want to delete the selected shopOwners?</span>}
                  </div>
               </Dialog>
            </div>
         </div>
      </div>
   );
};

export default ShopOwner;
