import React, { useState, useEffect, useRef } from "react";
import { classNames } from "primereact/utils";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import { InputNumber } from "primereact/inputnumber";
import { UserSessionUtils } from "../../utils/UserSessionUtils";
import { BaseApiService } from "../../utils/BaseApiService";
import { hasNull } from "../../utils/Utils";
import { Messages } from "primereact/messages";
import { showErrorMessage } from "../../constants/ErrorMessages";
import { DAMAGES_ENDPOINT } from "utils/EndpointsUtils";
import FormFooter from "components/FormFooter";
import { damageTypes } from "constants/Constants";

function NewDamageDialog({ onSave, damagesForm, setDamagesForm, stockEntry, saleRecord }) {
   const [submitted, setSubmitted] = useState(false);
   const [selectedShop, setSelectedShop] = useState(null);
   const [selectedDamageType, setSelectedDamageType] = useState(damageTypes[0]);
   const [unitOfQty, setUnitOfQty] = useState(null);
   const [units, setUnits] = useState([]);

   const [isLoading, setIsLoading] = useState(false);

   const [qtyLost, setQtyLost] = useState(null);
   const [remarks, setRemarks] = useState("");

   const [edit, setEdit] = useState(false);

   const dialogMessage = useRef();
   const toast = useRef(null);
   const isShopAttendant = UserSessionUtils.getShopAttendant();
   const attendantShopId = UserSessionUtils.getShopId();

   const populateForm = () => {};

   const fetchContainerUnits = async () => {
      let searchParameters = {
         offset: 0,
         limit: 0,
         commaSeparatedTypeIds: [2]
      };
      await new BaseApiService("/lookups/lookup-values")
         .getRequestWithJsonResponse(searchParameters)
         .then(async (response) => {
            setUnits(response.records);
         })
         .catch((error) => {});
   };

   const hideDialog = () => {
      setSubmitted(false);
      setDamagesForm(false);
      clearForm();
   };

   const clearForm = () => {
      setSelectedShop(null);
      setUnitOfQty(null);
      setQtyLost(0);
      setIsLoading(false);
      setEdit(false);
   };

   const saveDamage = async () => {
      setSubmitted(true);

      const payload = {
         id: 0,
         quantityLost: qtyLost,
         unitOfQuantityId: unitOfQty,
         type: selectedDamageType?.type,
         ...(saleRecord && { saleLineItemId: saleRecord?.id }),
         ...(stockEntry && {
            stockEntryId: stockEntry?.id,
            shopId: stockEntry?.shopId
         }),

         notes: remarks
      };

      const isValidPayload = hasNull(payload) === false;

      if (isValidPayload === true) {
         setIsLoading(true);

         await new BaseApiService(DAMAGES_ENDPOINT)
            .postRequestWithJsonResponse(payload)
            .then((response) => {
               onSave();
               hideDialog();
               setEdit(false);
               setIsLoading(false);
               setSubmitted(false);
            })
            .catch((error) => {
               showErrorMessage(dialogMessage, error.message);
               setSubmitted(false);
               setIsLoading(false);
            });
      }
   };

   useEffect(() => {
      fetchContainerUnits();
   }, []);

   return (
      <Dialog
         visible={damagesForm}
         style={{ width: "700px" }}
         header={`Damage Information for ${stockEntry?.productName}`}
         modal
         className="p-fluid"
         footer={() => <FormFooter onClickRight={saveDamage} onClickLeft={hideDialog} isLoading={isLoading} />}
         //
         onHide={hideDialog}
      >
         <Messages ref={dialogMessage} style={{ width: "100%" }} />

         <div className="formgrid grid">
            <div className="field col">
               <label htmlFor="supplier">Type</label>
               <Dropdown
                  id="supplier"
                  value={selectedDamageType}
                  options={damageTypes}
                  onChange={(e) => setSelectedDamageType(e.target.value)}
                  filter
                  filterBy="name"
                  optionLabel="name"
                  placeholder="Select a damage type"
                  className={classNames({
                     "p-invalid": submitted && !selectedDamageType
                  })}
               />
               {submitted && !selectedDamageType && <small className="p-error">Type is required.</small>}
            </div>
         </div>

         <div className="formgrid grid">
            <div className="field col-6 md:col-6">
               <label>Qty lost</label>
               <InputNumber
                  value={qtyLost}
                  onValueChange={(e) => setQtyLost(e.target.value)}
                  className={classNames({ "p-invalid": submitted && !qtyLost })}
               />
               {submitted && !qtyLost && <small className="p-error">Field is required .</small>}
            </div>

            <div className="field col-6 md:col-6">
               <label htmlFor="unitOfQty">Unit of Qty</label>

               <Dropdown
                  options={units}
                  value={unitOfQty}
                  optionLabel={"value"}
                  optionValue={"id"}
                  filter
                  onChange={(e) => {
                     setUnitOfQty(e.target.value);
                  }}
               />

               {submitted && !unitOfQty && <small className="p-error">Field is required .</small>}
            </div>
         </div>

         <div className="field mt-4">
            <label htmlFor="remarks">Remarks</label>
            <InputTextarea style={{ height: 100 }} id="remarks" value={remarks} onChange={(e) => setRemarks(e.target.value)} />
         </div>
      </Dialog>
   );
}

export default NewDamageDialog;
