import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { BaseApiService } from "../../utils/BaseApiService";
import { Dropdown } from "primereact/dropdown";
import { formatNumberWithCommas, toReadableDateTime } from "../../utils/Utils";
import { UserSessionUtils } from "../../utils/UserSessionUtils";
import { MAXIMUM_RECORDS_PER_PAGE } from "../../constants/Constants";
import { Paginator } from "primereact/paginator";
import DeleteRecordDialog from "../../components/dialogs/DeleteRecordDialog";
import StockListingFormDialog from "../../components/dialogs/StockListingFormDialog";
import StatusSummary from "../../components/StatusSummary";
import NewProduct from "../shopowner/NewProduct";
import FilterComponent from "../../components/FilterComponent";
import StockListingRow from "../../components/row_expansions/StockListingRow";
import { Menu } from "primereact/menu";
import DownloadWorkBookModal from "../../components/dialogs/DownloadWorkBookModal";
import { template2 } from "components/PaginatorTemplate";
import { showSuccessMessage } from "constants/ErrorMessages";

const StockListing = () => {
   const [shopProducts, setShopProducts] = useState([]);
   const [productDialog, setProductDialog] = useState(false);

   const [openBullDozer, setOpenBullDozer] = useState(false);

   const [deleteProductDialog, setDeleteProductDialog] = useState(false);
   const [shopProduct, setShopProduct] = useState(null);
   const [selectedProducts, setSelectedProducts] = useState([]);
   const [submitted, setSubmitted] = useState(false);
   const [limit, setLimit] = useState(0);
   const [searchTerm, setSearchTerm] = useState(null);
   const [isLoading, setIsLoading] = useState(false);
   const [first, setFirst] = useState(0);
   const [totalRecords, setTotalRecords] = useState(0);
   const [expandedRows, setExpandedRows] = useState(null);
   const [edit, setEdit] = useState(false);
   const [shops, setShops] = useState([]);
   const [selectedShop, setSelectedShop] = useState(null);
   const [manufacturers, setManufacturers] = useState([]);
   const [selectedManufacturer, setSelectedManufacturer] = useState(null);
   const [barcode, setBarCode] = useState("");

   const [showDownloadModal, setShoDownloadModal] = useState(false);

   let offset = 0;

   const toast = useRef(null);
   const dt = useRef(null);
   const isShopOwner = UserSessionUtils.getShopOwner();
   const isShopAttendant = UserSessionUtils.getShopAttendant();

   const fetchShopProducts = async () => {
      setIsLoading(true);
      const searchParameters = {
         offset: offset,
         limit: MAXIMUM_RECORDS_PER_PAGE,
         ...(searchTerm && { searchTerm }),
         ...(selectedManufacturer && { manufacturerId: selectedManufacturer?.id }),
         ...(isShopOwner && { shopOwnerId: UserSessionUtils.getShopOwnerId() }),
         ...(isShopAttendant && { shopId: UserSessionUtils.getShopId() }),
         ...(selectedShop && { shopId: selectedShop?.id }),
         ...(barcode && { barCode: barcode })
      };

      new BaseApiService("/shop-products")
         .getRequestWithJsonResponse(searchParameters)
         .then((response) => {
            setShopProducts(response.records);
            setTotalRecords(response.totalItems);
            setIsLoading(false);
         })
         .catch((error) => {
            setIsLoading(false);
         });
   };

   const fetchShops = async () => {
      let searchParameters = { offset: 0, limit: 0 };
      if (isShopOwner) {
         searchParameters.shopOwnerId = UserSessionUtils.getShopOwnerId();
      }
      new BaseApiService("/shops")
         .getRequestWithJsonResponse(searchParameters)
         .then(async (response) => {
            setShops(response.records);
         })
         .catch((error) => {});
   };

   const fetchManufacturers = async () => {
      let searchParameters = { offset: 0, limit: 0 };
      new BaseApiService("/manufacturers")
         .getRequestWithJsonResponse(searchParameters)
         .then(async (response) => {
            setManufacturers(response.records);
         })
         .catch((error) => {});
   };

   const clearFilterFields = () => {
      setSearchTerm(null);
      setSelectedShop(null);
      setSelectedManufacturer(null);
      setBarCode("");
   };
   const onPageChange = (e) => {
      offset = e.page * MAXIMUM_RECORDS_PER_PAGE;
      setFirst(e.first);
      setLimit(MAXIMUM_RECORDS_PER_PAGE);

      fetchShopProducts();
   };

   const openNew = () => {
      setSubmitted(false);
      setProductDialog(true);
   };

   const openNew2 = () => {
      setSubmitted(false);
      setOpenBullDozer(true);
   };

   const hideDialog = () => {
      setSubmitted(false);
      setProductDialog(false);
      setShopProduct(null);
      setEdit(false);
   };

   const editProduct = (shopProduct) => {
      setEdit(true);
      setShopProduct(shopProduct);
      setProductDialog(true);
   };

   const confirmDeleteManufacturer = (shopProduct) => {
      setShopProduct(shopProduct);
      setDeleteProductDialog(true);
   };

   const productBodyTemplate = (rowData) => {
      return (
         <>
            {rowData.productName}
            <br />
            <span className="text-sm opacity-80">SN: {rowData.serialNumber}</span>
         </>
      );
   };

   const priceBodyTemplate = (rowData) => {
      return <>{formatNumberWithCommas(rowData.salesPrice, rowData?.currency)}</>;
   };

   const manufacturerBodyTemplate = (rowData) => {
      return <>{rowData.manufacturerName}</>;
   };

   const createdByFullNameBodyTemplate = (rowData) => {
      return <>{rowData.createdByFullName}</>;
   };

   const dateCreatedBodyTemplate = (rowData) => {
      return <>{toReadableDateTime(rowData.dateCreated)}</>;
   };

   const actionBodyTemplate = (rowData) => {
      return (
         <div className="actions flex justify-content-center align-items-center">
            <Button icon="pi pi-pencil" className="p-button-rounded p-button-text p-button-success" onClick={() => editProduct(rowData)} />
            <Button
               icon="pi pi-trash"
               className="p-button-rounded p-button-text p-button-danger "
               onClick={() => confirmDeleteManufacturer(rowData)}
            />
         </div>
      );
   };

   const menuLeft = useRef(null);
   const menuRight = useRef(null);

   const header = (
      <div className="flex flex-column md:flex-row md:align-items-center">
         <div className="flex justify-content-center align-items-center gap-2">
            <h5 className="text-center line relative">Stock listing</h5>
            <div className="p-inputgroup flex-1">
               <InputText placeholder="Search product name" value={searchTerm} onInput={(e) => setSearchTerm(e.target.value)} />
               <Button icon="pi pi-search" className="primary-btn" onClick={fetchShopProducts} />
            </div>
         </div>
         <div className="ml-auto flex gap-3">
            <Menu
               model={[
                  {
                     label: "",
                     icon: "pi pi-plus",
                     items: [
                        { label: "Opening stock", command: openNew2 },
                        { label: "List product", command: openNew }
                     ]
                  }
               ]}
               popup
               ref={menuLeft}
               id="popup_menu_right"
               popupAlignment="right"
            />

            <Button
               label="New"
               icon="pi pi-plus"
               className="primary-btn"
               onClick={(event) => menuLeft.current.toggle(event)}
               aria-controls="popup_menu_right"
               aria-haspopup
            />
            <Button
               label=""
               icon="pi pi-download"
               className="primary-btn"
               onClick={(event) => setShoDownloadModal(true)}
               aria-controls="popup_menu_right"
               aria-haspopup
            />
         </div>
      </div>
   );

   const allowExpansion = (rowData) => {
      return rowData !== null && rowData !== undefined;
   };

   useEffect(() => {
      fetchShops();
      fetchShopProducts();
      fetchManufacturers();
      fetchShopProducts();
   }, []);

   return (
      <div className="grid crud-demo">
         <div className="col-12">
            <div className="py-2">
               <div className="grid">
                  <StatusSummary iconName="pi pi-shopping-cart " title="Products" value={formatNumberWithCommas(totalRecords)} />
                  <StatusSummary title="Categories" value={formatNumberWithCommas(0)} iconName="bx bx-receipt" />
                  <StatusSummary title="Avaiabale" value={formatNumberWithCommas(Math.round(totalRecords))} iconName="pi pi-money-bill" />
                  <StatusSummary title="Depleted" value={formatNumberWithCommas(0)} iconName="bx bx-wallet" />
               </div>
            </div>

            <FilterComponent onFilter={fetchShopProducts} onClear={clearFilterFields}>
               <div className="formgrid grid">
                  <div className="field col-4">
                     <label htmlFor="shop">Shop</label>
                     <Dropdown
                        id="shop"
                        value={selectedShop}
                        options={shops}
                        onChange={(e) => setSelectedShop(e.target.value)}
                        optionLabel={"name"}
                        filter
                        showClear
                        filterBy="name"
                        placeholder="Select Shop"
                     />
                  </div>

                  <div className="field col-4">
                     <label htmlFor="shop">Manufacturer</label>
                     <Dropdown
                        id="manufacturer"
                        value={selectedManufacturer}
                        options={manufacturers}
                        onChange={(e) => setSelectedManufacturer(e.target.value)}
                        optionLabel={"name"}
                        filter
                        filterBy="name"
                        placeholder="Select Manufacturer"
                     />
                  </div>

                  <div className="field col-4">
                     <label htmlFor="shop">Barcode</label>
                     <InputText value={barcode} onChange={(e) => setBarCode(e?.target?.value)} />
                  </div>
               </div>
            </FilterComponent>

            <div className="card">
               <Toast ref={toast} />

               {header}

               <DataTable
                  loading={isLoading}
                  ref={dt}
                  value={shopProducts}
                  selection={selectedProducts}
                  onSelectionChange={(e) => setSelectedProducts(e.value)}
                  dataKey="id"
                  rows={10}
                  className="datatable-responsive"
                  paginatorTemplate={template2}
                  emptyMessage="No stock listing found."
                  size="small"
                  resizableColumns
                  onRowToggle={(e) => {
                     setExpandedRows(e.data);
                  }}
                  rowExpansionTemplate={(rowData) => <StockListingRow rowData={rowData} />}
                  expandedRows={expandedRows}
               >
                  <Column expander={allowExpansion} style={{ width: "5rem" }} />

                  <Column field="productName" header="Product" body={productBodyTemplate} />
                  <Column field="barcode" header="Bar code" />

                  <Column field="categoryName" header="Category" />
                  <Column field="salesPrice" header="Price" body={priceBodyTemplate} />
                  <Column field="manufacturerName" header="Manufacturer" body={manufacturerBodyTemplate} />
                  {UserSessionUtils.getSuperAdmin() && <Column field="shopName" header="Shop" />}
                  <Column field="createdBy" header="Listed by" body={createdByFullNameBodyTemplate} />
                  <Column field="dateCreated" header="Listed on" body={dateCreatedBodyTemplate} />
                  <Column body={actionBodyTemplate} header="Actions" className="text-center" headerStyle={{ textAlign: "center" }} />
               </DataTable>
               <Paginator
                  template={template2}
                  first={first}
                  rows={MAXIMUM_RECORDS_PER_PAGE}
                  totalRecords={totalRecords}
                  alwaysShow={false}
                  onPageChange={onPageChange}
               />

               {productDialog === true && (
                  <StockListingFormDialog
                     edit={edit}
                     selectedRecord={shopProduct}
                     hideDialog={hideDialog}
                     productDialog={productDialog}
                     onComplete={() => {
                        fetchShopProducts();
                        showSuccessMessage(toast, "Product Details saved");
                     }}
                     shops={shops}
                     manufacturers={manufacturers}
                  />
               )}

               <DeleteRecordDialog
                  selectedRecordName={shopProduct?.productName}
                  deleteProductDialog={deleteProductDialog}
                  setDeleteProductDialog={setDeleteProductDialog}
                  setSelectedRecord={setShopProduct}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  apiUrl={`/shop-products/${shopProduct?.id}`}
                  onComplete={() => {
                     fetchShopProducts();
                     setShopProduct(null);
                     showSuccessMessage(toast, "Shop product deleted");
                     setIsLoading(false);
                  }}
               />
            </div>

            <DownloadWorkBookModal showModal={showDownloadModal} shops={shops} setShowModal={setShoDownloadModal} />

            {openBullDozer === true && (
               <NewProduct
                  openBulldozer={openBullDozer}
                  setOpenBullDozer={setOpenBullDozer}
                  toast={toast}
                  onComplete={() => {
                     fetchShopProducts();
                  }}
               />
            )}
         </div>
      </div>
   );
};
export default StockListing;
